
import { Col, Row, Card } from 'react-bootstrap';

// import bootstrap icons
import CreateEventForm from './CreateEventForm';
import ProfileLayout from "../../../instructor/ProfileLayout";
import { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const CreateEvent = ({ history }) => {

	const [eventInfo, setEventInfo] = useState({
		startTime: '',
		endTime: '',
		eventDescription: '',
		eventName: ''
	})

	const events = useSelector(state => state.adminUser.events)
	useEffect(() => {

		if (history?.location?.state?.id) {
			const event = events.filter((event) => event.id === history.location.state.id)[0]
			setEventInfo({
				startTime: event.startsAt,
				endTime: event.endsAt,
				eventDescription: event.description,
				eventName: event.name,
				id: event.id
			});
		} else {
			setEventInfo({
				startTime: '',
				endTime: '',
				eventDescription: '',
				eventName: ''
			});
		}
	}, [])

	return (
		<ProfileLayout>
			{/* create User form  */}
			<div>
				{/* row */}
				<Row>
					<Col xl={12} md={12} xs={12}>
						<Card>
							{/* card body */}
							<Card.Body className="p-lg-6">
								<CreateEventForm eventInfo={eventInfo} />
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</div>
		</ProfileLayout>
	);
};

export default CreateEvent;
