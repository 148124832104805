import { v4 as uuid } from 'uuid';

const NavbarDefault = [
    {
        id: uuid(),
        menuitem: 'Courses',
        children: [
            {
                id: uuid(),
                menuitem: 'My Courses',
                link: '/instructor/instructor-my-courses/',
                role: ['Instructor']
            },
            { id: uuid(), link: '/courses/all-courses', menuitem: 'All Courses', role: ['Admin'] },
            { id: uuid(), link: '/courses/upload-banner', menuitem: 'Upload Banner', role: ['Admin'] },
			{ id: uuid(), link: '/courses/enroll', menuitem: 'Enroll Course', role: ['Admin'] },
			{ id: uuid(), link: '/courses/transactions', menuitem: 'All Transactions', role: ['Admin'] },
			{ id: uuid(), link: '/courses/shipments', menuitem: 'All Shipments', role: ['Admin'] },
			{ id: uuid(), link: '/courses/assignments', menuitem: 'All Assignments', role: ['Admin', 'Instructor'] }
        ],
        role: ['Admin', 'Instructor']
    },
    {
        id: uuid(),
        menuitem: 'User',
        children: [
            { id: uuid(), link: '/user/create-user', menuitem: 'Create User', role: ['Admin'] },
            { id: uuid(), link: '/user/admin', menuitem: 'Admin', role: ['Admin'] },
            { id: uuid(), link: '/user/instructor', menuitem: 'Instructor', role: ['Admin'] },
			{ id: uuid(), link: '/user/author', menuitem: 'Author', role: ['Admin'] },
            { id: uuid(), link: '/user/student', menuitem: 'Student', role: ['Admin'] }
        ],
        role: ['Admin']
    },
	// {
	// 	id: uuid(),
	// 	menuitem: 'Accounts',
	// 	link: '#',
	// 	children: [
	// 		{
	// 			id: uuid(),
	// 			header: true,
	// 			header_text: 'Accounts'
	// 		},
	// 		{
	// 			id: uuid(),
	// 			menuitem: 'Instructor',
	// 			link: '#',
	// 			children: [
	// 				{
	// 					id: uuid(),
	// 					header: true,
	// 					header_text: 'Instructor',
	// 					description: 'Instructor dashboard for manage courses and earning.'
	// 				},
	// 				{
	// 					id: uuid(),
	// 					divider: true
	// 				},
	// 				{
	// 					id: uuid(),
	// 					menuitem: 'Dashboard',
	// 					link: '/marketing/instructor/dashboard/'
	// 				},
	// 				{
	// 					id: uuid(),
	// 					menuitem: 'Profile',
	// 					link: '/marketing/instructor/instructor-profile/'
	// 				},
	// 				{
	// 					id: uuid(),
	// 					menuitem: 'My Courses',
	// 					link: '/marketing/instructor/instructor-my-courses/'
	// 				},
	// 				{
	// 					id: uuid(),
	// 					menuitem: 'Orders',
	// 					link: '/marketing/instructor/instructor-orders/'
	// 				},
	// 				{
	// 					id: uuid(),
	// 					menuitem: 'Reviews',
	// 					link: '/marketing/instructor/instructor-reviews/'
	// 				},
	// 				{
	// 					id: uuid(),
	// 					menuitem: 'Students',
	// 					link: '/marketing/instructor/instructor-students/'
	// 				},
	// 				{
	// 					id: uuid(),
	// 					menuitem: 'Payouts',
	// 					link: '/marketing/instructor/instructor-payouts/'
	// 				},
	// 				{
	// 					id: uuid(),
	// 					menuitem: 'Earning',
	// 					link: '/marketing/instructor/instructor-earnings/'
	// 				}
	// 			]
	// 		},
	// 		{
	// 			id: uuid(),
	// 			menuitem: 'Students',
	// 			link: '#',
	// 			children: [
	// 				{
	// 					id: uuid(),
	// 					header: true,
	// 					header_text: 'Students',
	// 					description:
	// 						'Students dashboard to manage your courses and subscriptions.'
	// 				},
	// 				{
	// 					id: uuid(),
	// 					divider: true
	// 				},
	// 				{
	// 					id: uuid(),
	// 					menuitem: 'Dashboard',
	// 					link: '/marketing/student/dashboard/'
	// 				},
	// 				{
	// 					id: uuid(),
	// 					menuitem: 'Subscriptions',
	// 					link: '/marketing/student/student-subscriptions/'
	// 				},
	// 				{
	// 					id: uuid(),
	// 					menuitem: 'Payments',
	// 					link: '/marketing/student/student-payment/'
	// 				},
	// 				{
	// 					id: uuid(),
	// 					menuitem: 'Billing Info',
	// 					link: '/marketing/student/student-billing-info/'
	// 				},
	// 				{
	// 					id: uuid(),
	// 					menuitem: 'Invoice',
	// 					link: '/marketing/student/student-invoice/'
	// 				},
	// 				{
	// 					id: uuid(),
	// 					menuitem: 'Invoice Details',
	// 					link: '/marketing/student/student-invoice-details/'
	// 				},
	// 				{
	// 					id: uuid(),
	// 					menuitem: 'Bookmarked',
	// 					link: '/marketing/student/dashboard/'
	// 				},
	// 				{
	// 					id: uuid(),
	// 					menuitem: 'My Path',
	// 					link: '/marketing/student/dashboard/'
	// 				}
	// 			]
	// 		},
	// 		{
	// 			id: uuid(),
	// 			menuitem: 'Admin',
	// 			link: '#',
	// 			children: [
	// 				{
	// 					id: uuid(),
	// 					header: true,
	// 					header_text: 'Master Admin',
	// 					description:
	// 						'Master admin dashboard to manage courses, user, site setting, and work with amazing apps.'
	// 				},
	// 				{
	// 					id: uuid(),
	// 					divider: true
	// 				},
	// 				{
	// 					id: uuid(),
	// 					menuitem: 'Go to Dashboard',
	// 					link: '/dashboard/overview/',
	// 					type: 'button'
	// 				}
	// 			]
	// 		}
	// 	]
	// }
];

export default NavbarDefault;
