import {createReducer} from '@reduxjs/toolkit';

import {
    loginSuccess,
    loginFailure,
    clearErrorMessages,
    sendOTPSuccess,
    sendOTPFailure,
    resetOTPSentFlag,
    signUpSuccess,
    signUpFailure, logOut,
    refreshTokenSuccess
} from 'actions/userActions';

const initialState = {
    phoneNumber: null,
    firstName: null,
    lastName: null,
    email: null,
    role: null,
    isLoggedIn: false,
    errorMessage: null,
    OTPError: null,
    isOTPSent: false,
    session: null,
    accessToken: null,
    refreshToken: null,
    expiresIn: null,
    isLoginError: false,
    isSignUp: false,
    isSignUpError: false,
    isSignedUp: false,
    isLoading: false,
    unauthorized: false
};

const userReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(loginSuccess, (state, action) => ({
            ...state,
            accessToken: action.payload.AccessToken,
            refreshToken: action.payload.RefreshToken,
            expiresIn: action.payload.ExpiresIn,
            firstName: action.payload.User.firstName,
            lastName: action.payload.User.lastName,
            role: action.payload.User.role,
            isLoggedIn: true,
            isLoginError: false,
        }))

        .addCase(loginFailure, (state, action) => ({
            ...state,
            errorMessage: action.payload.message,
            invalidCredentials: action.payload.code === '403',
            unauthorized: action.payload.code === '401',
            isLoginError: true,
            isLoading: false,
            isLoggedIn: false,
            isOTPSent: false,
            session: null,
            phoneNumber: null
        }))

        .addCase(
            sendOTPSuccess,
            (state, action) => ({
                ...state,
                session: action.payload.session,
                phoneNumber: action.payload.phoneNumber,
                OTPError: null,
                isOTPSent: true,
                isLoading: false
            })
        )

        .addCase(
            sendOTPFailure,
            (state, action) => ({
                ...state,
                session: null,
                isOTPSent: false,
                OTPError: action.payload.message,
                errorMessage: action.payload.message,
                isLoading: false
            })
        )

        .addCase(
            resetOTPSentFlag,
            (state) => ({
                ...state,
                isOTPSent: false
            })
        )

        .addCase(
            signUpSuccess,
            (state, action) => ({
                ...state,
                isSignedUp: true,
                isSignUp: false,
                firstName: action.payload.firstName,
                lastName: action.payload.lastName,
                email: action.payload.email,
                role: action.payload.role,
                phoneNumber: action.payload.phoneNumber,
                isLoading: false
            })
        )

        .addCase(
            signUpFailure,
            (state, action) => ({
                ...state,
                isSignUpError: true,
                errorMessage: action.payload.message,
                isLoading: false
            })
        )

        .addCase(
            clearErrorMessages,
            (state) => ({
                ...state,
                errorMessage: null,
                invalidCredentials: false,
                OTPError: null,
                isLoginError: false,
                isSignUpError: false,
                isLoading: false,
            })
        )

        .addCase(
            refreshTokenSuccess,
            (state, action) => ({
                ...state,
                accessToken: action.payload.AccessToken,
                expiresIn: action.payload.ExpiresIn
            })
        )

        .addCase(
            logOut,
            () => (initialState)
        );


});

export default userReducer;
