export const DashboardMenu = [
    {
        id: 1,
        title: 'My Courses',
        link: '/instructor/instructor-my-courses/',
        icon: 'book'
    },
    {
        id: 2,
        title: 'Add Questions',
        link: '/quiz/add-quiz',
        icon: 'edit'
    },
    {
        id: 3,
        title: 'List Questions',
        link: '/quiz/list-quiz',
        icon: 'list'
    }
];

export const InstructorDashboardMenu = [
    {
        id: 1,
        title: 'Dashboard',
        children: DashboardMenu
    }];

export default InstructorDashboardMenu;
