import { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';

import {MediaUploaderVimeo} from './VideoUploaderVimeo';

const MediaUploader = ({ name, onChange, onBlur, isInvalid, category, requireDuration = false }) => {
    const [progress, setProgress] = useState(0);
    const [isUploading, setUploading] = useState(false);
    const accessToken = useSelector(state => state.user.accessToken);

    return (
        <Fragment>
            <Form.Control
                name={name}
                type="file"
                className="form-control"
                accept="video/*"
                isInvalid={isInvalid}
                onChange={async e => {
                    const file = e.target.files[0];

                    if (file) {
                        setProgress(0);
                        setUploading(true);
                        const uploader = new MediaUploaderVimeo(accessToken);
                        const videoMeta = await uploader.upload(
                            file,
                            category,
                            progress => {
                                setProgress(prevProgress => prevProgress + progress);
                            }
                        );
                        setUploading(false);
                        
                        if (requireDuration) {
                            let duration = 0;
                            const video = document.createElement('video');
                            video.preload = 'metadata';

                            video.onloadedmetadata = function() {
                                window.URL.revokeObjectURL(video.src);
                                duration = Math.round(video.duration);
                                
                                onChange({
                                    target: {
                                        name: name,
                                        value: videoMeta.url,
                                        duration
                                    }
                                });
                            }

                            video.src = URL.createObjectURL(file);
                        } else {
                            onChange({
                                target: {
                                    name: name,
                                    value: videoMeta.url,
                                }
                            });
                        }
                    } else {
                        onChange({
                            target: {
                                name: name,
                                value: null
                            }
                        });
                    }
                }}
                onBlur={onBlur}
            />
            {<Form.Label
                className="input-group-text mb-0"
            >
                {isUploading ? `Progress: ${Math.floor(progress)}%` : 'Upload'}
            </Form.Label>}
        </Fragment>
    );
};

export default MediaUploader;
