import {createAction} from '@reduxjs/toolkit';

export const getCourseRequest = createAction('GET_COURSE_REQUEST');
export const getCourseSuccess = createAction('GET_COURSE_SUCCESS');
export const getCourseFailure = createAction('GET_COURSE_FAILURE');

export const createCourseRequest = createAction('CREATE_COURSE_REQUEST');
export const createCourseSuccess = createAction('CREATE_COURSE_SUCCESS');
export const createCourseFailure = createAction('CREATE_COURSE_FAILURE');

export const updateCourseRequest = createAction('UPDATE_COURSE_REQUEST');
export const updateCourseSuccess = createAction('UPDATE_COURSE_SUCCESS');
export const updateCourseFailure = createAction('UPDATE_COURSE_FAILURE');

export const deleteCourseRequest = createAction('DELETE_COURSE_REQUEST');
export const deleteCourseSuccess = createAction('DELETE_COURSE_SUCCESS');
export const deleteCourseFailure = createAction('DELETE_COURSE_FAILURE');

export const submitCourseRequest = createAction('SUBMIT_COURSE_REQUEST');
export const submitCourseSuccess = createAction('SUBMIT_COURSE_SUCCESS');
export const submitCourseFailure = createAction('SUBMIT_COURSE_FAILURE');

export const updateCourseStatusRequest = createAction('UPDATE_COURSE_STATUS_REQUEST');
export const updateCourseStatusSuccess = createAction('UPDATE_COURSE_STATUS_SUCCESS');
export const updateCourseStatusFailure = createAction('UPDATE_COURSE_STATUS_FAILURE');

export const createChapterRequest = createAction('CREATE_CHAPTER_REQUEST');
export const createChapterSuccess = createAction('CREATE_CHAPTER_SUCCESS');
export const createChapterFailure = createAction('CREATE_CHAPTER_FAILURE');

export const updateChapterRequest = createAction('UPDATE_CHAPTER_REQUEST');
export const updateChapterSuccess = createAction('UPDATE_CHAPTER_SUCCESS');
export const updateChapterFailure = createAction('UPDATE_CHAPTER_FAILURE');

export const deleteChapterRequest = createAction('DELETE_CHAPTER_REQUEST');
export const deleteChapterSuccess = createAction('DELETE_CHAPTER_SUCCESS');
export const deleteChapterFailure = createAction('DELETE_CHAPTER_FAILURE');

export const createLessonRequest = createAction('CREATE_LESSON_REQUEST');
export const createLessonSuccess = createAction('CREATE_LESSON_SUCCESS');
export const createLessonFailure = createAction('CREATE_LESSON_FAILURE');

export const updateLessonRequest = createAction('UPDATE_LESSON_REQUEST');
export const updateLessonSuccess = createAction('UPDATE_LESSON_SUCCESS');
export const updateLessonFailure = createAction('UPDATE_LESSON_FAILURE');

export const deleteLessonRequest = createAction('DELETE_LESSON_REQUEST');
export const deleteLessonSuccess = createAction('DELETE_LESSON_SUCCESS');
export const deleteLessonFailure = createAction('DELETE_LESSON_FAILURE');

export const createFAQRequest = createAction('CREATE_FAQ_REQUEST');
export const createFAQSuccess = createAction('CREATE_FAQ_SUCCESS');
export const createFAQFailure = createAction('CREATE_FAQ_FAILURE');

export const createTaskRequest = createAction('CREATE_TASK_REQUEST');
export const createTaskSuccess = createAction('CREATE_TASK_SUCCESS');
export const createTaskFailure = createAction('CREATE_TASK_FAILURE');


export const updateTaskRequest = createAction('UPDATE_TASK_REQUEST');
export const updateTaskSuccess = createAction('UPDATE_TASK_SUCCESS');
export const updateTaskFailure = createAction('UPDATE_TASK_FAILURE');

export const deleteTaskRequest = createAction('DELETE_TASK_REQUEST');
export const deleteTaskSuccess = createAction('DELETE_TASK_SUCCESS');
export const deleteTaskFailure = createAction('DELETE_TASK_FAILURE');

export const createQuestionRequest = createAction('CREATE_QUESTION_REQUEST');
export const createQuestionSuccess = createAction('CREATE_QUESTION_SUCCESS');
export const createQuestionFailure = createAction('CREATE_QUESTION_FAILURE');

export const getQuizRequest = createAction('GET_QUIZ_REQUEST');
export const getQuizSuccess = createAction('GET_QUIZ_SUCCESS');
export const getQuizFailure = createAction('GET_QUIZ_FAILURE');

export const updateQuestionRequest = createAction('UPDATE_QUESTION_REQUEST');
export const updateQuestionSuccess = createAction('UPDATE_QUESTION_SUCCESS');
export const updateQuestionFailure = createAction('UPDATE_QUESTION_FAILURE');

export const updateFAQRequest = createAction('UPDATE_FAQ_REQUEST');
export const updateFAQSuccess = createAction('UPDATE_FAQ_SUCCESS');
export const updateFAQFailure = createAction('UPDATE_FAQ_FAILURE');

export const deleteFAQRequest = createAction('DELETE_FAQ_REQUEST');
export const deleteFAQSuccess = createAction('DELETE_FAQ_SUCCESS');
export const deleteFAQFailure = createAction('DELETE_FAQ_FAILURE');

export const getCoursesRequest = createAction('GET_COURSES_REQUEST');
export const getCoursesSuccess = createAction('GET_COURSES_SUCCESS');
export const getCoursesFailure = createAction('GET_COURSES_FAILURE');

export const getTransactionsRequest = createAction('GET_TRANSACTIONS_REQUEST');
export const getTransactionsSuccess = createAction('GET_TRANSACTIONS_SUCCESS');
export const getTransactionsFailure = createAction('GET_TRANSACTIONS_FAILURE');

export const getShipmentsRequest = createAction('GET_SHIPMENTS_REQUEST');
export const getShipmentsSuccess = createAction('GET_SHIPMENTS_SUCCESS');
export const getShipmentsFailure = createAction('GET_SHIPMENTS_FAILURE');

export const getTransactionsStatsRequest = createAction('GET_TRANSACTIONS_STATS_REQUEST');
export const getTransactionsStatsSuccess = createAction('GET_TRANSACTIONS_STATS_SUCCESS');
export const getTransactionsStatsFailure = createAction('GET_TRANSACTIONS_STATS_FAILURE');

export const getAssignmentsRequest = createAction('GET_ASSIGNMENTS_REQUEST');
export const getAssignmentsSuccess = createAction('GET_ASSIGNMENTS_SUCCESS');
export const getAssignmentsFailure = createAction('GET_ASSIGNMENTS_FAILURE');

export const deleteAssignmentsRequest = createAction('DELETE_ASSIGNMENTS_REQUEST');
export const deleteAssignmentsSuccess = createAction('DELETE_ASSIGNMENTS_SUCCESS');
export const deleteAssignmentsFailure = createAction('DELETE_ASSIGNMENTS_FAILURE');

export const updateAssignmentsRequest = createAction('UPDATE_ASSIGNMENTS_REQUEST');
export const updateAssignmentsSuccess = createAction('UPDATE_ASSIGNMENTS_SUCCESS');
export const updateAssignmentsFailure = createAction('UPDATE_ASSIGNMENTS_FAILURE');

export const clearDraftCourseStatus = createAction('CLEAR_DRAFT_COURSE_STATUS');

export const resetDraftCourse = createAction('RESET_DRAFT_COURSE');

export const getCourseBannerRequest = createAction('GET_COURSE_BANNER_REQUEST');
export const getCourseBannerSuccess = createAction('GET_COURSE_BANNER_SUCCESS');
export const getCourseBannerFailure = createAction('GET_COURSE_BANNER_FAILURE');

export const uploadCourseBannerRequest = createAction('UPLOAD_COURSE_BANNER_REQUEST');
export const uploadCourseBannerSuccess = createAction('UPLOAD_COURSE_BANNER_SUCCESS');
export const uploadCourseBannerFailure = createAction('UPLOAD_COURSE_BANNER_FAILURE');

export const updateCourseBannerRequest = createAction('UPDATE_COURSE_BANNER_REQUEST');
export const updateCourseBannerSuccess = createAction('UPDATE_COURSE_BANNER_SUCCESS');
export const updateCourseBannerFailure = createAction('UPDATE_COURSE_BANNER_FAILURE');
export const clearUploadBannerError = createAction('CLEAR_UPLOAD_BANNER_ERROR');

export const deleteCourseBannerSuccess = createAction('DELETE_COURSE_BANNER_SUCCESS');
export const deleteCourseBannerFailure = createAction('DELETE_COURSE_BANNER_FAILURE');
export const deleteCourseBannerRequest = createAction('DELETE_COURSE_BANNER_REQUEST');

export const courseOfflineEnrollRequest = createAction('COURSE_OFFLINE_ENROLL_REQUEST');
export const courseOfflineEnrollSuccess = createAction('COURSE_OFFLINE_ENROLL_SUCCESS');
export const courseOfflineEnrollFailure = createAction('COURSE_OFFLINE_ENROLL_FAILURE');

export const deleteTransactionRequest = createAction('DELETE_TRANSACTION_REQUEST');
export const deleteTransactionSuccess = createAction('DELETE_TRANSACTION_SUCCESS');
export const deleteTransactionFailure = createAction('DELETE_TRANSACTION_FAILURE');
