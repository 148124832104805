// import node module libraries
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Tab, Card, Nav } from 'react-bootstrap';

// import sub custom components
import AssignmentTable from './AssignmentTable';
import ProfileLayout from "../../../instructor/ProfileLayout";
import { getAssignmentsRequest } from '../../../../actions/courseActions';

import { Form } from 'react-bootstrap';

const status = {
	ALL: 'ALL',
	GRADED: 'GRADED',
	NOT_GRADED: 'NOT GRADED',
	ACTIVE: 'active'
}

const AllAssignments = () => {
	const dispatch = useDispatch();
	const assignments = useSelector(state => state.courses?.assignments);
	const [courseList, setCourseList] = useState([status.ALL]);
	const [experimentList, setExperimentList] = useState([status.ALL]);
	const [usersList, setUsersList] = useState([status.ALL]);
	const gradeList = [status.ALL, status.GRADED, status.NOT_GRADED];
	const [selectedCourse, setSelectedCourse] = useState(status.ALL);
	const [selectedGrade, setSelectedGrade] = useState(status.ALL);
	const [selectedExperiment, setSelectedExperiment] = useState(status.ALL);
	const [selectedUser, setSelectedUser] = useState(status.ALL);
	const [filteredAssignments, setFilteredAssignments] = useState(assignments.filter(x => x.status === status.ACTIVE));
	const [selectedShowInactive, setSelectedShowInactive] = useState(false);

	useEffect(() => {
		dispatch(getAssignmentsRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let courses = [];
		let experiments = [];
		let users = [];

		if (assignments.length > 0) {
			assignments.map(function (val, index) {
				var existingCourse = courses.findIndex(x => x === val.courseEnrollment.course.name);
				var existingExperiment = experiments.findIndex(x => x === val.lesson.name);
				var existingUsers = users.findIndex(x => x === val.courseEnrollment.userName);

				if (existingCourse < 0)
					courses.push(val.courseEnrollment.course.name);

				if (existingExperiment < 0)
					experiments.push(val.lesson.name);

				if (existingUsers < 0)
					users.push(val.courseEnrollment.userName);
			})
		}

		setCourseList([status.ALL, ...courses]);
		setExperimentList([status.ALL, ...experiments]);
		setUsersList([status.ALL, ...users]);
		applyFilters();
	}, [assignments]);

	useEffect(() => {
		applyFilters();
	}, [selectedCourse, selectedExperiment,
		selectedGrade, selectedShowInactive, selectedUser]);

	const applyFilters = () => {
		const gradedCheck = (x, filter) => {
			switch (filter) {
				case status.GRADED: return x.assignmentScore >= 0 && x.assignmentScore != null;
				case status.NOT_GRADED: return x.assignmentScore == null && (!selectedShowInactive && x.status === status.ACTIVE);
				default: return true;
			}
		}
		const experimentCheck = (x, filter) => filter === status.ALL ? true : x.lesson.name === filter;
		const courseCheck = (x, filter) => filter === status.ALL ? true : x.courseEnrollment.course.name === filter;
		const userCheck = (x, filter) => filter === status.ALL ? true : x.courseEnrollment.userName === filter;
		const isInActiveCheck = (x, filter) => filter ^ x.status === status.ACTIVE;
		const tempAssignments = assignments.filter(x => {
			return gradedCheck(x, selectedGrade)
				&& experimentCheck(x, selectedExperiment)
				&& courseCheck(x, selectedCourse)
				&& userCheck(x, selectedUser) && isInActiveCheck(x, selectedShowInactive);
		})
		setFilteredAssignments(tempAssignments);
	}

	const dropDown = (options, selectedOption, handleSelect) => {
		return <Form.Select value={selectedOption} onChange={e => handleSelect(e.target.value)}>
			{options && options.map((val, index) => <option key={index} value={val}>{val}</option>)}
		</Form.Select>
	}

	return (
		<ProfileLayout>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<Tab.Container defaultActiveKey="all">
						<Card>
							<Card.Header className="border-bottom-0 p-0 bg-white">
								<Nav className="nav-lb-tab">
									<Nav.Item>
										<Nav.Link eventKey="all" className="mb-sm-3 mb-md-0">
											All
										</Nav.Link>
									</Nav.Item>
								</Nav>
							</Card.Header>
							<Card.Header>
								<Row>
									<Col lg={4} md={5} sm={12}>
										<Card.Title style={{ textAlign: 'center' }}>Grade</Card.Title>
										{dropDown(gradeList, selectedGrade, (value) => setSelectedGrade(value))}
									</Col>
									<Col lg={4} md={5} sm={12}>
										<Card.Title style={{ textAlign: 'center' }}>Course</Card.Title>
										{dropDown(courseList, selectedCourse, (value) => setSelectedCourse(value))}
									</Col>
									<Col lg={4} md={5} sm={12}>
										<Card.Title style={{ textAlign: 'center' }}>Experiment</Card.Title>
										{dropDown(experimentList, selectedExperiment, (value) => setSelectedExperiment(value))}
									</Col>
									{/* <Col lg={3} md={5} sm={12}>
										{dropDown(gradeList, selectedGradeList, (value) => setSelectedGradeList(value), 'grade')}
                            	    </Col> */}
								</Row>
								<Row style={{ marginTop: '20px' }}>
									<Col lg={4} md={5} sm={12}>
										<Card.Title style={{ textAlign: 'center' }}>User</Card.Title>
										{dropDown(usersList, selectedUser, (value) => setSelectedUser(value))}
									</Col>
									<Col lg={8} md={7} sm={12} style={{ alignSelf: 'center' }}>
										<Form.Check
											checked={selectedShowInactive}
											type='checkbox'
											id={`show-inactive-checkbox`}
											label={`Show Inactive assignments`}
											onChange={e => setSelectedShowInactive(!selectedShowInactive)}
										/>
									</Col>
								</Row>
							</Card.Header>
							<Card.Body className="p-0">
								<Tab.Content>
									<Tab.Pane eventKey="all" className="pb-4">
										<AssignmentTable assignments_data={filteredAssignments} />
									</Tab.Pane>
									{/* <Tab.Pane eventKey="approved" className="pb-4">
										<AssignmentTable courses_data={courses} />
									</Tab.Pane>
									<Tab.Pane eventKey="pending" className="pb-4">
										<AssignmentTable courses_data={courses} />
									</Tab.Pane> */}
								</Tab.Content>
							</Card.Body>
						</Card>
					</Tab.Container>
				</Col>
			</Row>
		</ProfileLayout>
	);
};

export default AllAssignments;
