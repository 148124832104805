// import node module libraries
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
	Card,
	Row,
	Col,
	Nav,
	Tab
} from 'react-bootstrap';

// import custom components
import CoursesTable from './CoursesTable';

// import profile layout wrapper
import ProfileLayout from './ProfileLayout';

// import utility file
import { getCoursesRequest } from 'actions/courseActions';
import jwtDecode from "jwt-decode";
import dayjs from "dayjs";
import { refreshTokenRequest } from 'actions/userActions';

const MyCourses = () => {
	// The forwardRef is important!!
	// Dropdown needs access to the DOM node in order to position the Menu
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			{children}
		</Link>
	));
	const dispatch = useDispatch();
	const courses = useSelector(state => state.courses.courses);
	const { accessToken } = useSelector(state => state.user);

	useEffect(() => {
		dispatch(getCoursesRequest());

		const userData = jwtDecode(accessToken ?? '');
    	const isExpired = dayjs.unix(userData.exp).diff(dayjs()) < 1;
		console.log(dayjs.unix(userData.exp));

		if(isExpired) {
		  dispatch(refreshTokenRequest());
		}
	}, [])

	return (
		<ProfileLayout>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<Tab.Container defaultActiveKey="all">
						<Card>
							<Card.Header className="border-bottom-0 p-0 bg-white">
								<Nav className="nav-lb-tab">
									<Nav.Item>
										<Nav.Link eventKey="all" className="mb-sm-3 mb-md-0">
											All
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="approved" className="mb-sm-3 mb-md-0">
											Approved
										</Nav.Link>
									</Nav.Item>
									<Nav.Item>
										<Nav.Link eventKey="pending" className="mb-sm-3 mb-md-0">
											Pending
										</Nav.Link>
									</Nav.Item>
								</Nav>
							</Card.Header>
							<Card.Body className="p-0">
								<Tab.Content>
									<Tab.Pane eventKey="all" className="pb-4">
										<CoursesTable courses_data={courses} />
									</Tab.Pane>
									<Tab.Pane eventKey="approved" className="pb-4">
										<CoursesTable courses_data={courses.filter(course => course.status === 'approved')} />
									</Tab.Pane>
									<Tab.Pane eventKey="pending" className="pb-4">
										<CoursesTable courses_data={courses.filter(course => course.status === 'pending')} />
									</Tab.Pane>
								</Tab.Content>
							</Card.Body>
						</Card>
					</Tab.Container>
				</Col>
			</Row>
		</ProfileLayout>
	);
};

export default MyCourses;
