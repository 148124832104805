// import node module libraries
import React, { Fragment, useMemo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination
} from 'react-table';
import { Link } from 'react-router-dom';
import { Col, Row, Table, Dropdown } from 'react-bootstrap';
import { MoreVertical, Trash } from 'react-feather';

// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';

import { deleteTransactionRequest, getTransactionsRequest } from 'actions/courseActions';

import moment from "moment/moment";

const TransactionsTable = ({pageSize, startDate, endDate, selectedStatusOption }) => {
	// The forwardRef is important!!
	// Dropdown needs access to the DOM node in order to position the Menu
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to="#"
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			{children}
		</Link>
	));

	const dispatch = useDispatch();
	const {transactions, transactionsCount} = useSelector(state => state.courses);
	const [filterQuery, setFilterQuery] = useState(null);
	const [pageCount, setPageCount] = useState(Math.max(1, Math.ceil(transactionsCount/pageSize)));

	useEffect(() => {
		setPageCount(Math.max(1, Math.ceil(transactionsCount/pageSize)));
	}, [transactionsCount]);

	const ActionMenu = ({ id }) => {
		return (
			<Dropdown>
				<Dropdown.Toggle as={CustomToggle}>
					<MoreVertical size="15px" className="text-secondary" />
				</Dropdown.Toggle>
				<Dropdown.Menu align="end">
					<Dropdown.Header>SETTINGS</Dropdown.Header>
					<Dropdown.Item eventKey="2" onClick={() => dispatch(deleteTransactionRequest({ id }))}>
						{' '}
						<Trash size="18px" className="dropdown-item-icon" /> Remove
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	const columns = useMemo(
		() => [
			{ accessor: 'id', Header: 'ID', show: false },
			{ accessor: 'userName', Header: 'USER',
			  Cell: ({ value, row }) => {
			 	return (
			 		<Fragment>
			 			{value}
			 		</Fragment>
			 	);}
			},
			{ accessor: 'phoneNumber', Header: 'MOBILE NO',
			  Cell: ({ value, row }) => {
			 	return (
			 		<Fragment>
			 			{value}
			 		</Fragment>
			 	);}
			},
			{ accessor: 'status', Header: 'STATUS',
			  Cell: ({ value, row }) => {
			 	return (
			 		<Fragment>
			 			{value.charAt(0).toUpperCase() + value.slice(1)}
			 		</Fragment>
			 	);}
			},
			{ accessor: 'course.name', Header: 'COURSE',
			  Cell: ({ value, row }) => {
			 	return (
			 		<Fragment>
			 			{value}
			 		</Fragment>
			 	);}
			},
			{ accessor: 'paymentOrder.amount', Header: 'AMOUNT',
			  Cell: ({ value, row }) => {
			 	return (
			 		<Fragment>
			 			{value}
			 		</Fragment>
			 	);}
			},
			{ accessor: 'paymentOrder.currency', Header: 'CURRENCY',
			  Cell: ({ value, row }) => {
			 	return (
			 		<Fragment>
			 			{value}
			 		</Fragment>
			 	);}
			},
			{ accessor: 'transactionId', Header: 'TRANSACTION ID',
			Cell: ({ value, row }) => {
			   return (
				   <Fragment>
					   {value}
				   </Fragment>
			   );}
		  	},
			{ accessor: 'transactedAt', Header: 'TRANSACTED AT',
			  Cell: ({ value, row }) => {
			 	return (
			 		<Fragment>
			 			{moment(value).format("DD/MM/YYYY HH:mm")}
			 		</Fragment>
			 	);}
			},
			{ accessor: 'reference', Header: 'REFERENCE',
			  Cell: ({ value, row }) => {
			 	return (
			 		<Fragment>
			 			{value}
			 		</Fragment>
			 	);}
			},
			{
				accessor: 'shortcutmenu',
				Header: '',
				Cell: ({ row }) => {
					return row.original.status === 'offline' ? (<ActionMenu id={row.original.paymentOrder.orderId} />) : null;
				}
			}
		],
		[]
	);

	const data = useMemo(() => transactions, [transactions]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		state,
		gotoPage,
		prepareRow
	} = useTable(
		{
			columns,
			data,
			manualPagination: true,
			manualGlobalFilter: true,
			autoResetPage: false,
			pageCount: pageCount,
			initialState: {
				hiddenColumns: columns.map((column) => {
					if (column.show === false) return column.accessor || column.id;
					else return false;
				})
			}
		},
		useGlobalFilter,
		usePagination
	);

	const { pageIndex, globalFilter } = state;

	useEffect(() => {
		dispatch(getTransactionsRequest({ 
			status: selectedStatusOption,
			startDate,
			endDate,  
			page: pageIndex + 1, 
			limit: pageSize,
			search: filterQuery }));
	}, [pageIndex]);

	useEffect(() => {
		dispatch(getTransactionsRequest({ 
			status: selectedStatusOption,
			startDate,
			endDate,
			page: 1,
			limit: pageSize,
			search: filterQuery }));
	}, [filterQuery]);

	return (
		<Fragment>
			<div className=" overflow-hidden">
				<Row>
					<Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-4 px-5 ">
						<GlobalFilter
							filter={globalFilter}
							setFilter={setFilterQuery}
							placeholder="Search Transactions"
						/>
					</Col>
				</Row>
			</div>

			<div className="table-responsive border-0">
				<Table {...getTableProps()} className="text-nowrap">
					<thead className="table-light">
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps()}>
										{column.render('Header')}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell) => {
										return (
											<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</Table>
			</div>

			{/* Pagination @ Footer */}
			<Pagination
				previousPage={previousPage}
				pageCount={pageCount}
				pageIndex={pageIndex}
				gotoPage={gotoPage}
				nextPage={nextPage}
			/>
		</Fragment>
	);
};

export default TransactionsTable;
