import { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Form, Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { Formik } from 'formik';
import * as yup from 'yup';
import parse from 'html-react-parser';
import RichTextEditor from 'components/elements/editor/RichTextEditor';

import { createFAQRequest, updateFAQRequest, deleteFAQRequest } from 'actions/courseActions';
import useConfirm from 'hooks/useConfirm';

const AddFAQ = ({ onAddFAQ, editFAQ, onEditFAQ, onCancelEdit }) => {
	const [show, setShow] = useState(!!editFAQ);

	const handleClose = () => {
		setShow(false);
		onCancelEdit();
	};
	const handleShow = () => setShow(true);

	useEffect(() => {
		setShow(!!editFAQ);
	}, [editFAQ]);

	const schema = yup.object().shape({
		question: yup.string().required(),
		answer: yup.string().required(),
		order: yup.number().required()
	});

	return (
		<Fragment>
			<Button
				variant="outline-primary"
				className="btn btn-outline-primary btn-sm mt-3"
				onClick={handleShow}
			>
				Add FAQ
			</Button>
			<Modal
                size="lg"
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{!!editFAQ ? 'Update FAQ' : 'Add New FAQ'}
					</Modal.Title>
				</Modal.Header>
				<Formik
					enableReinitialize
					validationSchema={schema}
					onSubmit={e => {
						!!editFAQ ? onEditFAQ({ ...editFAQ, ...e }) : onAddFAQ(e); 
						 handleClose(); 
					}}
					initialValues={{
						question: editFAQ?.question || '',
						answer: editFAQ?.answer || '',
						order: editFAQ?.order
					}}
				>
					{({
						handleSubmit,
						handleChange,
						handleBlur,
						values,
						touched,
						isValid,
						errors
					}) => (
						<Form noValidate onSubmit={(e) => { e.preventDefault(); handleSubmit(e); }}>
							<Modal.Body className="pb-0">
								<Form.Group className="mb-3" controlId="formaddquestion">
									<Form.Label>Question</Form.Label>
									<Form.Control 
										type="text" 
										placeholder="Add question" 
										name="question" 
										onChange={handleChange} 
										value={values.question}
										onBlur={handleBlur}
										isInvalid={touched.question && !!errors.question}
									/>
									<Form.Control.Feedback type="invalid">
										Question is required
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group className="mb-3" controlId="formaddanswer">
									<Form.Label>Answer</Form.Label>
									<RichTextEditor
										value={values.answer}
										id="answer"
										name="answer"
										onChange={handleChange}
										onBlur={handleBlur}
										isInValid={touched.answer && !!errors.answer}
									/>
									<Form.Control.Feedback type="invalid">
										Answer is required
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group className="mb-3" controlId="formaddfaqorder">
									<Form.Label>FAQ seq no</Form.Label>
									<Form.Control 
										type="text" 
										placeholder="Add Seq number" 
										name="order" 
										onChange={handleChange} 
										value={values.order}
										onBlur={handleBlur}
										isInvalid={touched.order && !!errors.order}
									/>
									<Form.Control.Feedback type="invalid">
										Seq number is required
									</Form.Control.Feedback>
								</Form.Group>
							</Modal.Body>
							<Modal.Footer className="pt-0 border-0 d-inline ">
								<Button variant="primary" type="submit">
									{!!editFAQ ? 'Update FAQ' : 'Add New FAQ'}
								</Button>
								<Button variant="outline-secondary" onClick={handleClose}>
									Close
								</Button>
							</Modal.Footer>
						</Form>
					)}
				</Formik>
			</Modal>
		</Fragment>
	);
};

const FAQs = ({ next, previous, data, handleChange }) => {
    const dispatch = useDispatch();
	const [editFAQ, setEditFAQ] = useState(null);

    const handleAddFAQ = faq => {
		dispatch(createFAQRequest({ ...faq, courseId: data.id }));
	}

	const handleEditFAQ = faq => {
		dispatch(updateFAQRequest({
			id: faq.id,
			question: faq.question,
			answer: faq.answer,
			order: faq.order
		}))
	}

	const handleDeleteFAQ = faq => {
		dispatch(deleteFAQRequest(faq));
	}

	const {confirm} = useConfirm();
    const showConfirm = async (faq) => {
        const isConfirmed = await confirm('Are you sure?');
        if (isConfirmed) {
            handleDeleteFAQ(faq)
        }
    }

    return <div>
        <Card className="mb-3  border-0">
            <Card.Header className="border-bottom px-4 py-3">
                <h4 className="mb-0">FAQs</h4>
            </Card.Header>
            {/* Card body */}
            <Card.Body>
                {
                    data.faqs.map((faq, i) => (
                        <div className="bg-light rounded p-2 mb-4">
                            <div className="d-flex align-items-center justify-content-between">
                                <h5>{`${i + 1}) ${faq.question}`}</h5>
                                <div>
                                    <OverlayTrigger
                                        key={uuid()}
                                        placement="top"
                                        overlay={<Tooltip id="tooltip-top"> Edit</Tooltip>}
                                    >
                                        <Link to="#" className="me-1 text-inherit" title="Edit" onClick={() => setEditFAQ(faq)}>
                                            <i className="fe fe-edit fs-6"></i>
                                        </Link>
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        key={uuid()}
                                        placement="top"
                                        overlay={<Tooltip id="tooltip-top"> Delete</Tooltip>}
                                    >
                                        <Link to="#" className="me-1 text-inherit" title="Delete" onClick={() => showConfirm(faq)}>
                                            <i className="fe fe-trash-2 fs-6"></i>
                                        </Link>
                                    </OverlayTrigger>
                                </div>
                            </div>
                            {parse(faq.answer)}
                        </div>
                    ))
                }
                <AddFAQ 
                    onAddFAQ={handleAddFAQ} 
                    editFAQ={editFAQ}
                    onEditFAQ={handleEditFAQ}
                    onCancelEdit={setEditFAQ.bind(this, null)}
                />
            </Card.Body>
        </Card>
        {/* Button */}
        <div className="d-flex justify-content-between">
            <Button variant="secondary" onClick={previous}>
                Previous
            </Button>
            <Button variant="primary" onClick={next}>
                Next
            </Button>
        </div>
    </div>
}

export default FAQs;