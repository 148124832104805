import axios from 'axios';
import * as tus from 'tus-js-client';
import { URL } from 'shared/url';

export class MediaUploaderVimeo {
    accessToken = null;

    constructor(accessToken) {
        this.accessToken = accessToken;
    }
    async upload(
        file,
        path,
        onProgress
    ){
        const [fileName, ext] = file.name.split('.');
        const chunkSize = Math.pow(1024, 2) * 5; // chunk size is set to 5MB
        const fileSize = file.size;
        const iterations = Math.ceil(fileSize / chunkSize);
        const updatedFileName = `${fileName}_${new Date().getTime()}.${ext}`;

        const presignedLinkResponse = await axios.post(URL.uploadCourseAsset, {
            fileName: updatedFileName,  
            fileType: `video/${ext}`,
            path,
            parts: iterations
        }, { headers: { Authorization: `Bearer ${this.accessToken}` } });
        
        return new Promise(async (resolve, reject) => {
            try {
                const { signedUrl: { uploadId, signedUrls }, objectUrl } = presignedLinkResponse.data;
                const chunkUploadResponses = await Promise.all(Object.keys(signedUrls).map(async (urlKey, i) => {
                    const offset = i * chunkSize;
                    const currentChunkSize = offset + chunkSize > fileSize ? fileSize - offset : chunkSize;
                    const response = await axios.put(signedUrls[urlKey], offset + chunkSize > fileSize ? file.slice(offset) : file.slice(offset, offset + chunkSize));
                    onProgress((currentChunkSize / fileSize) * 100);
                    return { ETag: response.headers['etag'], PartNumber: i + 1  }
                }));
                await axios.post(URL.coursesVideoCompleteUpload, {
                    fileName: updatedFileName,
                    path,
                    uploadId,
                    parts: chunkUploadResponses
                }, { headers: { Authorization: `Bearer ${this.accessToken}` } });
                resolve({ url: objectUrl })
            } catch(error) {
                reject(error);
            }
            // const uploader = new tus.Upload(file, {
            //     uploadUrl: signedUrl,
            //     endpoint: signedUrl,
            //     onError: (error) => {
            //         reject(error);
            //     },
            //     onProgress: (bytesUploaded, bytesTotal) => {
            //         onProgress?.(bytesUploaded, bytesTotal);
            //     },
            //     onSuccess: () => {
            //         resolve({
            //             url: objectUrl
            //         });
            //     },
            // });
            // uploader.start();
        });
    }
}
