import { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Form, Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { Formik } from 'formik';
import * as yup from 'yup';
import { createTaskRequest, updateTaskRequest, deleteTaskRequest } from 'actions/courseActions';
import useConfirm from 'hooks/useConfirm';

const AddTask = ({ onAddTask, editTask, onEditTask, onCancelEdit }) => {
	const [show, setShow] = useState(!!editTask);

	const handleClose = () => {
		setShow(false);
		onCancelEdit();
	};
	const handleShow = () => setShow(true);

	useEffect(() => {
		setShow(!!editTask);
	}, [editTask]);

	const schema = yup.object().shape({
		task: yup.string().required()
	});

	return (
		<Fragment>
			<Button
				variant="outline-primary"
				className="btn btn-outline-primary btn-sm mt-3"
				onClick={handleShow}
			>
				Add Task
			</Button>
			<Modal
				size="lg"
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{!!editTask ? 'Update Task' : 'Add New Task'}
					</Modal.Title>
				</Modal.Header>
				<Formik
					enableReinitialize
					validationSchema={schema}
					onSubmit={e => {
						!!editTask ? onEditTask({ ...editTask, ...e }) : onAddTask(e);
						handleClose();
					}}
					initialValues={{
						task: editTask?.task || ''
					}}
				>
					{({
						handleSubmit,
						handleChange,
						handleBlur,
						values,
						touched,
						errors
					}) => (
						<Form noValidate onSubmit={(e) => { e.preventDefault(); handleSubmit(e); }}>
							<Modal.Body className="pb-0">
								<Form.Group className="mb-3" controlId="formaddTask">
									<Form.Label>Task</Form.Label>
									<Form.Control
										type="text"
										placeholder="Add task"
										name="task"
										onChange={handleChange}
										value={values.task}
										onBlur={handleBlur}
										isInvalid={touched.task && !!errors.task}
									/>
									<Form.Control.Feedback type="invalid">
										Task is required
									</Form.Control.Feedback>
								</Form.Group>
							</Modal.Body>
							<Modal.Footer className="pt-0 border-0 d-inline ">
								<Button variant="primary" type="submit">
									{!!editTask ? 'Update Task' : 'Add Task'}
								</Button>
								<Button variant="outline-secondary" onClick={handleClose}>
									Close
								</Button>
							</Modal.Footer>
						</Form>
					)}
				</Formik>
			</Modal>
		</Fragment>
	);
};

const Tasks = ({ next, previous, data, handleChange }) => {
	const dispatch = useDispatch();
	const [editTask, setEditTask] = useState(null);

	const handleAddTask = taskObj => {
		dispatch(createTaskRequest({ ...taskObj, courseId: data.id }));
	}

	const handleEditTask = taskObj => {
		dispatch(updateTaskRequest({
			id: taskObj.id,
			task: taskObj.task,
		}))
	}

	const handleDeleteTask = taskObj => {
		dispatch(deleteTaskRequest(taskObj));
	}

	const {confirm} = useConfirm();
    const showConfirm = async (task) => {
        const isConfirmed = await confirm('Are you sure?');
        if (isConfirmed) {
            handleDeleteTask(task)
        }
    }

	return <div>
		<Card className="mb-3  border-0">
			<Card.Header className="border-bottom px-4 py-3">
				<h4 className="mb-0">Tasks</h4>
			</Card.Header>
			{/* Card body */}
			<Card.Body>
				{
					data.tasks.map((task, i) => (
						<div key = {i+1} className="bg-light rounded p-2 mb-4">
							<div className="d-flex align-items-center justify-content-between">
								<h5>{`${i + 1}) ${task.task}`}</h5>
								<div>
									<OverlayTrigger
										key={uuid()}
										placement="top"
										overlay={<Tooltip id="tooltip-top"> Edit</Tooltip>}
									>
										<Link to="#" className="me-1 text-inherit" title="Edit" onClick={() => setEditTask(task)}>
											<i className="fe fe-edit fs-6"></i>
										</Link>
									</OverlayTrigger>

									<OverlayTrigger
										key={uuid()}
										placement="top"
										overlay={<Tooltip id="tooltip-top"> Delete</Tooltip>}
									>
										<Link to="#" className="me-1 text-inherit" title="Delete" onClick={() => showConfirm(task)}>
											<i className="fe fe-trash-2 fs-6"></i>
										</Link>
									</OverlayTrigger>
								</div>
							</div>
						</div>
					))
				}
				<AddTask
					onAddTask={handleAddTask}
					editTask={editTask}
					onEditTask={handleEditTask}
					onCancelEdit={setEditTask.bind(this, null)}
				/>
			</Card.Body>
		</Card>

		<div className="d-flex justify-content-between">
			<Button variant="secondary" onClick={previous}>
				Previous
			</Button>
			<Button variant="primary" onClick={next}>
				Next
			</Button>
		</div>
	</div>
}

export default Tasks;