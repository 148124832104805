// import node module libraries
import React, { useMemo, useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    useTable,
    useFilters,
    useGlobalFilter,
    usePagination
} from 'react-table';
import {
    Card,
    Row,
    Col,
    Table,
    Form,
    Button,
    Modal,
    Tab
} from 'react-bootstrap';
import ProfileLayout from "components/instructor/ProfileLayout";
import Pagination from 'components/elements/advance-table/Pagination';
import { getQuizRequest, getCoursesRequest, updateQuestionRequest } from 'actions/courseActions';
import AddQuestion from './AddQuestion';
import {toast} from 'react-toastify';
const DisplayQuiz = () => {
    const dispatch = useDispatch();
    const { quiz, courses: coursesData } = useSelector(state => state.courses);
    const [units, setUnits] = useState([{ id: '', name: 'Select unit' }]);
    const [courses, setCourses] = useState([{ id: '', name: 'Select course' }]);
    const [courseUnit, setCourseUnit] = useState({ course: '', unit: '', experiment: '' });
    const [experiments, setExperiment] = useState([{ id: '', name: 'Select experiment' }]);
    const [viewQuestion, setViewQuestion] = useState(null);
    const [oldViewQuestion, setOldViewQuestion] = useState(null);
    const questionStatuses = [{ id: 'all', name: 'All' }, { id: 'pending', name: 'Pending' }, { id: 'approved', name: 'Approved' }, { id: 'rejected', name: 'Rejected' }];
    const [selectedQuestionStatus, setSelectedQuestionStatus] = useState('all');
    const [changeQuestionStatus, setChangeQuestionStatus] = useState(null);
    const questions = useMemo(() => {
        let selectedQuiz;

        if (courseUnit.experiment) {
            const experiment = JSON.parse(courseUnit.experiment);
            const selectedChapter = quiz && quiz.find(q => q.id == experiment.chapterId);
            selectedQuiz = selectedChapter && selectedChapter.lessons.find(l => l.id === experiment.lessonId);
        } else if (courseUnit.unit)  {
            selectedQuiz = quiz && quiz.find(q => q.id == courseUnit.unit);
        } else if (!courseUnit.course) {
            selectedQuiz = {
                // Skip assigning course level quiz
                questions: quiz?.length && !!quiz[0].questions ? [] : quiz
            }
        }
        if (selectedQuiz && selectedQuiz.questions)
            return selectedQuiz.questions.filter(q => 
                selectedQuestionStatus == 'all' || selectedQuestionStatus == q.status);
        else return [];
    }, [quiz, courseUnit, selectedQuestionStatus]);

    useEffect(() => {
        dispatch(getCoursesRequest());
        dispatch(getQuizRequest({ status: 'pending' }));
    }, []);

    useMemo(() => {
        if (quiz && courseUnit.course) {
            const units = [];
			const experiments = [];
			quiz.forEach(chapter => {
				if (chapter.isQuizEnabled)
					units.push({id: chapter.id, name: chapter.name})
				
				chapter.lessons.forEach(lesson => {
					if (lesson.isQuizEnabled)
						experiments.push({ id: JSON.stringify({ chapterId: chapter.id, lessonId: lesson.id }), name: lesson.name })
				})
			})
			setUnits([{ id: '', name: 'Select unit' }, ...units]);
			setExperiment([{ id: '', name: 'Select experiment' }, ...experiments])
        }
    }, [quiz]);

    useEffect(() => {
        if (coursesData) {
            const temp = coursesData.map(c => {return {"id": c.id, "name": c.name}});
            setCourses([{ id: '', name: 'Select course' }, ...temp]);
        }
    }, [coursesData])

    const handleUpdateQuestionStatus = (question) =>
    {
        setChangeQuestionStatus(null);
        dispatch(updateQuestionRequest({ question, chapterId: courseUnit.unit ? Number(courseUnit.unit) : null }));
    }

    const setAndPrepareViewQuestion = (question) => 
    {
        setOldViewQuestion({...question});
        const preparedQuestion = {
            "text": question.text,
            "questionData": {
                "level": question.level,
                "score": question.score,
                "type": question.type,
            },
            "choices": question.choices.map(c => {return {"text": c.text, "isCorrect": c.isCorrect}})
        };
        setViewQuestion(preparedQuestion);
        return;
    }

    const columns = useMemo(
        () => [
            {
                accessor: 'id',
                Header: 'Id',
                Cell: ({ value, row }) => {
                    return (
                        <div className="d-lg-flex">
                            {row.index + 1}
                        </div>
                    );
                }
            },
            {
                accessor: 'question',
                Header: 'Question',
                Cell: ({ value, row }) => {
                    return (
                        <div className="d-lg-flex">
                            {row.original.text}
                        </div>
                    );
                }
            },
            {
                accessor: 'type',
                Header: 'TYPE',
                Cell: ({ row }) => {
                    return <div>{row.original.type}</div>;
                }
            },
            {
                accessor: 'level',
                Header: 'LEVEL',
                Cell: ({ row }) => {
                    return <div>{row.original.level}</div>;
                }
            },
            {
                accessor: 'status',
                Header: 'STATUS',
                Cell: ({ row }) => {
                    return <div>{row.original.status}</div>;
                }
            },
            {
				Header: 'ACTION',
				Cell: ({ row }) => {
					if (row.original.status === 'pending' || changeQuestionStatus === row.original.id) {
						return (
							<Fragment>
                                <Button
									href="#"
									variant="outline"
									className="btn-outline-white btn-sm mx-1"
									onClick={setAndPrepareViewQuestion.bind(this, row.original)}
								>
									Edit
								</Button>
								<Button
									href="#"
									variant="outline"
									className="btn-outline-white btn-sm"
									onClick={handleUpdateQuestionStatus.bind(this, { ...row.original, status: 'rejected' })}
								>
									Reject
								</Button>{' '}
								<Button
									variant="success"
									className="btn-sm"
									onClick={handleUpdateQuestionStatus.bind(this, { ...row.original, status: 'approved' })}
								>
									Approved
								</Button>
							</Fragment>
						);
					} else {
						return (
                            <Fragment>
                                <Button
									href="#"
									variant="outline"
									className="btn-outline-white btn-sm mx-1"
									onClick={setAndPrepareViewQuestion.bind(this, row.original)}
								>
									Edit
								</Button>
                                <Button href="#" variant="secondary" className="btn-sm" onClick={setChangeQuestionStatus.bind(this, row.original.id)}>
                                    Change Status
                                </Button>
                            </Fragment>
						);
					}
				}
			}/*,
            {
                accessor: 'edit',
                Header: 'EDIT',
                show: true
            }*/
        ],
        [courseUnit, changeQuestionStatus]
    );
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        state,
        gotoPage,
        pageCount,
        prepareRow
    } = useTable(
        {
            columns,
            data: questions,
            initialState: {
                pageSize: 25,
                hiddenColumns: columns.map((column) => {
                    if (column.show === false) return column.accessor || column.id;
                    else return false;
                })
            }
        },
        useFilters,
        useGlobalFilter,
        usePagination
    );
    const { pageIndex } = state;


    const dropDown = (options, selectedOption, handleSelect) => {
        return <Form.Select value={selectedOption} style={{ minWidth: "100px", maxWidth: "150px", marginLeft: "10px" }} onChange={e => handleSelect(e.target.value)}>
            {options && options.map(({ id, name }, index) => <option key={index} value={id}>{name}</option>)}
        </Form.Select>
    }

    const prepareQuestion = (question) => 
    {
        if(question)
        {
            return AddQuestion({
                questionKey: 0, 
                choices: question.choices,
                questionText: question.text,
                dropDown: dropDown, 
                questionData: question.questionData,
                onAddChoice: (questionKey) =>{
                    question.choices = [...question.choices, {value:'', isCorrect: false}];
                    setViewQuestion({...question});
                },
                onChangeChoice: (data, questionKey, choiceKey) =>{
                    question.choices[choiceKey] = data;
                    setViewQuestion({...question});
                },
                onRemoveChoice: (questionKey, ChoiceKey) =>{
                    question.choices.splice(ChoiceKey, 1);
                    setViewQuestion({...question});
                },
                setQuestionText: (questionKey, data) => setViewQuestion({...question, "text": data}),
                setData: (questionKey, data) => setViewQuestion({...question, questionData: data}),
                onRemoveQuestion: null
            })
        }
    }

    const onSaveQuestion = (question) =>
	{
        let error = '';
		if(question.choices.length < 1)
			error = "Add choices for each quetion.";
		else if(!question.choices.reduce((or, val) => or || val.isCorrect, false))
			error = "Choices should have atleast one correct choice.";
        if(error){
            toast.error(error);
            return;
        }
		const updatedQuestion = {
            ...oldViewQuestion,
            "text": question.text,
            "choices": question.choices.map(c => {
                const ch = oldViewQuestion.choices.find(oc => oc.text == c.text);
                if(ch)  c = {...c, id: ch.id};;
                return c;
            }),
            "level": question.questionData.level,
            "score": question.questionData.score,
            "type": question.questionData.type
        }
        setViewQuestion(null);
        dispatch(updateQuestionRequest({ question: updatedQuestion, courseId: Number(courseUnit.unit) }));
	}

    return (
        <ProfileLayout>
            <Modal
				show={!!viewQuestion}
				onHide={() => { setViewQuestion(null); setOldViewQuestion(null) }}
				size="lg"
			>
				<Modal.Header closeButton/>
				<Modal.Body className='pt-0'>
                    {prepareQuestion(viewQuestion)} 
                    <Button
							style={{marginLeft:"10px", minWidth:"150px", marginTop: "10px", height: "30px", padding:"0px", float: "right"}}
							type="button"
							className="btn btn-primary mb-2"
							onClick={() => onSaveQuestion(viewQuestion)}
						>
							Save
                    </Button>
				</Modal.Body>
			</Modal>
            <Tab.Container defaultActiveKey="list">
                <Tab.Content>
                    {/*<Tab.Pane eventKey="grid" className="pb-4">*/}
                    {/*	/!* students in list view *!/*/}
                    {/*	<StudentsGridCard studentsList={users}/>*/}
                    {/*	/!* end of students in list view *!/*/}
                    {/*</Tab.Pane>*/}
                    <h4 className="mb-1 h4 fw-light">
                        Question management {questions && <span className="fs-5 text-muted">({questions.length})</span>}
                    </h4>
                    <Card className="border-0">
                        <Card.Header>
                            <Row>
                                <Col lg={3} md={5} sm={12}>
                                    {dropDown(courses, courseUnit.course, (value) => setCourseUnit(() => {
                                        if(value) dispatch(getQuizRequest({ courseId: value }));
                                        else {
                                            dispatch(getQuizRequest({ status: 'pending' }));
                                            setUnits([{ id: '', name: 'Select unit' }]);
                                            setExperiment([{ id: '', name: 'Select experiment' }]);
                                        };
                                        return { course: value, unit: '', experiment: '' }
                                    }))}
                                </Col>
                                <Col lg={3} md={5} sm={12}>
                                    {dropDown(units, courseUnit.unit, (value) => setCourseUnit( () => {
                                        return { ...courseUnit, unit: value, experiment: '' }}))}
                                </Col>
                                <Col lg={3} md={5} sm={12}>
                                    {dropDown(experiments, courseUnit.experiment, (value) => setCourseUnit( () => {
                                        return { ...courseUnit, unit: '', experiment: value }}))}
                                </Col>

                                <Col lg={3} md={5} sm={12}>
                                    {dropDown(questionStatuses, selectedQuestionStatus, (value) => setSelectedQuestionStatus(value))}
                                </Col>
                            </Row>
                        </Card.Header>
                    </Card>
                    <Tab.Pane eventKey="list" className="pb-4">
                        <Card className="mb-5 ">
                            <Card.Body className="p-0">
                                <Row>
                                    <Col lg={12} md={12} sm={12}>
                                        <div className="table-responsive ">
                                            <Table {...getTableProps()} className="text-nowrap">
                                                <thead className="table-light">
                                                    {headerGroups.map((headerGroup) => (
                                                        <tr {...headerGroup.getHeaderGroupProps()}>
                                                            {headerGroup.headers.map((column) => (
                                                                <th {...column.getHeaderProps()}>
                                                                    {column.render('Header')}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    ))}
                                                </thead>
                                                <tbody {...getTableBodyProps()}>
                                                    {page.map((row) => {
                                                        prepareRow(row);
                                                        return (
                                                            <tr {...row.getRowProps()}>
                                                                {row.cells.map((cell) => {
                                                                    return (
                                                                        <td {...cell.getCellProps()}>
                                                                            {cell.render('Cell')}
                                                                        </td>
                                                                    );
                                                                })}
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Col>
                                </Row>
                                {/* Pagination @ Footer */}
                                <Pagination
                                    previousPage={previousPage}
                                    pageCount={pageCount}
                                    pageIndex={pageIndex}
                                    gotoPage={gotoPage}
                                    nextPage={nextPage}
                                />
                            </Card.Body>
                        </Card>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </ProfileLayout>
    );
}

export default DisplayQuiz;