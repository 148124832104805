import {call, put, takeEvery, select, putResolve} from 'redux-saga/effects';
import {createCustomError} from '../errors';
import {
    getCourseFailure,
    getCourseRequest,
    getCoursesFailure,
    getCoursesRequest,
    getCoursesSuccess,
    getTransactionsFailure,
    getTransactionsRequest,
    getTransactionsSuccess,
    getShipmentsFailure,
    getShipmentsRequest,
    getShipmentsSuccess,
    getAssignmentsFailure,
    getAssignmentsRequest,
    getAssignmentsSuccess,
    getCourseSuccess,
    createCourseRequest,
    createCourseSuccess,
    createCourseFailure,
    updateCourseRequest,
    updateCourseSuccess,
    updateCourseFailure,
    deleteCourseRequest,
    deleteCourseSuccess,
    deleteCourseFailure,
    createChapterRequest,
    createChapterSuccess,
    createChapterFailure,
    updateChapterRequest,
    updateChapterSuccess,
    updateChapterFailure,
    deleteChapterRequest,
    deleteChapterSuccess,
    deleteChapterFailure,
    createLessonRequest,
    createLessonFailure,
    createLessonSuccess,
    updateLessonRequest,
    updateLessonSuccess,
    updateLessonFailure,
    deleteLessonRequest,
    deleteLessonSuccess,
    deleteLessonFailure,
    createFAQRequest,
    createFAQSuccess,
    createFAQFailure,
    updateFAQRequest,
    updateFAQSuccess,
    updateFAQFailure,
    deleteFAQRequest,
    deleteFAQSuccess,
    deleteFAQFailure,
    submitCourseRequest,
    submitCourseFailure,
    submitCourseSuccess,
    updateCourseStatusRequest,
    updateCourseStatusSuccess,
    updateCourseStatusFailure,
    uploadCourseBannerRequest,
    uploadCourseBannerSuccess,
    uploadCourseBannerFailure,
    updateCourseBannerRequest,
    updateCourseBannerFailure,
    updateCourseBannerSuccess,
    getCourseBannerRequest,
    getCourseBannerFailure,
    getCourseBannerSuccess,
    createQuestionRequest,
    createQuestionSuccess,
    createQuestionFailure,
    getQuizRequest,
    getQuizFailure,
    getQuizSuccess,
    updateQuestionRequest,
    updateQuestionSuccess,
    updateQuestionFailure,
    deleteAssignmentsRequest,
    deleteAssignmentsFailure,
    createTaskRequest,
    updateTaskRequest,
    deleteTaskRequest,
    createTaskFailure,
    createTaskSuccess,
    updateTaskFailure,
    updateTaskSuccess,
    deleteTaskFailure,
    deleteTaskSuccess,
    updateAssignmentsRequest,
    updateAssignmentsFailure,
    updateAssignmentsSuccess,
    courseOfflineEnrollRequest,
    deleteTransactionRequest,
    deleteTransactionSuccess,
    deleteCourseBannerRequest,
    deleteCourseBannerSuccess,
    deleteCourseBannerFailure,
    getTransactionsStatsRequest,
    getTransactionsStatsFailure,
    getTransactionsStatsSuccess
} from '../actions/courseActions';
// import {toggleLoading} from '../actions/userActions';
import {
    getCourse,
    getCourses,
    createCourse,
    updateCourse,
    deleteCourse,
    createChapter,
    updateChapter,
    deleteChapter,
    updateLesson,
    createLesson,
    deleteLesson,
    createFAQ,
    updateFAQ,
    deleteFAQ,
    createCourseBanner,
    updateCourseBanner,
    deleteCourseBanner,
    getCourseBanner,
    getTransactions,
    getAssignments,
    deleteAssignment,
    updateAssignment,
    createTask,
    updateTask,
    deleteTask,
    offlineCourseEnroll,
    deleteTransaction,
    getTransactionsStats,
    getShipments
} from '../services/courses.service';
import { getQuiz, putQuestion, updateQuestion } from 'services/quizService';
import {uploadToS3} from "../services/s3.service";
import {toast} from 'react-toastify';

function* getCourseHandler(params) {
    try {
        // yield put(toggleLoading(true));
        const {accessToken} = yield select((state) => state.user);

        const courseResponse = yield call(getCourse, params.payload, accessToken);
        if (courseResponse.errorCode) {
            yield put(getCourseFailure(createCustomError(courseResponse.message)));
        } else {
            yield put(getCourseSuccess(courseResponse));
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* getCoursesHandler() {
    try {
        // yield put(toggleLoading(true));
        const {accessToken} = yield select((state) => state.user);

        const coursesResponse = yield call(getCourses, accessToken);
        if (coursesResponse.errorCode) {
            yield put(getCoursesFailure(createCustomError(coursesResponse.message)));
        } else {
            yield put(getCoursesSuccess(coursesResponse));
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* createCoursesHandler(params) {
    try {
        // yield put(toggleLoading(true));
        const {accessToken} = yield select((state) => state.user);

        const coursesResponse = yield call(createCourse, params.payload, accessToken);
        if (coursesResponse.errorCode) {
            toast.error(coursesResponse.message);
            yield put(createCourseFailure(createCustomError(coursesResponse.message)));
        } else {
            yield put(createCourseSuccess(coursesResponse));
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* updateCourseHandler(params) {
    try {
        // yield put(toggleLoading(true));
        const {accessToken} = yield select((state) => state.user);

        const courseResponse = yield call(updateCourse, params.payload, accessToken);
        if (courseResponse.errorCode) {
            toast.error(courseResponse.message);
            yield put(updateCourseFailure(createCustomError(courseResponse.message)));
        } else {
            yield put(updateCourseSuccess(courseResponse));
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* deleteCourseHandler(params) {
    try {
        // yield put(toggleLoading(true));
        const {accessToken} = yield select((state) => state.user);

        const courseResponse = yield call(deleteCourse, params.payload, accessToken);
        if (courseResponse.errorCode) {
            toast.error(courseResponse.message);
            yield put(deleteCourseFailure(createCustomError(courseResponse.message)));
        } else {
            yield put(deleteCourseSuccess(params.payload));
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* submitCourseHandler(params) {
    try {
        // yield put(toggleLoading(true));
        const {accessToken, role} = yield select((state) => state.user);

        const courseResponse = yield call(updateCourse, params.payload, accessToken);
        if (courseResponse.errorCode) {
            toast.error(courseResponse.message);
            yield put(submitCourseFailure(createCustomError(courseResponse.message)));
        } else {
            yield put(submitCourseSuccess());
            let redirectTo = null;
            if (role === 'Admin') {
                redirectTo = '/courses/all-courses/';
            }
        
            if (role === 'Instructor') {
                redirectTo = '/instructor/instructor-my-courses/';
            }
        
            if (redirectTo) {
                params.payload.history.push(redirectTo);
            }
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* updateCourseStatusHandler(params) {
    try {
        // yield put(toggleLoading(true));
        const {accessToken} = yield select((state) => state.user);

        const courseResponse = yield call(updateCourse, params.payload, accessToken);
        if (courseResponse.errorCode) {
            toast.error(courseResponse.message);
            yield put(updateCourseStatusFailure(createCustomError(courseResponse.message)));
        } else {
            yield put(updateCourseStatusSuccess(params.payload));
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* createChaptersHandler(params) {
    try {
        // yield put(toggleLoading(true));
        const {accessToken} = yield select((state) => state.user);

        const chapterResponse = yield call(createChapter, params.payload, accessToken);
        if (chapterResponse.errorCode) {
            toast.error(chapterResponse.message);
            yield put(createChapterFailure(createCustomError(chapterResponse.message)));
        } else {
            yield put(createChapterSuccess(chapterResponse));
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* updateChaptersHandler(params) {
    try {
        // yield put(toggleLoading(true));
        const {accessToken} = yield select((state) => state.user);

        const chapterResponse = yield call(updateChapter, params.payload, accessToken);
        if (chapterResponse.errorCode) {
            toast.error(chapterResponse.message);
            yield put(updateChapterFailure(createCustomError(chapterResponse.message)));
        } else {
            yield put(updateChapterSuccess(chapterResponse));
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* deleteChaptersHandler(params) {
    try {
        // yield put(toggleLoading(true));
        const {accessToken} = yield select((state) => state.user);

        const chapterResponse = yield call(deleteChapter, params.payload, accessToken);
        if (chapterResponse.errorCode) {
            toast.error(chapterResponse.message);
            yield put(deleteChapterFailure(createCustomError(chapterResponse.message)));
        } else {
            yield put(deleteChapterSuccess({...chapterResponse, ...params.payload}));
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* createLessonsHandler(params) {
    try {
        // yield put(toggleLoading(true));
        const {accessToken} = yield select((state) => state.user);

        const lessonResponse = yield call(createLesson, params.payload, accessToken);
        if (lessonResponse.errorCode) {
            toast.error(lessonResponse.message);
            yield put(createLessonFailure(createCustomError(lessonResponse.message)));
        } else {
            yield put(createLessonSuccess({...lessonResponse, chapterIndex: params.payload.chapterIndex}));
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* updateLessonsHandler(params) {
    try {
        // yield put(toggleLoading(true));
        const {accessToken} = yield select((state) => state.user);

        const lessonResponse = yield call(updateLesson, params.payload, accessToken);
        if (lessonResponse.errorCode) {
            toast.error(lessonResponse.message);
            yield put(updateLessonFailure(createCustomError(lessonResponse.message)));
        } else {
            yield put(updateLessonSuccess({...lessonResponse, chapterIndex: params.payload.chapterIndex}));
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* deleteLessonsHandler(params) {
    try {
        // yield put(toggleLoading(true));
        const {accessToken} = yield select((state) => state.user);

        const lessonResponse = yield call(deleteLesson, params.payload, accessToken);
        if (lessonResponse.errorCode) {
            toast.error(lessonResponse.message);
            yield put(deleteLessonFailure(createCustomError(lessonResponse.message)));
        } else {
            yield put(deleteLessonSuccess({...lessonResponse, ...params.payload}));
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* createFAQsHandler(params) {
    try {
        // yield put(toggleLoading(true));
        const {accessToken} = yield select((state) => state.user);

        const faqResponse = yield call(createFAQ, params.payload, accessToken);
        if (faqResponse.errorCode) {
            toast.error(faqResponse.message);
            yield put(createFAQFailure(createCustomError(faqResponse.message)));
        } else {
            yield put(createFAQSuccess(faqResponse));
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* createQuestionHandler(params) {
    try {
        const {accessToken} = yield select((state) => state.user);

        const quizResponse = yield call(putQuestion, params.payload, accessToken);
        if (quizResponse.errorCode) {
            toast.error(quizResponse.message);
            yield put(createQuestionFailure(createCustomError(quizResponse.message)));
        } else {
            toast.success('Quiz saved successfully');
            yield put(createQuestionSuccess(quizResponse));
            params.payload.payload.history.push('/quiz/list-quiz/');
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* updateQuestionHandler(params) {
    try {
        const {accessToken} = yield select((state) => state.user);
        const statusResponse = yield call(updateQuestion, params.payload, accessToken);
        if (statusResponse.errorCode) {
            toast.error(statusResponse.message);
            yield put(updateQuestionFailure(createCustomError(statusResponse.message)));
        } else {
            yield put(updateQuestionSuccess({ question: statusResponse, chapterId: params.payload.chapterId }));
            toast.success('Question updated successfully');
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* getQuizHandaler(params)
{
    try {
        // yield put(toggleLoading(true));
        const {accessToken} = yield select((state) => state.user);
        const quizResponse = yield call(getQuiz, params.payload, accessToken);
        if (quizResponse.errorCode) {
            yield put(getQuizFailure(createCustomError(quizResponse.message)));
        } else {
            yield put(getQuizSuccess(quizResponse));
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* updateFAQsHandler(params) {
    try {
        // yield put(toggleLoading(true));
        const {accessToken} = yield select((state) => state.user);

        const faqResponse = yield call(updateFAQ, params.payload, accessToken);
        if (faqResponse.errorCode) {
            toast.error(faqResponse.message);
            yield put(updateFAQFailure(createCustomError(faqResponse.message)));
        } else {
            yield put(updateFAQSuccess(faqResponse));
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* deleteFAQsHandler(params) {
    try {
        // yield put(toggleLoading(true));
        const {accessToken} = yield select((state) => state.user);

        const faqResponse = yield call(deleteFAQ, params.payload, accessToken);
        if (faqResponse.errorCode) {
            toast.error(faqResponse.message);
            yield put(deleteFAQFailure(createCustomError(faqResponse.message)));
        } else {
            yield put(deleteFAQSuccess({...faqResponse, ...params.payload}));
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* createTaskHandler(params) {
    try {
        // yield put(toggleLoading(true));
        const {accessToken} = yield select((state) => state.user);

        const taskResponse = yield call(createTask, params.payload, accessToken);
        if (taskResponse.errorCode) {
            toast.error(taskResponse.message);
            yield put(createTaskFailure(createCustomError(taskResponse.message)));
        } else {
            yield put(createTaskSuccess(taskResponse));
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* updateTaskHandler(params) {
    try {
        // yield put(toggleLoading(true));
        const {accessToken} = yield select((state) => state.user);

        const taskResponse = yield call(updateTask, params.payload, accessToken);
        if (taskResponse.errorCode) {
            toast.error(taskResponse.message);
            yield put(updateTaskFailure(createCustomError(taskResponse.message)));
        } else {
            yield put(updateTaskSuccess(taskResponse));
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* deleteTaskHandler(params) {
    try {
        // yield put(toggleLoading(true));
        const {accessToken} = yield select((state) => state.user);

        const taskResponse = yield call(deleteTask, params.payload, accessToken);
        if (taskResponse.errorCode) {
            toast.error(taskResponse.message);
            yield put(deleteTaskFailure(createCustomError(taskResponse.message)));
        } else {
            yield put(deleteTaskSuccess({...taskResponse, ...params.payload}));
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* uploadCourseBannerHandler(params) {
    try {
        // yield put(toggleLoading(true));
        const {accessToken} = yield select((state) => state.user);
        const {banners} = yield select((state) => state.courses);

        const uploadResponse = yield call(uploadToS3, params.payload, accessToken);
        const matchingBanner = banners?.filter(x => x.title === params.payload.title && x.status === 'disabled');

        if(matchingBanner.length > 0) {
            let updatePayload = {
                id: matchingBanner[0].id,
                title: params.payload.title,
                description: params.payload.description,
                status: 'active',
                banner: uploadResponse
            }

            const updateBannerResponse = yield call(updateCourseBanner, updatePayload, accessToken);

            if (updateBannerResponse.errorCode) {
                yield put(updateCourseBannerFailure(createCustomError(updateBannerResponse.message)));
            } else {
                yield putResolve(updateCourseBannerSuccess(updateBannerResponse));

                yield call(getCourseBannerHandler);
            }
        }
        else {
            const createBannerResponse = yield call(createCourseBanner, params.payload, uploadResponse, accessToken);

            if (createBannerResponse?.errorCode) {
                yield put(uploadCourseBannerFailure(createCustomError(createBannerResponse.message)));
            } else {
                yield putResolve(uploadCourseBannerSuccess(createBannerResponse));

                yield call(getCourseBannerHandler);
            }
        }
    } catch (error) {
        throw new Error(error)
    }
}

    function* updateCourseBannerHandler(params) {
    try {
        // yield put(toggleLoading(true));
        const {accessToken} = yield select((state) => state.user);

        const updateBannerResponse = yield call(updateCourseBanner, params.payload, accessToken);
        if (updateBannerResponse.errorCode) {
            yield put(updateCourseBannerFailure(createCustomError(updateBannerResponse.message)));
        } else {
            yield putResolve(updateCourseBannerSuccess(updateBannerResponse));

            yield call(getCourseBannerHandler);
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* deleteCourseBannerHandler(params) {
    try {
        // yield put(toggleLoading(true));
        const { accessToken } = yield select((state) => state.user);
        const deleteBannerResponse = yield call(deleteCourseBanner, params.payload, accessToken);
        if (deleteBannerResponse.errorCode) {
            yield put(deleteCourseBannerFailure(createCustomError(deleteBannerResponse.message)));
        } else {
            yield put(deleteCourseBannerSuccess({ ...deleteBannerResponse, ...params.payload }));
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* getCourseBannerHandler(params) {
    try {
        // yield put(toggleLoading(true));
        const {accessToken} = yield select((state) => state.user);

        const getBannerResponse = yield call(getCourseBanner, accessToken);
        if (getBannerResponse.errorCode) {
            yield put(getCourseBannerFailure(createCustomError(getBannerResponse.message)));
        } else {
            yield put(getCourseBannerSuccess(getBannerResponse));
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* getTransactionsHandler(params) {
    try {
        const {accessToken} = yield select((state) => state.user);

        const getTransactionsResponse = yield call(getTransactions, params.payload, accessToken);
        if (getTransactionsResponse.errorCode) {
            toast.error('Error in fetching transactions');
            yield put(getTransactionsFailure(createCustomError(getTransactionsResponse.message)));
        } else {
            yield put(getTransactionsSuccess(getTransactionsResponse));
        }
    } catch (error) {
        toast.error('Error in fetching transactions');
        throw new Error(error)
    }
}


function* getTransactionsStatsHandler(params) {
    try {
        const {accessToken} = yield select((state) => state.user);

        const getTransactionsResponse = yield call(getTransactionsStats, accessToken);
        if (getTransactionsResponse.errorCode) {
            yield put(getTransactionsStatsFailure(createCustomError(getTransactionsResponse.message)));
        } else {
            yield put(getTransactionsStatsSuccess(getTransactionsResponse));
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* getShipmentsHandler(params) {
    try {
        const {accessToken} = yield select((state) => state.user);

        const getShipmentsResponse = yield call(getShipments, accessToken);
        if (getShipmentsResponse.errorCode) {
            yield put(getShipmentsFailure(createCustomError(getShipmentsResponse.message)));
        } else {
            yield put(getShipmentsSuccess(getShipmentsResponse));
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* getAssignmentsHandler(params) {
    try {
        const {accessToken} = yield select((state) => state.user);

        const getAssignmentsResponse = yield call(getAssignments, accessToken);
        if (getAssignmentsResponse.errorCode) {
            yield put(getAssignmentsFailure(createCustomError(getAssignmentsResponse.message)));
        } else {
            yield put(getAssignmentsSuccess(getAssignmentsResponse));
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* deleteAssignmentRequestHandler(id) {
    try {
        const {accessToken} = yield select((state) => state.user);

        const deleteAssignmentResponse = yield call(deleteAssignment, id.payload, accessToken);
        if (deleteAssignmentResponse.errorCode) {
            yield put(deleteAssignmentsFailure(createCustomError(deleteAssignmentResponse.message)))
        }
    } catch (error) {
        throw new Error(error)
    }
}

function* updateCourseAssignmentHandler(params) {
    try {
        const {accessToken} = yield select((state) => state.user);
        
        const updateAssignmentResponse = yield call(updateAssignment, params.payload.assignmentId, params.payload.assignmentScore, accessToken)

        if (updateAssignmentResponse.errorCode) {
            yield put(updateAssignmentsFailure(createCustomError(updateAssignmentResponse.message)));
        } else {
            yield put(updateAssignmentsSuccess(updateAssignmentResponse));
        }
    }
    catch (error) {
        throw new Error(error)
    }
}

function* courseOfflineEnrollHandler(params) {
    const { accessToken } = yield select((state) => state.user);

    try {
        const courseEnrollResponse = yield call(offlineCourseEnroll, params.payload.enroll, accessToken);

        if (courseEnrollResponse.errorCode) {
            toast.error(courseEnrollResponse.message);
        }
        else {
            toast.success(courseEnrollResponse.message);
            params.payload.history.push('/courses/transactions');
        }
    } catch (error) {
        
    }
}

function* deleteTransactionHandler(params) {
    try {
        const {accessToken} = yield select((state) => state.user);
        
        const deleteTransactionResponse = yield call(deleteTransaction, params.payload, accessToken)

        if (deleteTransactionResponse.errorCode) {
            toast.error(deleteTransactionResponse.message);
        } else {
            toast.success('Transaction deleted successfully');
            yield put(deleteTransactionSuccess(params.payload));
        }
    }
    catch (error) {
       toast.error(error.message);
    }
}

function* courseSaga() {
    yield takeEvery(getCourseRequest.type, getCourseHandler);
    yield takeEvery(getCoursesRequest.type, getCoursesHandler);
    yield takeEvery(createCourseRequest.type, createCoursesHandler);
    yield takeEvery(updateCourseRequest.type, updateCourseHandler);
    yield takeEvery(deleteCourseRequest.type, deleteCourseHandler);
    yield takeEvery(submitCourseRequest.type, submitCourseHandler);
    yield takeEvery(updateCourseStatusRequest.type, updateCourseStatusHandler);
    yield takeEvery(createChapterRequest.type, createChaptersHandler);
    yield takeEvery(updateChapterRequest.type, updateChaptersHandler);
    yield takeEvery(deleteChapterRequest.type, deleteChaptersHandler);
    yield takeEvery(createLessonRequest.type, createLessonsHandler);
    yield takeEvery(updateLessonRequest.type, updateLessonsHandler);
    yield takeEvery(deleteLessonRequest.type, deleteLessonsHandler);
    yield takeEvery(createFAQRequest.type, createFAQsHandler);
    yield takeEvery(createQuestionRequest.type, createQuestionHandler);
    yield takeEvery(createTaskRequest.type, createTaskHandler);
    yield takeEvery(updateTaskRequest.type, updateTaskHandler);
    yield takeEvery(deleteTaskRequest.type, deleteTaskHandler);
    yield takeEvery(getQuizRequest.type, getQuizHandaler);
    yield takeEvery(updateQuestionRequest.type, updateQuestionHandler);
    yield takeEvery(updateFAQRequest.type, updateFAQsHandler);
    yield takeEvery(deleteFAQRequest.type, deleteFAQsHandler);
    yield takeEvery(uploadCourseBannerRequest.type, uploadCourseBannerHandler);
    yield takeEvery(updateCourseBannerRequest.type, updateCourseBannerHandler);
    yield takeEvery(deleteCourseBannerRequest, deleteCourseBannerHandler);
    yield takeEvery(getCourseBannerRequest.type, getCourseBannerHandler);
    yield takeEvery(getTransactionsRequest.type, getTransactionsHandler);
    yield takeEvery(getTransactionsStatsRequest.type, getTransactionsStatsHandler);
    yield takeEvery(getShipmentsRequest.type, getShipmentsHandler);
    yield takeEvery(getAssignmentsRequest.type, getAssignmentsHandler);
    yield takeEvery(deleteAssignmentsRequest.type, deleteAssignmentRequestHandler);
    yield takeEvery(updateAssignmentsRequest.type, updateCourseAssignmentHandler);
    yield takeEvery(courseOfflineEnrollRequest.type, courseOfflineEnrollHandler)
    yield takeEvery(deleteTransactionRequest.type, deleteTransactionHandler)
}

export default [courseSaga];
