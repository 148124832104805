// import node module libraries
import React, {Fragment, useEffect, useState} from 'react';
import {Col, Row, Card, Tab, Breadcrumb, Button, Offcanvas} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {clearErrorState, getUsersRequest} from "../../../actions/adminUserActions";
import AdminListItems from "./AdminListItems";
import OffcanvasCreateProjectForm from "../projects/OffcanvasCreateProjectForm";
import OffcanvasCreateUserForm from "./OffcanvasCreateUserForm";
import ProfileLayout from "../../instructor/ProfileLayout";

const Admin = () => {
    const dispatch = useDispatch();
    const admins = useSelector(state => state.adminUser.users);

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        dispatch(clearErrorState());
        dispatch(getUsersRequest());
    }
    const handleShow = () => {
        dispatch(clearErrorState());
        setShow(true);
    }

    useEffect(() => {
        dispatch(getUsersRequest({ role: 'Admin' }));
    }, []);

    return (
        <ProfileLayout>
            <Tab.Container defaultActiveKey="list">
                <Tab.Content>
                    {/*<Tab.Pane eventKey="grid" className="pb-4">*/}
                    {/*	/!* students in list view *!/*/}
                    {/*	<StudentsGridCard studentsList={users}/>*/}
                    {/*	/!* end of students in list view *!/*/}
                    {/*</Tab.Pane>*/}
                    <Tab.Pane eventKey="list" className="pb-4">
                        <h4 className="mb-1 h4 fw-light">
                            Admin <span className="fs-5 text-muted">({admins.length})</span>
                        </h4>
                        <Card className="mb-5 ">
                            <Card.Body className="p-0">
                                {/* students in list view */}
                                {admins.length > 0 ? (<AdminListItems adminsList={admins}/>) : <></>}
                                {/* end of students in list view */}
                            </Card.Body>
                        </Card>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </ProfileLayout>
    );
};
export default Admin;
