import {createReducer} from '@reduxjs/toolkit';

import {
    getCoursesFailure,
    getCoursesRequest,
    getCoursesSuccess,
    getCourseSuccess,
    createCourseRequest,
    createCourseSuccess,
    createCourseFailure,
    updateCourseRequest,
    updateCourseSuccess,
    updateCourseFailure,
    deleteCourseSuccess,
    createChapterSuccess,
    updateChapterSuccess,
    deleteChapterSuccess,
    createLessonSuccess,
    updateLessonSuccess,
    deleteLessonSuccess,
    createFAQSuccess,
    updateFAQSuccess,
    deleteFAQSuccess,
    submitCourseSuccess,
    updateCourseStatusSuccess,
    clearDraftCourseStatus,
    uploadCourseBannerRequest,
    uploadCourseBannerSuccess,
    uploadCourseBannerFailure,
    clearUploadBannerError,
    resetDraftCourse, 
    getCourseBannerRequest, 
    getCourseBannerSuccess, 
    getCourseBannerFailure, 
    getQuizSuccess,
    updateQuestionSuccess,
    getTransactionsSuccess,
    getTransactionsFailure,
    getTransactionsRequest,
    getShipmentsSuccess,
    getShipmentsFailure,
    createQuestionSuccess,
    createQuestionFailure,
    getAssignmentsFailure,
    getAssignmentsRequest,
    getAssignmentsSuccess,
    deleteAssignmentsFailure,
    updateAssignmentsSuccess,
    updateAssignmentsFailure,
    updateAssignmentsRequest,
    updateCourseBannerSuccess,
    createTaskSuccess,
    updateTaskSuccess,
    deleteTaskSuccess,
    deleteTransactionRequest,
    deleteTransactionSuccess,
    deleteCourseBannerSuccess,
    getTransactionsStatsSuccess
} from '../actions/courseActions';

const initialState = {
    courses: [],
    banners: [],
    errorMessage: null,
    hasError: false,
    draftCourse: {
        chapters: [],
        faqs: [],
        authors: [],
        tasks: []
    },
    isDraftCourseSaving: false,
    isDraftCourseSaved: false,
    isBannerCreated: false,
    isCreateBannerError: false,
    getCourseBannerError: false,
    isBannerUpdated: null,
    isBannerDeleted: null,
    quiz: [],
    transactions: [],
    totalAmount: 0,
    todayAmount: 0,
    thisWeekAmount: 0,
    lastWeekAmount: 0,
    transactionsCount: 0,
    shipments: [],
    assignments: [],
    isCreateQuestionSuccess: false,
    isAssignmentScoreUpdateError: false
};

const coursesReducer = createReducer(initialState, builder => {
    builder
        .addCase(getCoursesRequest, state => ({
            ...state
        }))

        .addCase(
            getCoursesFailure,
            (state, action) => ({
                ...state,
                errorMessage: action.payload.message,
                hasError: true
            })
        )

        .addCase(getCoursesSuccess,
            (state, action) => ({
            ...state,
            courses: action.payload,
            errorMessage: null,
            hasError: false
        }))

        .addCase(getTransactionsSuccess,
            (state, action) => ({
                ...state,
                transactions: action.payload.transactions,
                transactionsCount: action.payload.transactionsCount,
                errorMessage: null,
                hasError: false,
        }))

        .addCase(getTransactionsStatsSuccess,
            (state, action) => ({
                ...state,
                totalAmount: action.payload.totalAmount,
                todayAmount: action.payload.todayAmount,
                thisWeekAmount: action.payload.thisWeekAmount,
                lastWeekAmount: action.payload.lastWeekAmount
        }))

        .addCase(
            getTransactionsFailure,
            (state, action) => ({
                ...state,
                errorMessage: action.payload.message,
                hasError: true
            })
        )

        .addCase(
            getTransactionsRequest, state => ({
                ...state
            })
        )

        .addCase(getShipmentsSuccess,
            (state, action) => ({
                ...state,
                shipments: action.payload,
                errorMessage: null,
                hasError: false,
        }))

        .addCase(
            getShipmentsFailure,
            (state, action) => ({
                ...state,
                errorMessage: action.payload.message,
                hasError: true
            })
        )

        .addCase(getAssignmentsSuccess,
            (state, action) => ({
                ...state,
                assignments: action.payload,
                errorMessage: null,
                hasError: false,
        }))

        .addCase(
            getAssignmentsFailure,
            (state, action) => ({
                ...state,
                errorMessage: action.payload.message,
                hasError: true
            })
        )

        .addCase(
            deleteAssignmentsFailure,
            (state, action) => ({
                ...state,
                errorMessage: action.payload.message,
                hasError: true
            })
        )

        .addCase(
            getAssignmentsRequest, state => ({
                ...state
            })
        )

        .addCase(
            updateAssignmentsSuccess, (state, action) => {
                const assignmentIndex = state.assignments.findIndex(c => c.id === action.payload.id);
                state.assignments[assignmentIndex] = action.payload
            },({
                isAssignmentScoreUpdateError: false,
                errorMessage: null
            })
            )

        .addCase(
            updateAssignmentsFailure, (state, action) => ({
                ...state,
                isAssignmentScoreUpdateError: true,
                errorMessage: action.payload.message
            })
        )

        .addCase(
            updateAssignmentsRequest, state => ({
                ...state,
                isAssignmentScoreUpdateError: false,
            })
        )

        .addCase(getCourseSuccess, (state, action) => {
            state.draftCourse = action.payload;
        })

        .addCase(createCourseRequest, (state) => {
            state.isDraftCourseSaving = true;
        })

        .addCase(createCourseSuccess,
            (state, action) => ({
                ...state,
                draftCourse: { ...action.payload, chapters: [], faqs: [], authors: [], tasks: [] },
                isDraftCourseSaving: false,
                isDraftCourseSaved: true
            }))

        .addCase(createCourseFailure,
            (state, action) => ({
                ...state,
                isDraftCourseSaving: false,
                isDraftCourseSaved: false
            }))

        .addCase(updateCourseRequest, (state) => {
            state.isDraftCourseSaving = true;
        })

        .addCase(updateCourseSuccess,
            (state, action) => ({
                ...state,
                draftCourse: { ...state.draftCourse, ...action.payload },
                isDraftCourseSaving: false,
                isDraftCourseSaved: true
            }))

        .addCase(updateCourseFailure,
            (state, action) => ({
                ...state,
                isDraftCourseSaving: false,
                isDraftCourseSaved: false
            }))

        .addCase(deleteCourseSuccess,
            (state, action) => {
                state.courses = state.courses.filter(c => c.id !== action.payload.id);
            })

        .addCase(updateCourseStatusSuccess,
            (state, action) => {
                const courseIndex = state.courses.findIndex(c => c.id === action.payload.courseId);
                state.courses[courseIndex].status = action.payload.status;
            })

        .addCase(clearDraftCourseStatus, (state) => {
            state.isDraftCourseSaved = false;
            state.isDraftCourseSaving = false;
        })

        .addCase(createChapterSuccess,
            (state, action) => {
                const draftCourse = state.draftCourse;

                if (!draftCourse.chapters.length)
                    draftCourse.chapters = [];
                draftCourse.chapters.push({ ...action.payload, lessons: [] });
            })

        .addCase(updateChapterSuccess,
            (state, action) => {
                const draftCourse = state.draftCourse;
                const chapterIndex = draftCourse.chapters.findIndex(c => c.id === action.payload.id);

                draftCourse.chapters[chapterIndex] = {
                    ...draftCourse.chapters[chapterIndex],
                    ...action.payload
                };
            })

        .addCase(deleteChapterSuccess,
            (state, action) => {
                const draftCourse = state.draftCourse;
                draftCourse.chapters = draftCourse.chapters.filter(c => c.id !== action.payload.id);
            })

        .addCase(createLessonSuccess,
            (state, action) => {
                const draftCourse = state.draftCourse;

                if (!draftCourse.chapters[action.payload.chapterIndex].lessons.length)
                    draftCourse.chapters[action.payload.chapterIndex].lessons = [];

                draftCourse.chapters[action.payload.chapterIndex].lessons.push(action.payload);
            })

        .addCase(updateLessonSuccess, (state, action) => {
                const chapter = state.draftCourse.chapters[action.payload.chapterIndex];
                const lessonIndex = chapter.lessons.findIndex(l => l.id === action.payload.id);
                chapter.lessons[lessonIndex] = action.payload;
            })

        .addCase(deleteLessonSuccess, (state, action) => {
            const chapters = state.draftCourse.chapters[action.payload.chapterIndex];
            chapters.lessons = chapters.lessons.filter(l => l.id !== action.payload.lessonId);
        })

        .addCase(createFAQSuccess,
            (state, action) => {
                const draftCourse = state.draftCourse;

                if (!draftCourse.faqs.length)
                    draftCourse.faqs = [];
                draftCourse.faqs.push(action.payload);
            })

        .addCase(updateFAQSuccess,
            (state, action) => {
                const draftCourse = state.draftCourse;
                const faqIndex = draftCourse.faqs.findIndex(f => f.id === action.payload.id);

                draftCourse.faqs[faqIndex] = {
                    ...draftCourse.faqs[faqIndex],
                    ...action.payload
                };
            })

        .addCase(deleteFAQSuccess,
            (state, action) => {
                const draftCourse = state.draftCourse;
                draftCourse.faqs = draftCourse.faqs.filter(f => f.id !== action.payload.id);
            })

        .addCase(createTaskSuccess,
            (state, action) => {
                const draftCourse = state.draftCourse;

                if (!draftCourse.tasks.length)
                    draftCourse.tasks = [];
                draftCourse.tasks.push(action.payload);
            })

        .addCase(updateTaskSuccess,
            (state, action) => {
                const draftCourse = state.draftCourse;
                const taskIndex = draftCourse.tasks.findIndex(t => t.id === action.payload.id);

                draftCourse.tasks[taskIndex] = {
                    ...draftCourse.tasks[taskIndex],
                    ...action.payload
                };
            })

        .addCase(deleteTaskSuccess,
            (state, action) => {
                const draftCourse = state.draftCourse;
                draftCourse.tasks = draftCourse.tasks.filter(t => t.id !== action.payload.id);
            })

        .addCase(submitCourseSuccess, (state) => { state.draftCourse = initialState.draftCourse })

        .addCase(uploadCourseBannerRequest, state => ({
            ...state,
            isCreateBannerError: false,
            isBannerCreated: false
        }))

        .addCase(uploadCourseBannerSuccess, (state, action) => ({
            ...state,
            isCreateBannerError: false,
            isBannerCreated: true
        }))

        .addCase(
            uploadCourseBannerFailure,
            (state, action) => ({
                ...state,
                errorMessage: action.payload.message,
                isCreateBannerError: true
            })
        )

        .addCase(getCourseBannerRequest, state => ({
            ...state,
            getCourseBannerError: false,
            isBannerUpdated: null,
            isBannerDeleted: null
        }))

        .addCase(updateCourseBannerSuccess, state => ({
            ...state,
            isBannerUpdated: true,
            isBannerDeleted: false,
            errorMessage: false,
        }))

        .addCase(getCourseBannerSuccess, (state, action) => ({
            ...state,
            banners: action.payload,
            getCourseBannerError: false,
            errorMessage: false,
        }))

        .addCase(
            getCourseBannerFailure,
            (state, action) => ({
                ...state,
                errorMessage: action.payload.message,
                getCourseBannerError: true
            })
        )

        .addCase(deleteCourseBannerSuccess,
            (state, action) => ({
                ...state,
                isBannerDeleted: true,
                isBannerUpdated: false,
                banners: state.banners.filter(b => b.id !== action.payload.id)
            })
        )

        .addCase(
            clearUploadBannerError,
            (state, action) => ({
                ...state,
                isCreateBannerError: false,
                isBannerCreated: false,
                isBannerUpdated: false,
                isBannerDeleted: false,
                getCourseBannerError: false
            })
        )

        .addCase(
            getQuizSuccess,
            (state, action) =>({
                ...state,
                quiz: action.payload
            })
        )

        .addCase(
            createQuestionSuccess,
            (state, action) =>({
                ...state,
                isCreateQuestionSuccess: true
            })
        )
        
        .addCase(
            createQuestionFailure,
            (state, action) =>({
                ...state,
                isCreateQuestionSuccess: false
            })
        )

        .addCase(
            updateQuestionSuccess,
            (state, action) => {
                state.quiz = state.quiz.map(q => {
                    if (action.payload.chapterId && q.id === action.payload.chapterId) {
                        return {
                            ...q,
                            questions: q.questions.map(question => {
                                if (question.id === action.payload.question.id) 
                                    return action.payload.question;
                                return question;
                            })
                        }
                    } else if (q.id === action.payload.question.id) {
                        return action.payload.question;
                    }

                    return q;
                });
            }
        )

        .addCase(resetDraftCourse, (state) => { state.draftCourse = initialState.draftCourse })

        .addCase(deleteTransactionRequest,
            (state, action) => {
                state.isTransactionDeleteSuccess = false;
            })

        .addCase(deleteTransactionSuccess,
            (state, action) => {
                state.transactions = state.transactions.filter(t => t.paymentOrder.orderId !== action.payload.id);
                state.isTransactionDeleteSuccess = true;
            })
});

export default coursesReducer;
