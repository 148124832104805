import React, { useState, Fragment} from 'react';
import { Link } from 'react-router-dom';
import { createQuestionRequest, getCoursesRequest, getQuizRequest } from 'actions/courseActions';
import { useDispatch, useSelector} from 'react-redux';
import { Form, Card, Button, Container, Row, Col} from 'react-bootstrap';
import AddQuestion from './AddQuestion';
import { useEffect } from 'react';
import {toast} from 'react-toastify';
import { useHistory } from 'react-router-dom';

const AddNewQuiz = () => {
	const dispatch = useDispatch();
	const [questionText, setQuestionText1] = useState([]);
	const [choices, setChoices] = useState([]);
	const [courses, setCourses] = useState([{ id: '', name: 'Select course' }]);
	const [units, setUnits] = useState([{ id: '', name: 'Select unit' }]);
	const [experiments, setExperiment] = useState([{ id: '', name: 'Select experiment' }]);
	const [selectedCourse, setSelectedCourse] = useState('');
	const [selectedUnit, setSelectedUnit] = useState('');
	const [selectedExperiment, setSelectedExperiment] = useState('');
	const [questionData, setQuestionData] = useState([]);
	const initialQuestionData = {"level":'Easy',"type":'mcq', "score": 1};
	const quiz = useSelector(state => state.courses.quiz);
	const { role } = useSelector(state => state.user);
	const isCreateQuestionSuccess = useSelector(state => state.courses.isCreateQuestionSuccess);
    const coursesData = useSelector(state => state.courses.courses);
    const history = useHistory();

	useEffect(() => {
        dispatch(getCoursesRequest());
    }, []);

	useEffect(() => {
		isCreateQuestionSuccess && resetPage();
	}, [isCreateQuestionSuccess]);

    useEffect(() => {
        if (quiz && selectedCourse) {
			const units = [];
			const experiments = [];
			quiz.forEach(chapter => {
				if (chapter.isQuizEnabled)
					units.push({id: chapter.id, name: chapter.name})
				
				chapter.lessons.forEach(lesson => {
					if (lesson.isQuizEnabled)
						experiments.push({ id: JSON.stringify({ chapterId: chapter.id, lessonId: lesson.id }), name: lesson.name })
				})
			})
			setUnits([{ id: '', name: 'Select unit' }, ...units]);
			setExperiment([{ id: '', name: 'Select experiment' }, ...experiments])
        }
    }, [quiz]);

    useEffect(() => {
        if (coursesData) {
            const temp = coursesData.map(c => { return {"id": c.id, "name": c.name}});
			setCourses([{ id: null, name: 'Select course' }, ...temp]);
        }
    }, [coursesData])

	const resetPage = () =>
	{
		setQuestionText1([]);
		setChoices([]);
		setQuestionData([]);
		dispatch(getCoursesRequest());
	}
	
	const onChangeChoice = (data, questionKey, choiceKey) =>
	{
		let tempChoices = choices;
		tempChoices[questionKey][choiceKey] = data;
		setChoices([...tempChoices]);
	}

	const onRemoveChoice = (questionKey, ChoiceKey) =>
	{
		let tempChoiceData = choices;
		tempChoiceData[questionKey].splice(ChoiceKey, 1);
		setChoices([...tempChoiceData]);
	}

	const onAddChoice = (questionKey) =>
	{
		let tempChoices = choices;
		tempChoices[questionKey] = [...tempChoices[questionKey], {value:'', isCorrect: false}];
		setChoices([...tempChoices]);
	}
	
	const onSaveComponentData = () =>
	{
		let error = '';
		if(!selectedUnit && !selectedExperiment)
			error = "Select valid course and valid unit or experiment.";
		else if(questionText.length < 1)
			error = "Add atleast one question.";
		else if(choices.filter(c => c.length < 1).length > 0)
			error = "Add choices for each question.";
		else if(choices.filter(chs => !chs.reduce((or, val) => or || val.isCorrect, false)).length > 0)
			error = "Choices should have atleast one correct choice.";
		if(error){
			toast.error(error);
			return;
		}
		const questions = questionText.map((_, key) => {
			return {
					text: questionText[key],
					level: questionData[key].level,
					type: questionData[key].type,
					score: questionData[key].score,
					choices: choices[key].map((c, id) => ({
						"text": c.text,
						"isCorrect": !(!c.isCorrect)
					}))
		}});
		const payload = {"questions": questions, "chapterId": selectedUnit, "history": history}

		if (selectedExperiment) {
			const experiment = JSON.parse(selectedExperiment);
			payload.chapterId = experiment.chapterId;
			payload.experimentId = experiment.lessonId
		}
		dispatch(createQuestionRequest({ payload }));
	}

	const dropDown = (options, selectedOption, handleSelect) => {
        return <Form.Select value={selectedOption} style={{ minWidth: "100px", maxWidth: "150px", marginLeft: "10px" }} onChange={e => handleSelect(e.target.value)}>
            {options && options.map(({ id, name }, index) => <option key={index} value={id}>{name}</option>)}
        </Form.Select>
    }

	const setData = (questionKey, value) =>
	{
		let tempData = questionData;
		tempData[questionKey] = value;
		setQuestionData([...tempData]);
	}

	const onAddQuestion = () =>
	{
		setQuestionText1([...questionText, '']);
		setChoices([...choices, []]);
		setQuestionData([...questionData, {...initialQuestionData}]);
	}

	const onRemoveQuestion = (questionKey) =>
	{
		const tempQuestionText = [...questionText];
		const tempChoices = [...choices];
		const tempQuestionData = [...questionData];
		tempQuestionText.splice(questionKey, 1);
		tempChoices.splice(questionKey, 1);
		tempQuestionData.splice(questionKey, 1);
		setQuestionData(tempQuestionData);
		setChoices(tempChoices);
		setQuestionText1(tempQuestionText);
	}

	const setQuestionText = (questionKey, value) =>
	{
		let tempQuestionText = questionText;
		tempQuestionText[questionKey] = value;
		setQuestionText1([...tempQuestionText]);
	}

	return (
		<Fragment>
			<div className="py-4 py-lg-6 bg-primary">
				<Container>
					<Row>
						<Col lg={{ span: 10, offset: 1 }} md={12} sm={12}>
							<div className="d-lg-flex align-items-center justify-content-between">
								<div className="mb-4 mb-lg-0">
									<h1 className="text-white mb-1">Question management</h1>
									<p className="mb-0 text-white lead">
										Select course, unit and add quiz questions.
									</p>
								</div>
								<div>
									<Link
										to={role === 'Admin' ? '/quiz/list-quiz' : '/instructor/instructor-my-courses/'}
										className="btn btn-white "
									>
										Back to Questions
									</Link>{' '}
									{/* <Link
										to="/marketing/instructor/instructor-my-courses/"
										className="btn btn-success "
									>
										Save
									</Link> */}
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<div className="pb-12">
				<Container>
					<Card className="border-1">
						<Card.Header>
							<div style={{display:"flex"}}>
								{dropDown(courses, selectedCourse, (value) => setSelectedCourse(() => {
										if(value) dispatch(getQuizRequest({ courseId: value }));
										else setUnits([{ id: '', name: 'Select unit' }]);
										return value;
									}))}
								{dropDown(units, selectedUnit, (value) => {
									setSelectedUnit(value);
									setSelectedExperiment('');
								})}
								{dropDown(experiments, selectedExperiment, (value) => {
									setSelectedUnit('');
									setSelectedExperiment(value);
								})}
							</div>
						</Card.Header>
					</Card>
					{
						questionText.map( (_, key) => (
							<Card className='mt-3' key={`question-card-${key}`}>
								<Card.Body className='shadow p-3 bg-white rounded'>
									{AddQuestion({questionKey: key, choices: choices[key], questionText: questionText[key], 
										dropDown, questionData: questionData[key], onAddChoice,
										onChangeChoice, onRemoveChoice, setQuestionText, setData, onRemoveQuestion})}
								</Card.Body>
							</Card>
						))
					}
					<Card className='mt-3'>
						<Card.Body>
							<div>
								<Button 
									style={{minWidth:"150px", height: "30px", marginTop: "10px", padding:"0px"}}
									className="btn btn-primary mb-2"
									type="button"
									onClick={() => onAddQuestion()} 
								>
									Add Question
								</Button>
								<Button
									style={{marginLeft:"10px", minWidth:"150px", marginTop: "10px", height: "30px", padding:"0px"}}
									type="button"
									className="btn btn-primary mb-2"
									onClick={() => onSaveComponentData()}
								>
									Save
								</Button>
							</div>
						</Card.Body>
					</Card>
				</Container>
			</div>
		</Fragment>
	);
};



export default AddNewQuiz;
