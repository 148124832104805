// import node module libraries
import React, {Fragment, useEffect, useState} from 'react';
import {Col, Row, Card, Tab, Breadcrumb, Button, Offcanvas} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {clearErrorState, getUsersRequest} from "../../../actions/adminUserActions";
import AuthorsListItems from "./AuthorsListItems";
import ProfileLayout from "../../instructor/ProfileLayout";

const Author = () => {
    const dispatch = useDispatch();
    const authors = useSelector(state => state.adminUser.users);

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
        dispatch(clearErrorState());
        dispatch(getUsersRequest());
    }
    const handleShow = () => {
        dispatch(clearErrorState());
        setShow(true);
    }

    useEffect(() => {
        dispatch(getUsersRequest({ role: 'Author' }));
    }, []);

    return (
        <ProfileLayout>
            <Tab.Container defaultActiveKey="list">
                <Tab.Content>
                    {/*<Tab.Pane eventKey="grid" className="pb-4">*/}
                    {/*	/!* authors in list view *!/*/}
                    {/*	<AuthorssGridCard authorsList={users}/>*/}
                    {/*	/!* end of authors in list view *!/*/}
                    {/*</Tab.Pane>*/}
                    <Tab.Pane eventKey="list" className="pb-4">
                        <h4 className="mb-1 h4 fw-light">
                            Author <span className="fs-5 text-muted">({authors.length})</span>
                        </h4>
                        <Card className="mb-5 ">
                            <Card.Body className="p-0">
                                {/* authors in list view */}
                                {authors.length > 0 ? (<AuthorsListItems authorsList={authors}/>) : <></>}
                                {/* end of authors in list view */}
                            </Card.Body>
                        </Card>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </ProfileLayout>
    );
};
export default Author;
