// import node module libraries
import { useState } from 'react';
import { Card, Form, Button, Row, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

// import custom components
import { FormSelect } from 'components/elements/form-select/FormSelect';

import 'assets/scss/custom/modal.scss';
import { Editor } from 'react-draft-wysiwyg';
import '../../../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

const BasicInformation = (props) => {
	const { next, data, handleSubmit } = props;
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const [description, setDescription] = useState('');

	const onEditorStateChange = editorState => {
		var html = draftToHtml(convertToRaw(editorState.getCurrentContent()))
		setDescription(html);
		return setEditorState(editorState)
	}

	useEffect(() => {
		var stateFromHTML = require('draft-js-import-html').stateFromHTML;

        try {
            if(data.description == undefined)
                setEditorState(EditorState.createEmpty());
            else 
                setEditorState(EditorState.createWithContent(stateFromHTML(data.description)));
                
            setDescription(data.description ?? '');
        }
		catch {
			setEditorState(EditorState.createEmpty());
		}
	}, [data]);

	const categoryOptions = [
		{ value: 'React', label: 'React' },
		{ value: 'Javascript', label: 'Javascript' },
		{ value: 'HTML', label: 'HTML' },
		{ value: 'Vuejs', label: 'Vue js' },
		{ value: 'Gulpjs', label: 'Gulp js' }
	];

	const CoursesLevel = [
		{ value: 'Beginner', label: 'Beginner' },
		{ value: 'Intermediate', label: 'Intermediate' },
		{ value: 'Advance', label: 'Advance' }
	];

	const initialValue = `<p>Insert course description</p>
                      <p><br /></p>        
                      <p>Some initial <strong>bold</strong> text</p>
                      <p><br /></p><p><br /></p><p><br /></p><p><br /></p>`;

	const validateDescription = () => {
		var getBlocks = convertToRaw(editorState.getCurrentContent()).blocks;
	
		if(getBlocks.length > 0 && getBlocks[0].text !== "") {
			return true
		}
		return false;
	}

	const schema = yup.object().shape({
		name: yup.string().required(),
		level: yup.string(),
		description: yup.string(),
		price: yup.number().moreThan(0).required(),
		isShipping: yup.boolean().required(),
		length: yup.number().when('isShipping', {
			is: isShipping => isShipping,
			then: yup.number().moreThan(0.5).required()
		}),
		breadth: yup.number().when('isShipping', {
			is: isShipping => isShipping,
			then: yup.number().moreThan(0.5).required()
		}),
		height: yup.number().when('isShipping', {
			is: isShipping => isShipping,
			then: yup.number().moreThan(0.5).required()
		}),
		weight: yup.number().when('isShipping', {
			is: isShipping => isShipping,
			then: yup.number().moreThan(0).lessThan(1000).required()
		}),
	});

	return (
		<Formik
			enableReinitialize 
			validationSchema={schema}
			onSubmit={e => { handleSubmit({...e, description}); next(); }}
			initialValues={{
				name: data.name || '',
				level: data.level || '',
				description: data.description || '',
				price: data.price || '',
				isShipping: data.isShipping || false,
				length: data.shipmentData ? JSON.parse(data.shipmentData).length : '',
				breadth: data.shipmentData ? JSON.parse(data.shipmentData).breadth : '',
				height: data.shipmentData ? JSON.parse(data.shipmentData).height : '',
				weight: data.shipmentData ? JSON.parse(data.shipmentData).weight : ''
			}}
		>
			{({
				handleSubmit,
				handleChange,
				handleBlur,
				values,
				touched,
				isValid,
				errors
			}) => (
				<Form noValidate onSubmit={(e) => { 
													e.preventDefault(); 
													if(validateDescription()) {
														handleSubmit(e); 
													}
													else {
														toast.error('Description is required');
													}
												}}>
					{/* Card */}
					<Card className="mb-3 ">
						<Card.Header className="border-bottom px-4 py-3">
							<h4 className="mb-0">Basic Information</h4>
						</Card.Header>
						{/* Card body */}
						<Card.Body>
							{/* Title  */}
							<Form.Group className="mb-3">
								<Form.Label htmlFor="courseTitle">Course Title *</Form.Label>
								<Form.Control
									type="text"
									placeholder="Course Title"
									id="name"
									name="name"
									value={values.name}
									onChange={handleChange}
									onBlur={handleBlur}
									isInvalid={touched.name && !!errors.name}
								/>
								<Form.Text className="text-muted">
									Write a 60 character course title.
								</Form.Text>
								<Form.Control.Feedback type="invalid">
									Title is required
								</Form.Control.Feedback>
							</Form.Group>

							{/* Category */}
							{/* <Form.Group className="mb-3">
								<Form.Label>Category category</Form.Label>
								<FormSelect
									options={categoryOptions}
									id="category_category"
									name="category_category"
									placeholder="Select Category"
								/>
								<Form.Text className="text-muted">
									Help people find your courses by choosing categories that
									represent your course.
								</Form.Text>
							</Form.Group> */}

							{/* Courses level */}
							<Form.Group className="mb-3">
								<Form.Label>Courses level</Form.Label>
								<FormSelect
									options={CoursesLevel}
									id="level"
									name="level"
									placeholder="Select level"
									onChange={handleChange}
									onBlur={handleBlur}
									defaultselected={values.level}
								/>
							</Form.Group>

							{/* Course Description*/}
							<Form.Group className="mb-3">
								<Form.Label>Course Description *</Form.Label>
								<div className='editor'>
								<Editor
        							editorState={editorState}
									editorClassName='editor-custom'
									toolbar={{
										options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'emoji', 'link'],
									}}
        							onEditorStateChange={onEditorStateChange}
        						/>
								</div>
								<Form.Text className="text-muted">
									A brief summary of your courses.
								</Form.Text>
								{touched.description && !!errors.description && (<div className='invalid-feedback'>
									Description is required
								</div>)}
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Label htmlFor="coursePrice">Course Price *</Form.Label>
								<Form.Control
									value={values.price}
									type="number"
									placeholder="Course Price"
									id="price"
									name="price"
									onChange={handleChange}
									onBlur={handleBlur}
									isInvalid={touched.price && !!errors.price}
								/>
								<Form.Control.Feedback type="invalid">
									Price is required
								</Form.Control.Feedback>
							</Form.Group>

							<Form.Group className="mb-3">
								<Form.Check 
									type="checkbox"
									label="Is Shipping" 
									name="isShipping" 
									onChange={handleChange} 
									onBlur={handleBlur}
									checked={values.isShipping}
								/>
								{values.isShipping &&
									<>
										<br />
										<Row>
											<Col>
											<Form.Group className="mb-3">
												<Form.Label htmlFor="coursePrice">Length(cm) *</Form.Label>
												<Form.Control
													value={values.length}
													type="number"
													placeholder="Shipment length"
													id="length"
													name="length"
													onChange={handleChange}
													onBlur={handleBlur}
													isInvalid={touched.length && !!errors.length}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.length}
												</Form.Control.Feedback>
											</Form.Group>
											</Col>
											<Col>
											<Form.Group className="mb-3">
												<Form.Label htmlFor="coursePrice">Breadth(cm) *</Form.Label>
												<Form.Control
													value={values.breadth}
													type="number"
													placeholder="Shipment breadth"
													id="breadth"
													name="breadth"
													onChange={handleChange}
													onBlur={handleBlur}
													isInvalid={touched.breadth && !!errors.breadth}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.breadth}
												</Form.Control.Feedback>
											</Form.Group>
											</Col>
										</Row>
										<Row>
										<Col>
											<Form.Group className="mb-3">
												<Form.Label htmlFor="coursePrice">Height(cm) *</Form.Label>
												<Form.Control
													value={values.height}
													type="number"
													placeholder="Shipment height"
													id="height"
													name="height"
													onChange={handleChange}
													onBlur={handleBlur}
													isInvalid={touched.height && !!errors.height}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.height}
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
										<Col>
											<Form.Group className="mb-3">
												<Form.Label htmlFor="coursePrice">Weight(kg) *</Form.Label>
												<Form.Control
													value={values.weight}
													type="number"
													placeholder="Shipment weight"
													id="weight"
													name="weight"
													onChange={handleChange}
													onBlur={handleBlur}
													isInvalid={touched.weight && !!errors.weight}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.weight}
												</Form.Control.Feedback>
											</Form.Group>
										</Col>
										</Row>
									</>
								}
							</Form.Group>
						</Card.Body>
					</Card>
					{/* Button */}
					<Button variant="primary" type="submit">
						Next
					</Button>
				</Form>	
			)}
		</Formik>
	);
};
export default BasicInformation;
