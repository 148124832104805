import 'assets/scss/custom/student.scss';
import moment from 'moment';
import { useEffect, useState } from "react";
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { getUsersRequest } from "../../../../actions/adminUserActions";
import { URL } from '../../../../shared/url';

const CreateEventForm = ({ eventInfo }) => {

    const dispatch = useDispatch();
    const history = useHistory()

    const createdBy = useSelector(state => state.user)
    const createdByUserId = createdBy.phoneNumber
    const accessToken = createdBy.accessToken



    useEffect(() => {
        dispatch(getUsersRequest());
        setEndTime('');
        setEventName('');
        setEventDescription('');
        setStartTime('')
    }, []);


    const [eventName, setEventName] = useState('');
    const [errors, setErrors] = useState('');
    const [isEventCreated, setIsEventCreated] = useState(false);
    const handleEventNameChange = (eventName) => {
        setEventName(eventName);
    }

    const [eventDescription, setEventDescription] = useState('');
    const handleEventDescriptionChange = (eventDescription) => {
        setEventDescription(eventDescription);
    }

    const [endTime, setEndTime] = useState('');
    const handleEndTimeChange = (endTime) => {
        setEndTime(endTime);
    }

    const formatDate = (date) => {
        return moment(date).utc().format('YYYY-MM-DDThh:mm')
    }


    const [startTime, setStartTime] = useState('');
    const handleStartTimeChange = (startTime) => {
        setStartTime(startTime);
    }

    useEffect(() => {
        dispatch(getUsersRequest());
        setEndTime(formatDate(eventInfo.endTime));
        setEventName(eventInfo.eventName);
        setEventDescription(eventInfo.eventDescription);
        setStartTime(formatDate(eventInfo.startTime))
    }, [eventInfo]);

    const onSubmit = () => {
        let eventPayload = {
            name: eventName,
            description: eventDescription,
            startsAt: startTime,
            endsAt: endTime,
            createdByUserId
        }

        if (Date.parse(startTime) < Date.now()) {
            setErrors('Start time cannot be a past time');
            return
        }

        if (Date.parse(endTime) < Date.now()) {
            setErrors('End time cannot be a past time');
            return
        }

        if (Date.parse(endTime) < Date.parse(startTime)) {
            setErrors('End time cannot be lesser than start time');
            return
        }
        var method = 'POST'

        if (eventInfo.id) {
            eventPayload.id = eventInfo.id;
            method = 'PUT';
        }

        fetch(URL.event, {
            method: method,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({
                event: eventPayload
            })
        }).then((res) => {
            if (res.status == 200) {
                setErrors('')
                setIsEventCreated(true)
                history.push('/event/list');
            }
            else {
                setIsEventCreated(false)
                setErrors('Creation failed try again')
            }
            onClear()
        })
    }

    const onClear = () => {
        setEndTime('');
        setEventName('');
        setEventDescription('');
        setStartTime('')
        //setParticipant('');
    }

    return (

        <Form onSubmit={(e) => { e.preventDefault(); onSubmit(); }}>
            <Row>
                <Col xs={12} className="mb-3">
                    <Form.Group controlId="formEventTitle">
                        <Form.Label>
                            Event Name <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter name of event"
                            required
                            value={eventName}
                            onChange={e => handleEventNameChange(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <br></br>

                <Col xs={12} className="mb-3">
                    <Form.Group controlId="formEventTitle">
                        <Form.Label>
                            Event Description <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter event description"
                            required
                            value={eventDescription}
                            onChange={e => handleEventDescriptionChange(e.target.value)}
                        />
                    </Form.Group>
                </Col>

                <Col xs={12} className="mb-3">
                    <Form.Group controlId="formEventTitle">
                        <Form.Label>
                            Start Time <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            type="datetime-local"
                            placeholder="Enter Start Time"
                            required
                            value={startTime}
                            onChange={e => handleStartTimeChange(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                <Col xs={12} className="mb-3">
                    <Form.Group controlId="formEventTitle">
                        <Form.Label>
                            End Time <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            type="datetime-local"
                            placeholder="Enter End Time"
                            required
                            value={endTime}
                            onChange={e => handleEndTimeChange(e.target.value)}
                        />
                    </Form.Group>
                </Col>
                {/* <Col xs={12} className="mb-3 create-user">
                    <Form.Group controlId="formEventTitle">
                        <Form.Label>
                            Participant <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Control
                            className="form-control-role"
                            as="select"
                            placeholder="Participant"
                            options={studentsId}
                            value={participant}
                            onChange={e => handleParticipantChange(e.target.value)}
                            required
                        >
                            {studentsId.map((id, index) =>
                                participantItem(id, index)
                            )}
                        </Form.Control>
                    </Form.Group>
                </Col> */}
                {errors && (<Alert variant="danger" className="create-user-error">
                    <Alert.Heading>
                        {errors}
                    </Alert.Heading>
                </Alert>)}
                {isEventCreated && (<Alert variant="success" className="create-user-success">
                    <Alert.Heading>
                        {'Event created successfully'}
                    </Alert.Heading>
                </Alert>)}
                <Col xs={12} style={{ textAlign: "right" }}>
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                    <Button onClick={onClear} variant="outline-primary" className="ms-2">
                        Clear
                    </Button>
                </Col>
            </Row>
        </Form>
    )
};

export default CreateEventForm;
