import { call, put, takeEvery, putResolve, select } from 'redux-saga/effects';
import { login, requestOTP, signUp, getRefreshedToken } from '../services/user.service';
import { createCustomError, createError } from '../errors';
import {
  sendOTPFailure, sendOTPRequest, sendOTPSuccess,
  loginRequest, loginSuccess, loginFailure, signUpRequest, signUpSuccess, signUpFailure, logOut, refreshTokenRequest, refreshTokenSuccess
} from '../actions/userActions';
import { toast } from 'react-toastify';

function* requestOTPHandler(params) {
  const { phoneNumber } = params.payload;
  try {
    const OTPResponse = yield call(requestOTP, phoneNumber);

    if (OTPResponse.session) {
      yield put(sendOTPSuccess({ session: OTPResponse.session, phoneNumber: phoneNumber, message: null }));
    }
    else {
      toast.error(OTPResponse.message);
      yield put(sendOTPFailure(createCustomError(OTPResponse.message)));
    }
  } catch (error) {
    toast.error(error);

    yield put(sendOTPFailure(createCustomError(error)));
  }
}

function* loginHandler(params) {
  try {
    const { phoneNumber, session, otp, history } = params.payload;
    const loginResponse = yield call(login, {phoneNumber, session, otp});

    yield putResolve(loginSuccess({ AccessToken: loginResponse.AccessToken, ExpiresIn: loginResponse.ExpiresIn, RefreshToken: loginResponse.RefreshToken,
      User: loginResponse.User }));
    let landingPage = null;

    if (loginResponse.User.role === 'Admin') {
      landingPage = '/courses/all-courses/';
    }

    if (loginResponse.User.role === 'Instructor') {
      landingPage = '/instructor/instructor-my-courses/';
    }

    if (landingPage) {
      history.push(landingPage);
    }

  } catch (error) {
    if(error === '401')
      toast.error('Unauthorized');
    else 
      toast.error('Invalid credentials');

    yield put(loginFailure(createError(error)));
  }
}

function* signUpHandler(params) {
  try {

    const signUpResponse = yield call(signUp, params.payload);
    if (signUpResponse.errorCode || signUpResponse.message != "User created successfully") {
      yield put(signUpFailure(createCustomError(signUpResponse.message)));
    }
    else {
      let responsePayload = {
        firstName: params.payload.firstName,
        lastName: params.payload.lastName,
        phoneNumber: params.payload.phoneNumber,
        email: params.payload.email,
        role: params.payload.role,
        message: signUpResponse.message,
        errorCode: ''
      }

      yield put(signUpSuccess(responsePayload));
    }
  } catch (error) {
    throw new Error(error)
  }
}

function* logoutHandler(params) {
  localStorage.removeItem('persist:root');
}

function* refreshTokenHandler(params) {
  try {
    const { refreshToken } = yield select((state) => state.user);
    
    var payload = {
      refreshToken: refreshToken
    }
    const refreshTokenResponse = yield call(getRefreshedToken, payload);

    if (refreshTokenResponse.errorCode === 401 || refreshTokenResponse.errorCode == "InvalidToken") {
      yield put(logOut());
    }

    yield put(refreshTokenSuccess(refreshTokenResponse));
  } catch (error) {
    throw new Error(error);
  }
}

function* userSaga() {
  yield takeEvery(sendOTPRequest.type, requestOTPHandler);
  yield takeEvery(loginRequest.type, loginHandler);
  yield takeEvery(signUpRequest.type, signUpHandler);
  yield takeEvery(logOut.type, logoutHandler);
  yield takeEvery(refreshTokenRequest.type, refreshTokenHandler);
}

export default [userSaga];
