import { URL } from '../shared/url';

export async function login(req) {
  const res = await fetch(URL.login, {
    method: 'POST',
    body: JSON.stringify(req),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
  })

  if (res.status >= 200 && res.status < 300) {
    return res
      .json()
      .then(resBody => {
        return Promise.resolve(resBody);
      })
      .catch(err => Promise.reject(err));
  } else {
    throw new Error(res.status.toString())
  }
}

export async function signUp(req) {
  const res = await fetch(URL.signUp, {
    method: 'POST',
    body: JSON.stringify(req),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
  })

  return res
    .json()
    .then(resBody => {
      return Promise.resolve(resBody);
    })
    .catch(err => Promise.reject(err));
}

export async function requestOTP(phoneNumber) {
  const res = await fetch(URL.requestOTP, {
    method: 'POST',
    body: JSON.stringify({
      phoneNumber
    }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
  })

  return res
    .json()
    .then(resBody => {
      return Promise.resolve(resBody);
    })
    .catch(err => Promise.reject(err));
}

export async function getRefreshedToken(payload) {
  const res = await fetch(URL.refreshToken, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  })

  return res
      .json()
      .then(resBody => {
          return Promise.resolve(resBody);
      })
      .catch(err => Promise.reject(err));
}
