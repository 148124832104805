import { useReducer } from "react";
import ConfirmContext from "store/ConfirmContext";
import { initialState, ConfirmReducer } from "reducers/ConfirmReducer";

export const ConfirmContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(ConfirmReducer, initialState);

    return (
        <ConfirmContext.Provider value={[state, dispatch]}>
            {children}
        </ConfirmContext.Provider>
    );
};
