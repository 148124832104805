// import node module libraries
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Card, Form, Button, Modal, OverlayTrigger, Tooltip, Row, Col } from 'react-bootstrap';
import { v4 as uuid } from 'uuid';
import { Formik } from 'formik';
import * as yup from 'yup';
import 'assets/scss/custom/modal.scss';

// import custom components
import GKAccordionActions from 'components/common/accordions/GKAccordionActions';
import MediaUploader from 'components/dashboard/vimeo/MediaUploaderVimeo';
import ImageUploader from 'components/common/images/ImageUploader';
import RichTextEditor from 'components/elements/editor/RichTextEditor';

import { createChapterRequest, updateChapterRequest, deleteChapterRequest, createLessonRequest, updateLessonRequest, 
	deleteLessonRequest } from 'actions/courseActions';
import { useEffect } from 'react';
import useConfirm from 'hooks/useConfirm';

const AddLecture = ({ onAddLecture, editLecture, onEditLecture, onCancelEdit, courseId, chapterId }) => {
	const [show, setShow] = useState(!!editLecture);
	const [videoDuration, setVideoDuration] = useState(null);
	const [lessonType, setLessonType] = useState('lesson');

	const handleClose = () => {
		setShow(false);
		onCancelEdit(); 
	};
	const handleShow = () => setShow(true);

	useEffect(() => {
		setShow(!!editLecture);
	}, [editLecture]);

	const schema = yup.object().shape({
		name: yup.string().required(),
		description: yup.string().required(),
		order: yup.number().required(),
		assignmentScore: yup.number(),
		thumbnail: yup.mixed().required(),
		video: yup.mixed().required(),
		isPreview: yup.boolean().required(),
		lessonType: yup.string().required(),
		isQuizEnabled: yup.boolean().when('lessonType', {
			is: lessonType => lessonType === 'experiment',
			then: yup.boolean().required()
		}),
		noOfQuestions: yup.number().when('isQuizEnabled', {
			is: isQuizEnabled => isQuizEnabled,
			then: yup.number().required().min(1)
		}),
		quizMaxRetry: yup.number().when('isQuizEnabled', {
			is: isQuizEnabled => isQuizEnabled,
			then: yup.number().required().min(1)
		}),
	});

	return (
		<Fragment>
			<Button
				variant="outline-primary"
				className="btn btn-outline-primary btn-sm mt-3"
				onClick={handleShow}
			>
				Add Lecture +
			</Button>
			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				dialogClassName="curriculum-modal"
			>
				<Modal.Header closeButton>
					<Modal.Title>{editLecture !== null ? 'Update Lecture' : 'Add New Lecture'}</Modal.Title>
				</Modal.Header>
				<Formik
					enableReinitialize
					validationSchema={schema}
					onSubmit={e => {
						editLecture !== null 
							? onEditLecture({ ...editLecture, ...e, videoDuration: videoDuration || editLecture.videoDuration }) 
							: onAddLecture({ ...e, lessonType, videoDuration }); 
						handleClose(); 
					}}
					initialValues={{
						name: editLecture?.name || '',
						description: editLecture?.description || '',
						order: editLecture?.order,
						assignmentScore: editLecture?.assignmentScore || 0,
						thumbnail: editLecture?.thumbnail || null,
						video: editLecture?.video || null,
						isPreview: editLecture?.isPreview || false,
						lessonType: editLecture?.lessonType || 'lesson',
						isQuizEnabled: editLecture?.isQuizEnabled || false,
						noOfQuestions: editLecture?.noOfQuestions || 0,
						quizMaxRetry: editLecture?.quizMaxRetry || 0
					}}
				>
					{({
						handleSubmit,
						handleChange,
						handleBlur,
						setFieldValue,
						values,
						touched,
						errors
					}) => (
						<Form noValidate onSubmit={(e) => { e.preventDefault(); handleSubmit(e); }}>
							<Modal.Body className="pb-0">
							<Form.Group className="mb-3" controlId="formaddnewlecturetype">
									<Form.Label>Lecture type</Form.Label><br />
									<Form.Check
										inline
										type="radio"
										label="Lesson" 
										name="lessonTypeLesson" 
										onChange={e => {
											setFieldValue("lessonType", 'lesson')
											setLessonType('lesson')
										}}
										onBlur={handleBlur}
										checked={values.lessonType === 'lesson'}
									/>
									<Form.Check
										inline
										type="radio"
										label="Experiment" 
										name="lessonTypeExperiment" 
										onChange={e => {
											setFieldValue("lessonType", 'experiment')
											setLessonType('experiment')
										}} 
										onBlur={handleBlur}
										checked={values.lessonType === 'experiment'}
									/>
								</Form.Group>
								<Form.Group className="mb-3" controlId="formaddnewlecture">
									<Form.Label>Lecture name</Form.Label>
									<Form.Control 
										type="text" 
										placeholder="Add new lecture" 
										name="name" 
										onChange={handleChange} 
										onBlur={handleBlur}
										value={values.name}
										isInvalid={touched.name && !!errors.name}
									/>
									<Form.Control.Feedback type="invalid">
										Lecture name is required
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group className="mb-3" controlId="formaddnewlecturedesc">
									<Form.Label>Lecture description</Form.Label>
									<RichTextEditor
										value={values.description}
										id="description"
										name="description"
										onChange={handleChange}
										onBlur={handleBlur}
										isInValid={touched.description && !!errors.description}
									/>
									{touched.description && !!errors.description && (<div className='invalid-feedback'>
										Lecture description is required
									</div>)}
								</Form.Group>
								<Form.Group className="mb-3" controlId="formaddnewlectureorder">
									<Form.Label>Lecture seq no</Form.Label>
									<Form.Control 
										type="text" 
										placeholder="lecture seq number" 
										name="order" 
										onChange={handleChange} 
										onBlur={handleBlur}
										value={values.order}
										isInvalid={touched.order && !!errors.order}
									/>
									<Form.Control.Feedback type="invalid">
										Seq number is required
									</Form.Control.Feedback>
								</Form.Group>
								{ values.lessonType === 'experiment' && 
								<Form.Group className="mb-3" controlId="formaddnewassignmentscore">
									<Form.Label>Total Assignment Score</Form.Label>
									<Form.Control 
										type="text" 
										placeholder="Total assignment score" 
										name="assignmentScore" 
										onChange={handleChange} 
										onBlur={handleBlur}
										value={values.assignmentScore}
										isInvalid={touched.assignmentScore && !!errors.assignmentScore}
									/>
									<Form.Control.Feedback type="invalid">
										{errors.assignmentScore}
									</Form.Control.Feedback>
								</Form.Group>}
								<Form.Group className="mb-3" controlId="formaddnewlectureIsPReview">
									<Form.Check 
										type="checkbox"
										label="IsPreview" 
										name="isPreview" 
										onChange={handleChange} 
										onBlur={handleBlur}
										checked={values.isPreview}
									/>
								</Form.Group>
								{
									values.lessonType === 'experiment' && (
										<Row>
											<Col>
												<Form.Group className="mb-3" controlId="formaddnewLectureIsQuizEnabled">
													<Form.Check 
														type="checkbox"
														label="Enable Emperiment Quiz" 
														name="isQuizEnabled" 
														onChange={handleChange} 
														onBlur={handleBlur}
														checked={values.isQuizEnabled}
													/>
												</Form.Group>
											</Col>
											{
												values.isQuizEnabled && (
													<Fragment>
														<Col>
															<Form.Group className="mb-3" controlId="formaddnewlecturenoofquestions">
																<Form.Label>Number of questions</Form.Label>
																<Form.Control 
																	type="text" 
																	placeholder="Number of questions" 
																	name="noOfQuestions" 
																	onChange={handleChange} 
																	value={values.noOfQuestions}
																	onBlur={handleBlur}
																	isInvalid={touched.noOfQuestions && !!errors.noOfQuestions}
																/>
																<Form.Control.Feedback type="invalid">
																	{errors.noOfQuestions}
																</Form.Control.Feedback>
															</Form.Group>
														</Col>
														<Col>
															<Form.Group className="mb-3" controlId="formaddnewlecturenoofattempts">
																<Form.Label>Number of attempts</Form.Label>
																<Form.Control 
																	type="text" 
																	placeholder="Number of attempts" 
																	name="quizMaxRetry" 
																	onChange={handleChange} 
																	value={values.quizMaxRetry}
																	onBlur={handleBlur}
																	isInvalid={touched.quizMaxRetry && !!errors.quizMaxRetry}
																/>
																<Form.Control.Feedback type="invalid">
																	{errors.quizMaxRetry}
																</Form.Control.Feedback>
															</Form.Group>
														</Col>
													</Fragment>
												)
											}
										</Row>
									)
								}

								<Form.Label>Lecture thumbnail (300x240 pixels)</Form.Label>
								<Form.Group className="mb-3 input-group" controlId="formaddnewlectureThumbnail">
									<ImageUploader
										id="thumbnail"
										type="file"
										className="form-control"
										name="thumbnail"
										placeholder="lecture thumbnail"
										accept="image/*"
										onChange={handleChange}
										onBlur={handleBlur}
										isInvalid={touched.thumbnail && !!errors.thumbnail}
										category={`lessons/${courseId}/${chapterId}/images`}
									/>
									<Form.Control.Feedback type="invalid">
										Thumbnail is required
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Label>Lecture video</Form.Label>
								<Form.Group className="mb-3 input-group" controlId="formaddnewlectureVideo">
									<MediaUploader 
										category={`lessons/videos/${courseId}/${chapterId}`}
										name="video" 
										onChange={e => {
											handleChange(e);
											setVideoDuration(e.target.duration);
										}} 
										onBlur={handleBlur} 
										isInvalid={touched.video && !!errors.video} 
										requireDuration
									/>
									<Form.Control.Feedback type="invalid">
										video is required
									</Form.Control.Feedback>
								</Form.Group>
							</Modal.Body>
							<Modal.Footer className="pt-0 border-0 d-inline " style={{marginBottom: '20px'}}>
								<Button variant="primary" type='submit'>
									{editLecture !== null ? 'Update Lecture' : 'Add New Lecture'}
								</Button>
								<Button variant="outline-secondary" onClick={handleClose}>
									Close
								</Button>
							</Modal.Footer>
						</Form>
					)}
				</Formik>
			</Modal>
		</Fragment>
	);
};

const AddSection = ({ onAddSection, editSection, onEditSection, onCancelEdit }) => {
	const [show, setShow] = useState(!!editSection);

	const handleClose = () => {
		setShow(false);
		onCancelEdit();
	};
	const handleShow = () => setShow(true);

	useEffect(() => {
		setShow(!!editSection);
	}, [editSection]);

	const schema = yup.object().shape({
		name: yup.string().required(),
		description: yup.string().required(),
		order: yup.number().required(),
		isQuizEnabled: yup.boolean().required(),
		noOfQuestions: yup.number().when('isQuizEnabled', {
			is: isQuizEnabled => isQuizEnabled,
			then: yup.number().required().min(1)
		}),
		quizMaxRetry: yup.number().when('isQuizEnabled', {
			is: isQuizEnabled => isQuizEnabled,
			then: yup.number().required().min(1)
		}),
	});

	return (
		<Fragment>
			<Button
				variant="outline-primary"
				className="btn btn-outline-primary btn-sm mt-3"
				onClick={handleShow}
			>
				Add Section
			</Button>
			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
				dialogClassName="curriculum-modal"
			>
				<Modal.Header closeButton>
					<Modal.Title>
						{!!editSection ? 'Update Section' : 'Add New Section'}
					</Modal.Title>
				</Modal.Header>
				<Formik
					enableReinitialize
					validationSchema={schema}
					onSubmit={e => {
						!!editSection ? onEditSection({ ...editSection, ...e }) : onAddSection(e); 
						 handleClose(); 
					}}
					initialValues={{
						name: editSection?.name || '',
						description: editSection?.description || '',
						order: editSection?.order,
						isQuizEnabled: editSection?.isQuizEnabled || false,
						noOfQuestions: editSection?.noOfQuestions || 0,
						quizMaxRetry: editSection?.quizMaxRetry || 0
					}}
				>
					{({
						handleSubmit,
						handleChange,
						handleBlur,
						values,
						touched,
						isValid,
						errors,
					}) => (
						<Form noValidate onSubmit={(e) => { e.preventDefault(); handleSubmit(e); }}>
							<Modal.Body className="pb-0">
								<Form.Group className="mb-3" controlId="formaddnewsection">
									<Form.Label>Section name</Form.Label>
									<Form.Control 
										type="text" 
										placeholder="Add new section" 
										name="name" 
										onChange={handleChange} 
										value={values.name}
										onBlur={handleBlur}
										isInvalid={touched.name && !!errors.name}
									/>
									<Form.Control.Feedback type="invalid">
										Section name is required
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group className="mb-3" controlId="formaddnewsectiondescription">
									<Form.Label>Section description</Form.Label>
									<Form.Control 
										type="text" 
										placeholder="Add description" 
										name="description" 
										onChange={handleChange} 
										value={values.description}
										onBlur={handleBlur}
										isInvalid={touched.description && !!errors.description}
									/>
									<Form.Control.Feedback type="invalid">
										Description is required
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group className="mb-3" controlId="formaddnewsectionorder">
									<Form.Label>Section seq no</Form.Label>
									<Form.Control 
										type="text" 
										placeholder="Add Seq number" 
										name="order" 
										onChange={handleChange} 
										value={values.order}
										onBlur={handleBlur}
										isInvalid={touched.order && !!errors.order}
									/>
									<Form.Control.Feedback type="invalid">
										Seq number is required
									</Form.Control.Feedback>
								</Form.Group>
								<Form.Group className="mb-3" controlId="formaddnewSectionIsQuizEnabled">
									<Form.Check 
										type="checkbox"
										label="Enable unit test" 
										name="isQuizEnabled" 
										onChange={handleChange} 
										onBlur={handleBlur}
										checked={values.isQuizEnabled}
									/>
								</Form.Group>
								{
									values.isQuizEnabled && (
										<Fragment>
											<Form.Group className="mb-3" controlId="formaddnewsectionnoofquestions">
												<Form.Label>Number of questions</Form.Label>
												<Form.Control 
													type="text" 
													placeholder="Number of questions" 
													name="noOfQuestions" 
													onChange={handleChange} 
													value={values.noOfQuestions}
													onBlur={handleBlur}
													isInvalid={touched.noOfQuestions && !!errors.noOfQuestions}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.noOfQuestions}
												</Form.Control.Feedback>
											</Form.Group>
											<Form.Group className="mb-3" controlId="formaddnewsectionoofattempts">
												<Form.Label>Number of attempts</Form.Label>
												<Form.Control 
													type="text" 
													placeholder="Number of attempts" 
													name="quizMaxRetry" 
													onChange={handleChange} 
													value={values.quizMaxRetry}
													onBlur={handleBlur}
													isInvalid={touched.quizMaxRetry && !!errors.quizMaxRetry}
												/>
												<Form.Control.Feedback type="invalid">
													{errors.quizMaxRetry}
												</Form.Control.Feedback>
											</Form.Group>
										</Fragment>
									)
								}
							</Modal.Body>
							<Modal.Footer className="pt-0 border-0 d-inline ">
								<Button variant="primary" type="submit">
									{!!editSection ? 'Update Section' : 'Add New Section'}
								</Button>
								<Button variant="outline-secondary" onClick={handleClose}>
									Close
								</Button>
							</Modal.Footer>
						</Form>
					)}
				</Formik>
			</Modal>
		</Fragment>
	);
};

const Curriculum = (props) => {
	const { next, previous, data } = props;
	const dispatch = useDispatch();
	const [editSection, setEditSection] = useState(null);
	const [editLecture, setEditLecture] = useState(null);

	const handleAddSection = section => {
		dispatch(createChapterRequest({ 
			...section, 
			courseId: data.id,
			noOfQuestions: section.isQuizEnabled ? section.noOfQuestions : 0,
			quizMaxRetry: section.isQuizEnabled ? section.quizMaxRetry : 0
		}));
		// data.push({ ...section, lessons: [] });
		// handleChange(data);
	}

	const handleEditSection = section => {
		dispatch(updateChapterRequest({
			id: section.id,
			name: section.name,
			description: section.description,
			order: section.order,
			isQuizEnabled: section.isQuizEnabled,
			noOfQuestions: section.isQuizEnabled ? section.noOfQuestions : 0,
			quizMaxRetry: section.isQuizEnabled ? section.quizMaxRetry : 0
		}))
	}

	const handleDeleteSection = section => {
		dispatch(deleteChapterRequest(section));
	}

	const handleAddLecture = (chapterIndex, lecture) => {
		dispatch(createLessonRequest({ ...lecture, chapterId: data['chapters'][chapterIndex].id, chapterIndex }));
		// data[sectionIndex].lessons.push(lecture);
		// handleChange(data);
	}

	const handleEditLecture = (lecture) => {
		const updateLesson = {
			id: lecture.id,
			name: lecture.name,
			description: lecture.description,
			order: lecture.order,
			assignmentScore: lecture.assignmentScore,
			video: lecture.video,
			videoDuration: lecture.videoDuration,
			isPreview: lecture.isPreview,
			lessonType: lecture.lessonType,
			chapterIndex: lecture.chapterIndex,
			thumbnail: lecture.thumbnail,
			isQuizEnabled: lecture.isQuizEnabled,
			quizMaxRetry: lecture.quizMaxRetry,
			noOfQuestions: lecture.noOfQuestions
		}
		
		dispatch(updateLessonRequest(updateLesson));
		setEditLecture(null);
	}

	const handleDeleteLecture = (chapterIndex, lecture) => {
		dispatch(deleteLessonRequest({ chapterIndex, lessonId: lecture.id }))
	}

	const {confirm} = useConfirm();
    const showConfirm = async (chapter) => {
        const isConfirmed = await confirm('Are you sure?');
        if (isConfirmed) {
            handleDeleteSection(chapter)
        }
    }

	return (
		<Form>
			{/* Card */}
			<Card className="mb-3  border-0">
				<Card.Header className="border-bottom px-4 py-3">
					<h4 className="mb-0">Curriculum</h4>
				</Card.Header>
				{/* Card body */}
				<Card.Body>
					{
						data.chapters.map((chapter, i) => (
							<div className="bg-light rounded p-2 mb-4">
								<div className="d-flex align-items-center justify-content-between">
									<h4>{chapter.name}</h4>
									<div>
										<OverlayTrigger
											key={uuid()}
											placement="top"
											overlay={<Tooltip id="tooltip-top"> Edit</Tooltip>}
										>
											<Link to="#" className="me-1 text-inherit" title="Edit" onClick={() => setEditSection(chapter)}>
												<i className="fe fe-edit fs-6"></i>
											</Link>
										</OverlayTrigger>

										<OverlayTrigger
											key={uuid()}
											placement="top"
											overlay={<Tooltip id="tooltip-top"> Delete</Tooltip>}
										>
											<Link to="#" className="me-1 text-inherit" title="Delete" onClick={() => showConfirm(chapter)}>
												<i className="fe fe-trash-2 fs-6"></i>
											</Link>
										</OverlayTrigger>
									</div>
								</div>
								<GKAccordionActions 
									accordionItems={chapter.lessons} 
									onEdit={lecture => { setEditLecture({ ...lecture, chapterIndex: i }) }}
									onDelete={lecture => handleDeleteLecture(i, lecture)}
								/>
								<AddLecture 
									onAddLecture={lecture => handleAddLecture(i, lecture)} 
									editLecture={editLecture}
									onEditLecture={handleEditLecture}
									onCancelEdit={() => setEditLecture(null)}
									courseId={data.id}
									chapterId={chapter.id}
								/>
							</div>
						))
					}
					
					<AddSection 
						onAddSection={handleAddSection} 
						editSection={editSection}
						onEditSection={handleEditSection}
						onCancelEdit={setEditSection.bind(this, null)}
					/>
				</Card.Body>
			</Card>
			{/* Button */}
			<div className="d-flex justify-content-between">
				<Button variant="secondary" onClick={previous}>
					Previous
				</Button>
				<Button variant="primary" onClick={next}>
					Next
				</Button>
			</div>
		</Form>
	);
};
export default Curriculum;
