// import node module libraries
import React, { Fragment, useEffect } from 'react';

// import layouts
import NavbarDefault from 'layouts/common/navbars/NavbarDefault';
import Footer from 'layouts/common/footers/Footer';

const DefaultLayout = (props) => {
	useEffect(() => {
		document.body.style.backgroundColor = '#f5f4f8';
	});
	return (
		<Fragment>
			<NavbarDefault login />
			{props.children}
		</Fragment>
	);
};

export default DefaultLayout;
