// import node module libraries
import React, {Fragment, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Row, Col, Container, Nav, Navbar} from 'react-bootstrap';

// import custom components
import ProfileCover from 'components/common/headers/ProfileCover';

// import routes file
import {
    DashboardMenu,
    AccountSettingsMenu
} from 'routes/dashboard/InstructorDashboard';

// import media files
import Avatar1 from 'assets/images/avatar/avatar-1.jpg';
import {useSelector} from "react-redux";
import InstructorDashboardMenu from "../../routes/dashboard/InstructorDashboard";
import AdminDashboardMenu from "../../routes/dashboard/AdminDashBoard";

const ProfileLayout = (props) => {
    const location = useLocation();
    const user = useSelector(state => state.user);

    useEffect(() => {
        document.body.style.backgroundColor = '#f5f4f8';
    });

    const dashboardData = {
        avatar: Avatar1,
        name: `${user.firstName} ${user.lastName}`,
        linkname: 'Create New Course',
        link: '/instructor/add-new-course/'
    };

    const dashboardItem = (dashboardData, index) => {
        return (
            <>
            {dashboardData.map((item, index) => (
                    <div>
                        <Nav.Item className="navbar-header" as="li">
                            {item.title}
                        </Nav.Item>

                        {item.children.map((item, index) => (
                            <Nav.Item
                                as="li"
                                key={index}
                                className={`${
                                    item.link === location.pathname ? 'active' : ''
                                }`}>
                                <Link className="nav-link" to={item.link}>
                                    <i className={`fe fe-${item.icon} nav-icon`}></i>
                                    {item.title}
                                </Link>
                            </Nav.Item>
                        ))}
                    </div>
                ))}
            </>
        );
    }

    return (
        <Fragment>
            <div className="pt-5 pb-5">
                <Container>
                    {/* User info */}
                    <ProfileCover dashboardData={dashboardData}/>

                    {/* Content */}
                    <Row className="mt-0 mt-md-4">
                        <Col lg={3} md={4} sm={12}>
                            <Navbar
                                expand="lg"
                                className="navbar navbar-expand-md navbar-light shadow-sm mb-4 mb-lg-0 sidenav"
                            >
                                <Link
                                    className="d-xl-none d-lg-none d-md-none text-inherit fw-bold fs-5 float-start py-1"
                                    to="#"
                                >
                                    Menu
                                </Link>
                                <Navbar.Toggle
                                    aria-controls="basic-navbar-nav"
                                    className="p-0 focus-none border-0"
                                    label="Responsive Menu"
                                >
									<span
                                        className="navbar-toggler d-md-none icon-shape icon-sm rounded bg-primary p-0 text-white float-end"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#sidenav"
                                        aria-controls="sidenav"
                                        aria-expanded="false"
                                        aria-label="Toggle navigation"
                                    >
										<span className="fe fe-menu"></span>
									</span>
                                </Navbar.Toggle>

                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="me-auto flex-column" as="ul">
                                        {user.role === 'Instructor' && dashboardItem(InstructorDashboardMenu)}
                                        {user.role === 'Admin' && dashboardItem(AdminDashboardMenu)}
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                        </Col>

                        <Col lg={9} md={8} sm={12}>
                            {props.children}
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
};
export default ProfileLayout;
