// import node module libraries
import Select from 'react-select';
import PropTypes from 'prop-types';

export const FormMultiSelect = (props) => {
	const { selectedOption, options, name, onChange, onBlur } = props;

	const handleChange = selectedOption => {
		onChange({ target: { name, value: selectedOption }});
	}

	const handleBlur = selectedOption => {
		onBlur({ target: { name, value: selectedOption } })
	}

	return (
		<Select
			isMulti
			value={selectedOption}
			onChange={handleChange}
			options={options}
			onBlur={handleBlur}
		/>
	);
};

FormMultiSelect.propTypes = {
	placeholder: PropTypes.string.isRequired,
	selectedOption: PropTypes.object.isRequired,
	id: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onBlur: PropTypes.func.isRequired,
	options: PropTypes.object.isRequired
};

FormMultiSelect.defaultProps = {
	placeholder: '',
	selectedOption: '',
	id: '',
	name: ''
};

export default FormMultiSelect;
