// ** Import from react dom
import { Route, Switch, Redirect } from 'react-router-dom';

// ** Import core SCSS styles
import 'assets/scss/theme.scss';

// ** Import Layouts
import DashboardIndex from 'layouts/dashboard/DashboardIndex';
import AuthLayout from 'layouts/dashboard/AuthLayout';
import Error404 from 'components/common/Error404';
import NotFound from 'layouts/common/NotFound';
import AllCourses from 'components/dashboard/courses/all-courses/AllCourses';
import CourseEnroll from 'components/dashboard/transactions/CourseEnroll';
import AllTransactions from 'components/dashboard/transactions/all-transactions/AllTransactions';
import Instructor from 'components/dashboard/user/Instructor';
import Students from 'components/dashboard/user/Students';
import SignIn from 'components/dashboard/authentication/SignIn';
// Instructor Dashboard Pages
import DefaultLayout from 'layouts/dashboard/DefaultLayout';
import InstructorDashboard from 'components/instructor/Dashboard';
import InstructorMyCourses from 'components/instructor/MyCourses';
import AddNewCourse from 'components/common/pages/courses/add-new-course/AddNewCourse';
import DisplayQuiz from 'components/common/pages/courses/add-new-quiz/DisplayQuiz'
import Admin from "../components/dashboard/user/Admin";
import Author from "../components/dashboard/user/Author"
import CreateUser from "../components/dashboard/user/create-user/CreateUser";
import UploadBanner from "../components/dashboard/courses/UploadBanner";
import CreateEvent from 'components/common/pages/events/CreateEvent';
import AddNewQuiz from 'components/common/pages/courses/add-new-quiz/AddNewQuiz';
import AllEvents from 'components/common/pages/events/AllEvents';
import CreateEventForm from 'components/common/pages/events/CreateEventForm';
import AllAssignments from 'components/dashboard/assignments/all-assignments/AllAssignments';
import AllShipments from 'components/dashboard/shipments/AllShipments';
import StudentReport from 'components/common/pages/report/StudentReport';
import SalesReport from 'components/common/pages/report/SalesReport';

const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
	<Route
		{...rest}
		render={(props) => (
			<Layout>
				<Component {...props}></Component>
			</Layout>
		)}
	></Route>
);

function AllRoutes() {
	return (
		<Switch>
			<AppRoute exact path="/" layout={AuthLayout} component={SignIn} />
			{/* INSTRUCTOR PAGES ROUTERS - START */}
			{/* --------------------------- */}

			<AppRoute
				exact
				path="/instructor/add-new-course/"
				layout={DefaultLayout}
				component={AddNewCourse}
			/>

			<AppRoute
				exact
				path="/instructor/edit-course/:courseId"
				layout={DefaultLayout}
				component={AddNewCourse}
			/>

			<AppRoute
				exact
				path="/quiz/add-quiz/"
				layout={DefaultLayout}
				component={AddNewQuiz}
			/>

			<AppRoute
				exact
				path="/quiz/list-quiz"
				layout={DefaultLayout}
				component={DisplayQuiz}
			/>

			<AppRoute
				exact
				path="/instructor/dashboard/"
				layout={DefaultLayout}
				component={InstructorDashboard}
			/>
			<AppRoute
				exact
				path="/instructor/instructor-my-courses/"
				layout={DefaultLayout}
				component={InstructorMyCourses}
			/>

			{/* FRONT SIDE INSTRUCTOR DASHBOARD-ACCOUNT SETTINGS ROUTERS */}
			{/*<AppRoute*/}
			{/*	exact*/}
			{/*	path="/instructor/instructor-edit-profile/"*/}
			{/*	layout={DefaultLayout}*/}
			{/*	component={EditProfile}*/}
			{/*/>*/}
			{/*<AppRoute*/}
			{/*	exact*/}
			{/*	path="/instructor/instructor-profile/"*/}
			{/*	layout={DefaultLayout}*/}
			{/*	component={ViewProfile}*/}
			{/*/>*/}
			{/*<AppRoute*/}
			{/*	exact*/}
			{/*	path="/instructor/instructor-security/"*/}
			{/*	layout={DefaultLayout}*/}
			{/*	component={Security}*/}
			{/*/>*/}


			{/* COURSES ROUTERS */}
			<AppRoute
				exact
				path="/courses/upload-banner"
				layout={DefaultLayout}
				component={UploadBanner}
			/>
			<AppRoute
				exact
				path="/courses/all-courses"
				layout={DefaultLayout}
				component={AllCourses}
			/>
			<AppRoute
				exact
				path="/courses/transactions"
				layout={DefaultLayout}
				component={AllTransactions}
			/>
			<AppRoute
				exact
				path="/courses/enroll"
				layout={DefaultLayout}
				component={CourseEnroll}
			/>
			<AppRoute
				exact
				path="/courses/shipments"
				layout={DefaultLayout}
				component={AllShipments}
			/>
			<AppRoute
				exact
				path="/courses/assignments"
				layout={DefaultLayout}
				component={AllAssignments}
			/>

			<AppRoute
				exact
				path="/user/create-user"
				layout={DefaultLayout}
				component={CreateUser}
			/>

			<AppRoute
				exact
				path="/user/edit-user/:userId"
				layout={DefaultLayout}
				component={CreateUser}
			/>

			<AppRoute
				exact
				path="/event/create-event"
				layout={DefaultLayout}
				component={CreateEvent}
			/>
			<AppRoute
				exact
				path="/user/instructor"
				layout={DefaultLayout}
				component={Instructor}
			/>
			<AppRoute
				exact
				path="/user/student"
				layout={DefaultLayout}
				component={Students}
			/>
			<AppRoute
				exact
				path="/user/admin"
				layout={DefaultLayout}
				component={Admin}
			/>
			<AppRoute
				exact
				path="/user/author"
				layout={DefaultLayout}
				component={Author}
			/>
			<AppRoute
				exact
				path="/event/list"
				layout={DefaultLayout}
				component={AllEvents}
			/>
			<AppRoute
				exact
				path="/report/student"
				layout={DefaultLayout}
				component={StudentReport}
			/>
			<AppRoute
				exact
				path="/event/update/"
				layout={DefaultLayout}
				component={CreateEvent}
			/>
			<AppRoute
				exact
				path="/report/sales"
				layout={DefaultLayout}
				component={SalesReport}
			/>
			<AppRoute
				exact
				path="/404-error/"
				layout={NotFound}
				component={Error404}
			/>
			<Redirect to="/404-error/" />
		</Switch>
	);
}

export default AllRoutes;
