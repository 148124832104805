import { all, fork } from 'redux-saga/effects';

import courseSaga from './courseSaga';
import userSaga from './userSaga';
import adminUserSaga from "./adminUserSaga";

export default function* root() {
    yield all([
        ...courseSaga.map(saga => fork(saga)),
        ...userSaga.map(saga => fork(saga)),
        ...adminUserSaga.map(saga => fork(saga))
    ]);
}
