// import node module libraries
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Breadcrumb, Card } from 'react-bootstrap';

// import bootstrap icons
import CreateUserForm from './CreateUserForm';
import ProfileLayout from "../../../instructor/ProfileLayout";

const CreateUser = () => {
	return (
		<ProfileLayout>
			{/* create User form  */}
			<div>
				{/* row */}
				<Row>
					<Col xl={12} md={12} xs={12}>
						<Card>
							{/* card body */}
							<Card.Body className="p-lg-6">
								<CreateUserForm />
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</div>
		</ProfileLayout>
	);
};

export default CreateUser;
