// import node module libraries
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Form, Button, Modal, Spinner, Image } from 'react-bootstrap';
import { Image as ImageIcon, Video } from 'react-feather';
import ModalVideo from 'react-modal-video'
import { Formik } from 'formik';
import * as yup from 'yup';
import { slugifyString as slugify } from 'shared/utils';

import { createCourseRequest, clearDraftCourseStatus, updateCourseRequest } from 'actions/courseActions';
import MediaUploader from 'components/dashboard/vimeo/MediaUploaderVimeo';
import ImageUploader from 'components/common/images/ImageUploader';
import { getMpdUrl } from 'shared/utils';

const CoursesMedia = (props) => {
	const dispatch = useDispatch();
	const { isDraftCourseSaving, isDraftCourseSaved } = useSelector(state => state.courses);
	const { next, previous, handleChange, data } = props;
	const [ previewVideo, setPreviewVideo ] = useState(null);
	const [ previewImage, setPreviewImage ] = useState(null);

	useEffect(() => {
		if (isDraftCourseSaved) {
			next();
			dispatch(clearDraftCourseStatus());
		}
	}, [isDraftCourseSaved]);

	const handleNextClick = (e) => {
		if (!data.id) {
			dispatch(createCourseRequest({ ...data, ...e }));
		} else {
			const updatedCourse = {
				courseId: data.id,
				name: data.name,
				description: data.description,
				level: data.level || null,
				price: data.price,
				isShipping: data.isShipping,
				shipmentData: data.shipmentData,
				trailer: e.trailer,
				glimpse: e.glimpse,
				thumbnail: e.thumbnail,
				coverPhoto: e.coverPhoto
			};
			dispatch(updateCourseRequest(updatedCourse));
		}
	}

	const schema = yup.object().shape({
		trailer: yup.string().required(),
		glimpse: yup.string().required(),
		thumbnail: yup.mixed().required(),
		coverPhoto: yup.mixed().required(),
	});

	return (
		<Formik
			enableReinitialize
			validationSchema={schema}
			onSubmit={handleNextClick}
			initialValues={{
				trailer: data.trailer || null,
				glimpse: data.glimpse || null,
				thumbnail: data.thumbnail || null,
				coverPhoto: data.coverPhoto || null
			}}
		>
			{({
				handleSubmit,
				handleChange,
				handleBlur,
				values,
				touched,
				isValid,
				errors
			}) => (
				<Form noValidate onSubmit={(e) => { e.preventDefault(); handleSubmit(e); }}>
					{/* Card */}
					<Card className="mb-3  border-0">
						<Card.Header className="border-bottom px-4 py-3">
							<h4 className="mb-0">Courses Media</h4>
						</Card.Header>
						{/* Card body */}
						<Card.Body>
							{/* Course cover image */}
							<Form.Label>Course cover image (1600x500 pixels)</Form.Label>
							<Form.Group className="mb-1 input-group">
								<ImageUploader
									id="coverPhoto"
									type="file"
									className="form-control"
									name="coverPhoto"
									accept="image/*"
									onChange={handleChange}
									onBlur={handleBlur}
									isInvalid={touched.coverPhoto && !!errors.coverPhoto}
									category={`lessons/${slugify(data.name)}/images`}
								/>
								{/* <Form.Label
									htmlFor="coverPhoto"
									className="input-group-text mb-0"
								>
									Upload
								</Form.Label> */}
								<Form.Text className="text-muted">
									Upload your course image here. It must meet our course image
									quality standards to be accepted. Important guidelines: 1600x500
									pixels; .jpg, .jpeg,. gif, or .png. no text on the image.
								</Form.Text>
								<Form.Control.Feedback type="invalid">
									Cover photo is required
								</Form.Control.Feedback>
							</Form.Group>
							{
								data.coverPhoto && (
									<div className='mb-3'>
										<Button variant="outline-white" className="me-1 mb-1" onClick={() => setPreviewImage(values.coverPhoto)}>
											<ImageIcon size="15px" className="dropdown-item-icon me-1" />
											Preview CoverPhoto
										</Button>
									</div>
								)
							}
							<Form.Label>Course thumbnail image (480x720 pixels)</Form.Label>
							<Form.Group className="mb-1 input-group">
								<ImageUploader
									id="thumbnail"
									type="file"
									className="form-control"
									name="thumbnail"
									accept="image/*"
									onChange={handleChange}
									onBlur={handleBlur}
									isInvalid={touched.thumbnail && !!errors.thumbnail}
									category={`lessons/${slugify(data.name)}/images`}
								/>
								<Form.Control.Feedback type="invalid">
									Thumbnail is required
								</Form.Control.Feedback>
							</Form.Group>
							{
								data.thumbnail && (
									<div className='mb-3'>
										<Button variant="outline-white" className="me-1 mb-1" onClick={() => setPreviewImage(values.thumbnail)}>
											<ImageIcon size="15px" className="dropdown-item-icon me-1" />
											Preview Thumbnail
										</Button>
									</div>
								)
							}

							<Form.Label>Course glimpse</Form.Label>
							<Form.Group className="mb-1 input-group">
								<MediaUploader category={`lessons/${slugify(data.name)}/glimpse`} id="glimpse" name="glimpse" onChange={handleChange} onBlur={handleBlur} isInvalid={touched.glimpse && !!errors.glimpse}/>
								<Form.Control.Feedback type="invalid">
									Glimpse is required
								</Form.Control.Feedback>
							</Form.Group>
							{
								data.glimpse && (
									<div className='mb-3'>
										<Button variant="outline-white" className="me-1 mb-1" onClick={() => setPreviewVideo(values.glimpse)}>
											<Video size="15px" className="dropdown-item-icon me-1" /> Preview Glimpse
										</Button>
										<Button variant="outline-white" className="me-1 mb-1" onClick={() => setPreviewVideo(getMpdUrl(values.glimpse))}>
											<Video size="15px" className="dropdown-item-icon me-1" /> Preview Glimpse Mpd
										</Button>
									</div>
								)
							}

							<Form.Label>Course trailer</Form.Label>
							<Form.Group className="mb-1 input-group">
								<MediaUploader category={`lessons/${slugify(data.name)}/trailer`} id="trailer" name="trailer" onChange={handleChange} onBlur={handleBlur} isInvalid={touched.trailer && !!errors.trailer}/>
								<Form.Control.Feedback type="invalid">
									Trailer is required
								</Form.Control.Feedback>
							</Form.Group>
							{
								data.trailer && (
									<div className='mb-3'>
										<Button variant="outline-white" className="me-1 mb-1" onClick={() => setPreviewVideo(values.trailer)}>
											<Video size="15px" className="dropdown-item-icon me-1" /> Preview Trailer
										</Button>
										<Button variant="outline-white" className="me-1 mb-1" onClick={() => setPreviewVideo(getMpdUrl(values.trailer))}>
											<Video size="15px" className="dropdown-item-icon me-1" /> Preview Trailer Mpd
										</Button>
									</div>
								)
							}
						</Card.Body>
					</Card>

					{/* Button */}
					<div className="d-flex justify-content-between">
						<Button variant="secondary" onClick={previous}>
							Previous
						</Button>
						<Button variant="primary" type="submit">
							Next
						</Button>
					</div>
					<Modal
						show={isDraftCourseSaving}
						// onHide={handleClose}
						backdrop="static"
						keyboard={false}
						className="form-submit-loader"
					>
						<Modal.Body className="pb-0">
							<Spinner
								animation="border"
								variant="primary"
								className="me-2"
							/>
						</Modal.Body>
					</Modal>
					<ModalVideo channel='custom' url={previewVideo} isOpen={!!previewVideo} onClose={() => setPreviewVideo(null)} />
					<Modal
						show={!!previewImage}
						onHide={() => { setPreviewImage(null) }}
						size="lg"
					>
						<Modal.Header closeButton/>
						<Modal.Body className='pt-0'>
							<Image src={previewImage} height={500} width="100%" />
						</Modal.Body>
					</Modal>
				</Form>
			)}
		</Formik>
	);
};
export default CoursesMedia;
