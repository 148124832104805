// import node module libraries
import { Fragment, useEffect, useMemo } from 'react';
import { Card, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { submitCourseRequest } from 'actions/courseActions';
import { getUsersRequest } from 'actions/adminUserActions';

// import custom components
import FormMultiSelect from 'components/elements/form-select/FormMultiSelect';
import { useState } from 'react';

const Authors = (props) => {
	const { previous, data, handleChange } = props;
	const authors = useSelector(state => state.adminUser.users);
	const role = useSelector(state => state.user.role);
	const authorOptions = useMemo(() => authors.map((author, i) => ({
		label: `${author.firstName} ${author.lastName}`,
		value: {
			name: `${author.firstName} ${author.lastName}`,
            description: 'No description',
            order: i,
            institution: author.instituteName || 'NA',
            cognitoSub: author.id,
            profilePicture: author.profilePicture || null,
			badgeLogo: author.badgeLogo || null,
		}
	})), [authors]);
	const [selectedAuthors, setSelectedAuthors] = useState(data?.authors.map(author => ({
		label: author.name,
		value: {
			id: author.id,
			name: author.name,
            description: author.description,
            order: author.order,
            institution: author.institution,
            cognitoSub: author.cognitoSub,
            profilePicture: author.profilePicture,
			badgeLogo: author.badgeLogo,
			createdAt: author.createdAt ? new Date(author.createdAt) : author.createdAt,
			updatedAt: author.updatedAt ? new Date(author.updatedAt) : author.updatedAt
		}
	})) || []);
	const dispatch = useDispatch();
	const history = useHistory();

	const handleSubmit = status => {
		dispatch(submitCourseRequest({ courseId: data.id, status, history, authors: selectedAuthors.map(({ value }) => value) }))
	}

	const handleAuthorChange = e => {
		const authors = e.target.value;
		setSelectedAuthors(authors);
		handleChange(authors.map(({ value }) => value));
	}

	useEffect(() => {
		dispatch(getUsersRequest({ role: 'Author' }));
	}, []);


	return (
		<Fragment>
			<Card className="mb-3  border-0">
				<Card.Header className="border-bottom px-4 py-3">
					<h4 className="mb-0">Authors</h4>
				</Card.Header>
				{/* Card body */}
				<Card.Body>
					<FormMultiSelect 
						name="authors" 
						selectedOption={selectedAuthors} 
						options={authorOptions} 
						onChange={handleAuthorChange} 
						onBlur={() => {}}
				/>
				</Card.Body>
			</Card>
			<div className="d-flex justify-content-between mb-22">
				{/* Button */}
				<Button variant="secondary" onClick={previous}>
					Previous
				</Button>
				{
					role === 'Admin' ? (
						<div>
							<Button className="mx-1" variant="success" onClick={handleSubmit.bind(this, 'approved')}>
								Approve
							</Button>
							<Button variant="danger" onClick={handleSubmit.bind(this, 'rejected')}>
								Reject
							</Button>
						</div>
					) : (
						<Button variant="danger" onClick={handleSubmit.bind(this, 'pending')}>
							Submit For Review
						</Button>
					)
				}
			</div>
		</Fragment>
	);
};
export default Authors;
