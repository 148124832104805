// import node module libraries
import {Card, Tab } from 'react-bootstrap';
import StudentsListItems from './StudentsListItems';
import ProfileLayout from "../../instructor/ProfileLayout";
import { useSelector } from 'react-redux'; 

const Students = () => {

	const usersCount = useSelector(state => state.adminUser.usersCount);

	return (
		<ProfileLayout>
			<Tab.Container defaultActiveKey="list">
				<Tab.Content>
					{/*<Tab.Pane eventKey="grid" className="pb-4">*/}
					{/*	/!* students in list view *!/*/}
					{/*	<StudentsGridCard studentsList={users}/>*/}
					{/*	/!* end of students in list view *!/*/}
					{/*</Tab.Pane>*/}
					<h4 className="mb-1 h4 fw-light">
						Students <span className="fs-5 text-muted">({usersCount})</span>
					</h4>
					<Tab.Pane eventKey="list" className="pb-4">
						<Card className="mb-5 ">
							<Card.Body className="p-0">
								{/* students in list view */}
									<StudentsListItems/>
								{/* end of students in list view */}
							</Card.Body>
						</Card>
					</Tab.Pane>
				</Tab.Content>
			</Tab.Container>
		</ProfileLayout>
	);
};
export default Students;
