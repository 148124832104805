import {createReducer} from '@reduxjs/toolkit';

import {
    getUsersSuccess,
    getUsersRequest,
    getUsersFailure, createUserRequest, createUserSuccess, createUserFailure, 
    clearErrorState, getEventsRequestSuccess, getEventsRequestFailure,
    getUserRequest, getUserSuccess, resetUser, updateUserSuccess, resetMessage, deleteUserSuccess
} from '../actions/adminUserActions';

const initialState = {
    users: [],
    usersCount: 0,
    errorMessage: null,
    hasError: false,
    isUserCreated: false,
    isCreateUserError: false,
    successMessage: null,
    events:[],
    eventsError: '',
    editUser: null
};

const adminUserReducer = createReducer(initialState, builder => {
    builder
        .addCase(getUsersRequest, state => ({
            ...state
        }))

        .addCase(
            getUsersFailure,
            (state, action) => ({
                ...state,
                errorMessage: action.payload.message,
                hasError: true
            })
        )

        .addCase(getUsersSuccess,
            (state, action) => ({
                ...state,
                users: action.payload.users,
                usersCount: action.payload.usersCount,
                errorMessage: null,
                hasError: false
            })
        )

        .addCase(createUserRequest,
            (state, action) => ({
                ...state,
            })
        )

        .addCase(createUserSuccess,
            (state, action) => ({
                ...state,
                isUserCreated: true,
                isCreateUserError: false,
                errorMessage: null,
                successMessage: action.payload.message
            })
        )

        .addCase(createUserFailure,
            (state, action) => ({
                ...state,
                errorMessage: action.payload.message,
                isCreateUserError: true,
                isUserCreated: false,
                successMessage: null
            })
        )

        .addCase(clearErrorState,
            (state, action) => ({
                ...state,
                errorMessage: null,
                isCreateUserError: false,
                hasError: null,
                isUserCreated: false,
                successMessage: null
            })
        )

        .addCase(getEventsRequestSuccess,
            (state, action) => ({
                ...state,
                events: action.payload
            })
        )
        .addCase(getEventsRequestFailure,
            (state, action) => ({
                ...state,
                eventsError: action.payload.message
            })
        )
        .addCase(getUserRequest,
            (state) => ({
                ...state,
                editUser: null
            })
        )
        .addCase(getUserSuccess,
            (state, action) => ({
                ...state,
                editUser: action.payload
            })
        )
        .addCase(updateUserSuccess,
            (state) => ({
                ...state,
                successMessage: 'User updated successfully'
            })
        )
        .addCase(resetUser,
            (state) => ({
                ...state,
                editUser: null
            })
        ).addCase(resetMessage,
            (state) => ({
                ...state,
                successMessage: null,
                errorMessage: null
            })
        ).addCase(deleteUserSuccess,
            (state, action) => ({
                ...state,
                users: state.users.filter(user => user.id !== action.payload.userId)
            })
        );
});

export default adminUserReducer;
