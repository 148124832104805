// import node module libraries
import {Fragment, useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Col, Row, Card, Form, Button, Image } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import OTPInput from './OTPInput';
import { sendOTPRequest, loginRequest } from 'actions/userActions';

// import media files
import Logo from 'assets/images/brand/logo/logo-icon.svg';

import 'react-phone-number-input/style.css'

const SignIn = () => {
	const [phoneNumber, setPhoneNumber] = useState('');
	const [otp, setOTP] = useState('');
	const { isOTPSent, session } = useSelector(state => state.user);
	const dispatch = useDispatch();
	const history = useHistory();

	const user = useSelector(state => state.user);

	useEffect(() => {
		if(user.accessToken ) {
			if(user.role === 'Admin')
				history.push('/courses/all-courses');
			else
				history.push('/instructor/instructor-my-courses/');
		}
	});

	const handlePhoneNumberChange = phoneNumber => {
		setPhoneNumber(phoneNumber);
	}

	const handleOTPChange = (otp) => {
		setOTP(otp);
	}

	const handleRequestOtp = () => {
		dispatch(sendOTPRequest({ phoneNumber }));
	}

	const handleLogin = () => {
		dispatch(loginRequest({
			phoneNumber,
			otp,
			session: session ?? '',
			history
		}));
	}

	return (
		<Fragment>
			<Row className="align-items-center justify-content-center g-0 min-vh-100">
				<Col lg={5} md={5} className="py-8 py-xl-0">
					<Card>
						<Card.Body className="p-6">
							<div className="mb-4">
								<h1 className="mb-1 fw-bold">Sign in</h1>
							</div>
							{/* Form */}
							<Form>
								<Row>
									<Col lg={12} md={12} className="mb-3">
										{/* Phone number */}
										<Form.Label>Phone number</Form.Label>
										<PhoneInput
											className='form-control form-phone-input'
											placeholder="Enter phone number"
											value={phoneNumber}
											defaultCountry="IN"
											onChange={handlePhoneNumberChange}
										/>
									</Col>
									{isOTPSent ? (
										<Fragment>
											<Col lg={12} md={12} className="mb-3">
												{/* Phone number */}
												<Form.Label>Enter OTP</Form.Label>
												<OTPInput
													autoFocus
													length={4}
													className="form-otp"
													inputClassName="form-control"
													onChangeOTP={handleOTPChange}
												/>
											</Col>
											<Col lg={12} md={12} className="mb-0 d-grid gap-2">
												{/* Button */}
												<Button variant="primary" onClick={handleLogin}>
													Login
												</Button>
											</Col>
										</Fragment>
									): (<Col lg={12} md={12} className="mb-0 d-grid gap-2">
									{/* Button */}
									<Button variant="primary" onClick={handleRequestOtp}>
										Request OTP
									</Button>
								</Col>)}
								</Row>
							</Form>
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</Fragment>
	);
};

export default SignIn;
