// import node module libraries
import React from 'react';
import { Row, Col, Button, ListGroup } from 'react-bootstrap';

const Pagination = ({
	previousPage,
	pageCount,
	pageIndex,
	gotoPage,
	nextPage
}) => {
	
	const noOfPages = 10;
	const range = (start, end) => {
		const array = [];
		for(let i = start; i < end; i++)
			array.push(i);
		return array;
	}

	const pages = (pageIndex, pageCount) => {
		if(pageIndex < noOfPages/2)
			return range(0, Math.min(noOfPages, pageCount))
		else if(pageCount - pageIndex < noOfPages/2)
			return range(pageCount - Math.min(noOfPages, pageCount), pageCount);
		else 
			return range(pageIndex - noOfPages/2, pageIndex + noOfPages/2);
	}

	return (
		<Row>
			<Col lg={12} md={12} sm={12}>
				<div className="pb-5">
					<nav>
						<ListGroup
							as="ul"
							bsPrefix="pagination"
							className="justify-content-center mb-0"
						>
							<ListGroup.Item as="li" bsPrefix="page-item">
								<Button
									onClick={() => previousPage()}
									className="page-link mx-1 rounded"
								>
									<i className="fe fe-chevron-left"></i>
								</Button>
							</ListGroup.Item>
							{pages(pageIndex, pageCount).map((page) => (
								<ListGroup.Item
									as="li"
									bsPrefix="page-item"
									key={page}
									className={`page-item ${pageIndex === page ? 'active' : ''}`}
								>
									<Button
										className="page-link mx-1 rounded"
										onClick={() => gotoPage(page)}
									>
										{page + 1}
									</Button>
								</ListGroup.Item>
							))}
							<ListGroup.Item as="li" bsPrefix="page-item">
								<Button
									onClick={() => nextPage()}
									className="page-link mx-1 rounded"
								>
									<i className="fe fe-chevron-right"></i>
								</Button>
							</ListGroup.Item>
						</ListGroup>
					</nav>
				</div>
			</Col>
		</Row>
	);
};

export default Pagination;
