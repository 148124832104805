import { useState, useEffect } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { stateFromHTML } from 'draft-js-import-html';
import _noop from 'lodash/noop';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const RichTextEditor = ({ name, value, onChange, onBlur, isInValid, editorRef = _noop }) => {
    const emptyLineRegex = /<p><\/p>\n/g;
    const emptyStrRegex = /&nbsp;/g;
	const [editorState, setEditorState] = useState(value ? EditorState.createWithContent(stateFromHTML(value)): EditorState.createEmpty());

    useEffect(() => {
        if (!value) {
            setEditorState(EditorState.createEmpty());
        }
    }, [value])

    useEffect(() => {
        editorRef({ setEditorState });
    }, [])

    const getHtml = () => {
        const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));

        if (html.replace(emptyStrRegex, '').replace(emptyLineRegex, '') === '') 
            return '';
        
        return html;
    }

	const handleBlur = () => {
		onBlur({ target: { name: name, value: getHtml() } });
	}

    const onEditorStateChange = editorState => {
		onChange({ target: { name: name, value: getHtml() } });
		return setEditorState(editorState)
	}

    return <div className={`editor${isInValid ? ' form-control is-invalid' : ''}`}>
        <Editor
            editorState={editorState}
            editorClassName={`editor-custom`}
            toolbar={{
                options: ['inline', 'blockType', 'fontSize', 'list', 'textAlign', 'emoji', 'link'],
            }}
            onEditorStateChange={onEditorStateChange}
            onBlur={handleBlur}
        />
    </div>
};

export default RichTextEditor;
