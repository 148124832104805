// import node module libraries
import React, { Fragment, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination
} from 'react-table';
import { Link } from 'react-router-dom';
import { Col, Row, Table } from 'react-bootstrap';

// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';

import moment from "moment/moment";

const ShipmentsTable = ({ shipments_data }) => {
	// The forwardRef is important!!
	// Dropdown needs access to the DOM node in order to position the Menu
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to="#"
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			{children}
		</Link>
	));

	const dispatch = useDispatch();

	const columns = useMemo(
		() => [
			{ accessor: 'id', Header: 'ID', show: false },
			{ accessor: 'userName', Header: 'USER',
			  Cell: ({ value, row }) => {
			 	return (
			 		<Fragment>
			 			{value}
			 		</Fragment>
			 	);}
			},
			{ accessor: 'status', Header: 'STATUS',
			  Cell: ({ value, row }) => {
			 	return (
			 		<Fragment>
			 			{value.charAt(0).toUpperCase() + value.slice(1)}
			 		</Fragment>
			 	);}
			},
			{ accessor: 'course.name', Header: 'COURSE',
			  Cell: ({ value, row }) => {
			 	return (
			 		<Fragment>
			 			{value}
			 		</Fragment>
			 	);}
			},
			{ accessor: 'awbCode', Header: 'AWB CODE',
			  Cell: ({ value, row }) => {
			 	return (
			 		<Fragment>
			 			{value}
			 		</Fragment>
			 	);}
			},
			{ accessor: 'courierCompanyId', Header: 'COURIER ID',
			  Cell: ({ value, row }) => {
			 	return (
			 		<Fragment>
			 			{value}
			 		</Fragment>
			 	);}
			},
			{ accessor: 'courierName', Header: 'COURIER NAME',
			Cell: ({ value, row }) => {
			   return (
				   <Fragment>
					   {value}
				   </Fragment>
			   );}
		  	},
			{ accessor: 'orderId', Header: 'ORDER ID',
			  Cell: ({ value, row }) => {
				 return (
					 <Fragment>
						 {value}
					 </Fragment>
				 );}
			},
			{ accessor: 'shipmentId', Header: 'SHIPMENT ID',
			  Cell: ({ value, row }) => {
			     return (
			  	   <Fragment>
			  		   {value}
			  	   </Fragment>
			     );}
			},
			{ accessor: 'createdAt', Header: 'CREATED AT',
			  Cell: ({ value, row }) => {
			 	return (
			 		<Fragment>
			 			{moment(value).format("DD/MM/YYYY HH:mm")}
			 		</Fragment>
			 	);}
			},
			{ accessor: 'updatedAt', Header: 'UPDATED AT',
			Cell: ({ value, row }) => {
			   return (
					(value != null ? (
				   <Fragment>
					   {moment(value).format("DD/MM/YYYY HH:mm")}
				   </Fragment>) : <Fragment></Fragment>)
			   );}
		  }
		],
		[]
	);

	const data = useMemo(() => shipments_data, [shipments_data]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		state,
		gotoPage,
		pageCount,
		prepareRow,
		setGlobalFilter
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: 10,
				hiddenColumns: columns.map((column) => {
					if (column.show === false) return column.accessor || column.id;
					else return false;
				})
			}
		},
		useFilters,
		useGlobalFilter,
		usePagination
	);

	const { pageIndex, globalFilter } = state;

	return (
		<Fragment>
			<div className=" overflow-hidden">
				<Row>
					<Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-4 px-5 ">
						<GlobalFilter
							filter={globalFilter}
							setFilter={setGlobalFilter}
							placeholder="Search Shipments"
						/>
					</Col>
				</Row>
			</div>

			<div className="table-responsive border-0">
				<Table {...getTableProps()} className="text-nowrap">
					<thead className="table-light">
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps()}>
										{column.render('Header')}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell) => {
										return (
											<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</Table>
			</div>

			{/* Pagination @ Footer */}
			<Pagination
				previousPage={previousPage}
				pageCount={pageCount}
				pageIndex={pageIndex}
				gotoPage={gotoPage}
				nextPage={nextPage}
			/>
		</Fragment>
	);
};

export default ShipmentsTable;
