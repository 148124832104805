import {useEffect, useMemo} from "react";
import {useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {Col, Row, Form, Button} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';


import FormPhone from "components/elements/bootstrap/forms/FormPhone";
import ProfileLayout from "components/instructor/ProfileLayout";

import { getCoursesRequest, courseOfflineEnrollRequest } from "actions/courseActions"

const CourseEnroll = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { courses } = useSelector(state => state.courses);
    const approveCourses = useMemo(() => courses.filter(c => c.status === 'approved'), [courses]);

    useEffect(() => {
        dispatch(getCoursesRequest());
    }, []);
    
    const onSubmit = enroll => {
        dispatch(courseOfflineEnrollRequest({ enroll: { ...enroll, currency: 'INR' }, history }))
    }

    const schema = yup.object().shape({
        userPhoneNumber: yup.string().required(),
        courseId: yup.string().required(),
        amount: yup.number().required(),
        reference: yup.string()
	});

    return (
        <ProfileLayout>
            <Formik
                enableReinitialize 
                validationSchema={schema}
                onSubmit={onSubmit}
                initialValues={{
                    userPhoneNumber: '',
                    courseId: '',
                    amount: '',
                    reference: '',
                }}
            >
                {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                    resetForm,
                    setFieldValue
                }) => (
                    <Form noValidate onSubmit={(e) => { e.preventDefault(); handleSubmit(e); }} autoComplete="on">
                        <Row>
                            <Col xs={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label>
                                        Phone number <span className="text-danger">*</span>
                                    </Form.Label>
                                    <FormPhone

                                        name="userPhoneNumber"
                                        value={values.phoneNumber}
                                        defaultCountry="IN"
                                        id="phone-input"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        required
                                        isInValid={touched.userPhoneNumber && !!errors.userPhoneNumber}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Phone number is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            
                            {/* Set Priority */}
                            <Col xs={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label>Course <span className="text-danger">*</span></Form.Label>
                                    <Form.Control
                                        className="form-control-role"
                                        as="select"
                                        placeholder="Course"
                                        id="courseId"
                                        name="courseId"
                                        value={values.courseId}
                                        onChange={e => {
                                            const course = approveCourses.find(c => c.id === Number(e.target.value));
                                            if (course) {
                                                setFieldValue('amount', course.price)
                                            }
                                            handleChange(e);
                                        }}
                                        onBlur={handleBlur}
                                        isInvalid={touched.courseId && !!errors.courseId}
                                    >
                                        <option key={0} value={''} >{"Select course"}</option>
                                        {
                                            approveCourses.map(c => (
                                                <option key={c.id} value={c.id} >{c.name}</option>
                                            ))
                                        }
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        Course is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label>
                                        Course Price <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Course Price"
                                        id="amount"
                                        name="amount"
                                        value={values.amount}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.amount && !!errors.amount}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Course Price is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col xs={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label>
                                        Reference <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Reference"
                                        id="reference"
                                        name="reference"
                                        value={values.reference}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.reference && !!errors.reference}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Reference is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            {/* Buttons */}
                            <Col xs={12} style={{textAlign: "right"}}>
                                <Button variant="primary" type="submit">
                                    Submit
                                </Button>
                                <Button onClick={resetForm} variant="outline-primary" className="ms-2">
                                    Reset
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Formik>
        </ProfileLayout>
    );
};

export default CourseEnroll;
