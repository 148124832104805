const baseApiUrl = process.env.REACT_APP_API_URL;

export const URL = {
  login: `${baseApiUrl}/user/admin-login`,
  requestOTP: `${baseApiUrl}/user/request-otp`,
  signUp : `${baseApiUrl}/user/sign-up`,
  refreshToken: `${baseApiUrl}/user/refresh-token`,
  getCourses: `${baseApiUrl}/courses`,
  createCourses: `${baseApiUrl}/courses`,
  updateCourses: `${baseApiUrl}/courses`,
  deleteCourses: `${baseApiUrl}/courses`,
  createChapters: `${baseApiUrl}/courses/chapters`,
  updateChapters: `${baseApiUrl}/courses/chapters`,
  deleteChapters: `${baseApiUrl}/courses/chapters`,
  createLessons: `${baseApiUrl}/courses/lessons`,
  updateLessons: `${baseApiUrl}/courses/lessons`,
  deleteLessons: `${baseApiUrl}/courses/lessons`,
  getLessonVideoUrl: `${baseApiUrl}/courses/lessons/video`,
  createFAQs: `${baseApiUrl}/courses/faqs`,
  updateFAQs: `${baseApiUrl}/courses/faqs`,
  deleteFAQs: `${baseApiUrl}/courses/faqs`,
  createTask: `${baseApiUrl}/courses/tasks`,
  updateTask: `${baseApiUrl}/courses/tasks`,
  deleteTask: `${baseApiUrl}/courses/tasks`,
  adminUsers: `${baseApiUrl}/admin-user`,
  updateAuthor: `${baseApiUrl}/courses/authors`,
  uploadCourseAsset: `${baseApiUrl}/courses/assets`,
  createCourseBanner: `${baseApiUrl}/courses/banner`,
  updateCourseBanner: `${baseApiUrl}/courses/banner`,
  getCourseBanners: `${baseApiUrl}/courses/banner`,
  deleteCourseBanners: `${baseApiUrl}/courses/banner`,
  coursesVideoCompleteUpload: `${baseApiUrl}/courses/videos/complete-upload`,
  event: `${baseApiUrl}/user/events`,
  getEnrolledStudents: `${baseApiUrl}/courses/get-enrolled`,
  getCourseStatus: `${baseApiUrl}/courses/get-user-status`,
  addQuestion: (chapterId, experimentId) => `${baseApiUrl}/courses/chapters/${chapterId}/quiz${experimentId ? `?experimentId=${experimentId}` : ''}`,
  getQuiz: ({ courseId, status }) => courseId ? `${baseApiUrl}/courses/chapters/${courseId}/quiz` : `${baseApiUrl}/courses/quiz/?status=${status}`,
  updateQuestion: (questionId) =>  `${baseApiUrl}/courses/chapters/quiz/${questionId}`,
  getTransactions: `${baseApiUrl}/courses/payments/transactions`,
  getTransactionsStats: `${baseApiUrl}/courses/payments/stats`,
  getAssignments: `${baseApiUrl}/courses/assignments`,
  offlineCourseEnroll: `${baseApiUrl}/courses/payments/order/offline`,
  deleteTransaction: `${baseApiUrl}/courses/payments/order`,
  getPaymentAnalytics: `${baseApiUrl}/courses/payments/analytics`,
  getShipmentOrders: `${baseApiUrl}/courses/shipments/getOrders`,
  deleteEnrollment: `${baseApiUrl}/courses/enrollment`,
};
