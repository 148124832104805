// import node module libraries
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Tab, Card, Nav, CardGroup, Form } from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import { getTransactionsRequest, getTransactionsStatsRequest } from 'actions/courseActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndianRupee, faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons';
import '../../../../assets/scss/custom/transaction.scss';

// import sub custom components
import TransactionsTable from './TransactionsTable';
import ProfileLayout from "../../../instructor/ProfileLayout";
import moment from 'moment';
import { useState } from 'react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

// import MDI icons
import Icon from '@mdi/react';
import { mdiFilterOff } from '@mdi/js';

const AllTransactions = () => {
	const pageSize = 20;
	const [dateState, setDateState] = useState([
		{
		  startDate: null,
		  endDate: null,
		  key: 'selection'
		}
	  ]);
	const dispatch = useDispatch();
	const {totalAmount, todayAmount, thisWeekAmount,lastWeekAmount} = useSelector(state => state.courses);
	const [selectedTransactionStatus, setSelectedTransactionStatus] = useState('all');
	const transactionStatus = [
		{ id: 'all', name: 'All' },
		{ id: 'started', name: 'Started' },
		{ id: 'inprogress', name: 'In-Progress' },
		{ id: 'success', name: 'Success' },
		{ id: 'failure', name: 'Failure' },
		{ id: 'offline', name: 'Offline' }
	];
	
	const [selectedStatusOption, setSelectedStatusOption] = useState(transactionStatus[0].id);


	useEffect(() => {
		dispatch(getTransactionsRequest({page: 1, limit: pageSize}));
		dispatch(getTransactionsStatsRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(()=> {
		dispatch(getTransactionsRequest({
			status: selectedStatusOption, 
			page: 1, 
			limit: pageSize, 
			startDate: dateState[0].startDate, 
			endDate: dateState[0].endDate}));
	}, [dateState, selectedStatusOption])


	const statusDropDown = (options, selectedOption, handleSelect) => {
        return <Form.Select value={selectedOption} style={{ width: '20vw', marginLeft: "20px" }} onChange={e => setSelectedStatusOption(e.target.value)}>
            {options && options.map(({ id, name }, index) => <option key={index} value={id}>{name}</option>)}
        </Form.Select>
    }

	const dateDropDown = () => {
        return <DateRange
			onChange={item => {
				setDateState([item.selection])
			}}
			moveRangeOnFirstSelection={false}
			ranges={dateState}
			style={{marginLeft: '40px'}}
	  	/>
    }

	const clearFilter = () => {
		setDateState([{
			startDate: null,
			endDate: null,
			key: 'selection'
		  }]);

		  setSelectedStatusOption('all');
	}

	return (
		<ProfileLayout>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<Tab.Container defaultActiveKey="all">
						<Card>
							<Card.Header className="border-bottom-0 p-0 bg-white">
								<Nav className="nav-lb-tab">
									<Nav.Item>
										<Nav.Link eventKey="all" className="mb-sm-3 mb-md-0">
											All
										</Nav.Link>
									</Nav.Item>
								</Nav>
							</Card.Header>
							<Card.Body className="p-0">
								<CardGroup>
									<Card>
										<Card.Body>
											<Card.Title style={{ textAlign: 'center' }}>TODAY</Card.Title>
											<Card.Text style={{ textAlign: 'center' }}>
												<FontAwesomeIcon size={'2x'} icon={faIndianRupee} />&nbsp;<span className="transaction-amount">{todayAmount}</span>
											</Card.Text>
										</Card.Body>
									</Card>
									<Card>
										<Card.Body>
											<Card.Title style={{ textAlign: 'center' }}>THIS WEEK</Card.Title>
											<Card.Text style={{ textAlign: 'center' }}>
												<FontAwesomeIcon size={'2x'} icon={faIndianRupee} />&nbsp;<span className="transaction-amount">{thisWeekAmount}</span>
											</Card.Text>
										</Card.Body>
									</Card>
									<Card>
										<Card.Body>
											<Card.Title style={{ textAlign: 'center' }}>LAST WEEK</Card.Title>
											<Card.Text style={{ textAlign: 'center' }}>
												<FontAwesomeIcon size={'2x'} icon={faIndianRupeeSign} />&nbsp;<span className="transaction-amount">{lastWeekAmount}</span>
											</Card.Text>
										</Card.Body>
									</Card>
									<Card>
										<Card.Body>
											<Card.Title style={{ textAlign: 'center' }}>ALL TIME</Card.Title>
											<Card.Text style={{ textAlign: 'center' }}>
												<FontAwesomeIcon size={'2x'} icon={faIndianRupeeSign} />&nbsp;<span className="transaction-amount">{totalAmount}</span>
											</Card.Text>
										</Card.Body>
									</Card>
									<Card className="clear-filter-card">
										<Card.Body className="align-items-center d-flex justify-content-center">
										<OverlayTrigger placement="bottom" overlay={<Tooltip id={`tooltip-clear-filter`}>Clear Filter</Tooltip>}>
											<Icon
												path={mdiFilterOff}
												className="me-2 clear-filter-icon"
												size={1}
												onClick={() => clearFilter()}
											/>
										</OverlayTrigger>
										</Card.Body>
									</Card>
								</CardGroup>
								<CardGroup style={{marginTop: '20px'}}>
									<Row>
										<Col sm={6}>
											<Card.Title style={{ textAlign: 'center' }}>Status</Card.Title>
											{statusDropDown(transactionStatus, selectedStatusOption, (value) => setSelectedTransactionStatus(value))}
										</Col >
										<Col sm={6}>  
											<Card.Title style={{ textAlign: 'center' }}>Date</Card.Title>
											{dateDropDown()}
										</Col>
									</Row>
								</CardGroup>
								<Tab.Content>
									<Tab.Pane eventKey="all" className="pb-4">
										<TransactionsTable
											pageSize={pageSize} 
											startDate={dateState[0].startDate}
											endDate={dateState[0].endDate}
											selectedStatusOption={selectedStatusOption}
										/>
									</Tab.Pane>
								</Tab.Content>
							</Card.Body>
						</Card>
					</Tab.Container>
				</Col>
			</Row>
		</ProfileLayout>
	);
};

export default AllTransactions;
