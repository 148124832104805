// import node module libraries
import React, { Fragment, useMemo } from 'react';
import { useDispatch } from 'react-redux'; 
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination
} from 'react-table';
import { Link } from 'react-router-dom';
import {
	Dropdown,
	Image,
	Row,
	Col,
	Table
} from 'react-bootstrap';
import { MoreVertical, Trash, Edit } from 'react-feather';
import Avatar from 'assets/images/avatar/avatar.jpg';

// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';
import moment from "moment";

import { deleteUserRequest } from "actions/adminUserActions";
import useConfirm from 'hooks/useConfirm';

const InstructorListItems = ({ instructorList }) => {

	const dispatch = useDispatch();

	const {confirm} = useConfirm();
    const showConfirm = async (userId) => {
		const isConfirmed = await confirm('Are you sure?');
        if (isConfirmed) {
			dispatch(deleteUserRequest({ userId }))
        }
    }
	
	// The forwardRef is important!!
	// Dropdown needs access to the DOM node in order to position the Menu
	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to=""
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			{children}
		</Link>
	));

	const ActionMenu = ({ userId }) => {
		return (
			<Dropdown>
				<Dropdown.Toggle as={CustomToggle}>
					<MoreVertical size="15px" className="text-secondary" />
				</Dropdown.Toggle>
				<Dropdown.Menu align="end">
					<Dropdown.Header>SETTINGS</Dropdown.Header>
					<Dropdown.Item eventKey="1" href={`/user/edit-user/${userId}`}>
						{' '}
						<Edit size="18px" className="dropdown-item-icon" /> Edit
					</Dropdown.Item>
					<Dropdown.Item eventKey="2" onClick={() => showConfirm(userId) }>
						{' '}
						<Trash size="18px" className="dropdown-item-icon" /> Remove
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		);
	};

	const columns = useMemo(
		() => [
			{ accessor: 'id', Header: 'ID', show: false },
			{
				accessor: 'firstName',
				Header: 'NAME',
				Cell: ({ value, row }) => {
					return (
						<div className="d-flex align-items-center">
							<Image
								src={row.original.profilePicture ?? Avatar}
								alt=""
								className="rounded-circle avatar-md me-2"
							/>
							<h5 className="mb-0">{value + ' ' + row.original.lastName}</h5>
						</div>
					);
				}
			},
			{
				accessor: 'phoneNumber',
				Header: 'PHONE NUMBER',
				Cell: ({ value, row }) => {
					return (
						<div className="d-flex align-items-center">
							<h5 className="mb-0">{value}</h5>
						</div>
					);
				}
			},
			{
				accessor: 'status',
				Header: 'STATUS',
				Cell: ({ value, row }) => {
					return (
						<div className="d-flex align-items-center">
							<h5 className="mb-0">{value}</h5>
						</div>
					);
				}
			},
			{
				accessor: 'phoneVerified',
				Header: 'PHONE VERIFIED',
				Cell: ({ value, row }) => {
					return (
						<div className="d-flex align-items-center">
							<h5 className="mb-0">{value}</h5>
						</div>
					);
				}
			},
			{
				accessor: 'createdAt',
				Header: 'CREATED ON',
				Cell: ({ value, row }) => {
					return (
						<div className="d-flex align-items-center">
							<h5 className="mb-0">{moment(value).format("DD/MM/YYYY")}</h5>
						</div>
					);
				}
			},
			{
				accessor: 'shortcutmenu',
				Header: '',
				Cell: ({ row }) => {
					return <ActionMenu userId={row.original.id}/>;
				}
			}
		],
		[]
	);

	const data = useMemo(() => instructorList, [instructorList]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		state,
		gotoPage,
		pageCount,
		prepareRow,
		setGlobalFilter
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: 10,
				hiddenColumns: columns.map((column) => {
					if (column.show === false) return column.accessor || column.id;
					else return false;
				})
			}
		},
		useFilters,
		useGlobalFilter,
		usePagination
	);

	const { pageIndex, globalFilter } = state;

	return (
		<Fragment>
			<div className=" overflow-hidden">
				<Row>
					<Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 px-5 py-4">
						<GlobalFilter
							filter={globalFilter}
							setFilter={setGlobalFilter}
							placeholder="Search Instructors"
						/>
					</Col>
				</Row>
			</div>

			<div className="table-responsive ">
				<Table {...getTableProps()} className="text-nowrap">
					<thead className="table-light">
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column) => (
								<th {...column.getHeaderProps()}>
									{column.render('Header')}
								</th>
							))}
						</tr>
					))}
					</thead>
					<tbody {...getTableBodyProps()}>
					{page.map((row) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell) => {
									return (
										<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
									);
								})}
							</tr>
						);
					})}
					</tbody>
				</Table>
			</div>

			{/* Pagination @ Footer */}
			<Pagination
				previousPage={previousPage}
				pageCount={pageCount}
				pageIndex={pageIndex}
				gotoPage={gotoPage}
				nextPage={nextPage}
			/>
		</Fragment>
	);
};

export default InstructorListItems;
