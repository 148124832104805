// import node module libraries
import React, { useContext, Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import {
	Accordion,
	Card,
	useAccordionButton,
	AccordionContext,
	OverlayTrigger,
	Tooltip,
	Modal,
	Button,
	Image
} from 'react-bootstrap';
import { Image as ImageIcon, Video } from 'react-feather';
import ModalVideo from 'react-modal-video';
import axios from 'axios';
import {toast} from 'react-toastify';
import { URL } from 'shared/url';
import useConfirm from 'hooks/useConfirm';

const GKAccordionActions = ({ accordionItems, onEdit, onDelete }) => {
	const accessToken = useSelector(state => state.user.accessToken);
	const [ previewVideo, setPreviewVideo ] = useState(null);
	const [ previewImage, setPreviewImage ] = useState(null);

	const ContextAwareToggle = ({ children, eventKey, callback, onEdit, onDelete }) => {
		const currentEventKey = useContext(AccordionContext);
		const decoratedOnClick = useAccordionButton(
			eventKey,
			() => callback && callback(eventKey)
		);
		const isCurrentEventKey = currentEventKey === eventKey;
		const overlayKeyEdit = uuid();
		const overlayKeyDelete = uuid();

		const {confirm} = useConfirm();
		const showConfirm = async () => {
			const isConfirmed = await confirm('Are you sure?');
			if (isConfirmed) {
				onDelete()
			}
		}

		return (
			<Fragment>
				<div className="d-flex align-items-center justify-content-between">
					<h5 className="mb-0">
						<Link
							onClick={decoratedOnClick}
							aria-expanded={isCurrentEventKey}
							to="#"
							className="text-inherit"
						>
							<span className="align-middle p-1">{children}</span>
						</Link>
					</h5>
					<div>
						<OverlayTrigger
							key={overlayKeyEdit}
							placement="top"
							overlay={<Tooltip id="tooltip-top"> Edit</Tooltip>}
						>
							<Link to="#" className="me-1 text-inherit" title="Edit" onClick={onEdit}>
								<i className="fe fe-edit fs-6"></i>
							</Link>
						</OverlayTrigger>

						<OverlayTrigger
							key={overlayKeyDelete}
							placement="top"
							overlay={<Tooltip id="tooltip-top"> Delete</Tooltip>}
						>
							<Link to="#" className="me-1 text-inherit" title="Delete" onClick={showConfirm}>
								<i className="fe fe-trash-2 fs-6"></i>
							</Link>
						</OverlayTrigger>

						<Link
							to="#"
							className="text-inherit"
							data-bs-toggle="collapse"
							onClick={decoratedOnClick}
							aria-expanded={isCurrentEventKey}
						>
							<span className="chevron-arrow">
								<i className="fe fe-chevron-down fs-5"></i>
							</span>
						</Link>
					</div>
				</div>
			</Fragment>
		);
	};

	const generateKey = (pre) => {
		pre = pre.toLowerCase();
		pre = pre.replace(' ', '_');
		return `${pre}_${new Date().getTime()}`;
	};

	const previewLesson = async (lessonId, type = null) => {
		try {
			const signedUrlResponse = await axios.get(`${URL.getLessonVideoUrl}/${lessonId}${type ? `?type=${type}&strictType=true` : ''}`, 
				{ headers: { Authorization: `Bearer ${accessToken}` } });
			setPreviewVideo(signedUrlResponse.data?.url);
		} catch(e) {
			toast.error(e.message);
		}
	}

	return (
		<Fragment>
			<Accordion defaultActiveKey={accordionItems.length > 0 && accordionItems[0].id}>
				{accordionItems.map((item, index) => (
					<Card
						key={generateKey(item.name + index)}
						className="px-2 py-2 mb-1 shadow-none"
					>
						<Card.Header className="bg-transparent border-0 p-0">
							<div className="border-0">
								<h3 className="mb-0 fw-bold">
									<ContextAwareToggle 
										eventKey={item.id + '_' + index} 
										onEdit={onEdit.bind(this, item)}
										onDelete={onDelete.bind(this, item)}
									>
										{item.name}
									</ContextAwareToggle>
								</h3>
							</div>
						</Card.Header>
						<Accordion.Collapse eventKey={item.id + '_' + index}>
							<Card.Body className="fs-4">
								{/* {item.content}	 */}
								<Button variant="outline-white" className="me-1 mb-1" onClick={() => setPreviewImage(item.thumbnail)}>
									<ImageIcon size="15px" className="dropdown-item-icon me-1" />
									Preview Thumbnail
								</Button>{' '}
								<Button variant="outline-white" className="me-1 mb-1" onClick={async () => {
									await previewLesson(item.id); 
								}}>
									<Video size="15px" className="dropdown-item-icon me-1" /> Preview Lesson
								</Button>
								<Button variant="outline-white" className="me-1 mb-1" onClick={async () => {
									await previewLesson(item.id, 'mpd'); 
								}}>
									<Video size="15px" className="dropdown-item-icon me-1" /> Preview Lesson Mpd
								</Button>
							</Card.Body>
						</Accordion.Collapse>
					</Card>
				))}
			</Accordion>
			<ModalVideo channel='custom' url={previewVideo} isOpen={!!previewVideo} onClose={() => setPreviewVideo(null)} />
			<Modal
				show={!!previewImage}
				onHide={() => { setPreviewImage(null) }}
				size="lg"
			>
				<Modal.Header closeButton/>
				<Modal.Body className='pt-0'>
					<Image src={previewImage} height={500} width="100%" />
				</Modal.Body>
			</Modal>
		</Fragment>
	);
};

export default GKAccordionActions;
