import { combineReducers } from 'redux';

import coursesReducer from './coursesReducer';
import userReducer from './userReducer';
import adminUserReducer from "./adminUserReducer";

export default combineReducers({
  courses: coursesReducer,
  user: userReducer,
  adminUser: adminUserReducer
});
