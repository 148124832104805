// import node module libraries
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, Tab, Card, Nav, CardGroup, Form } from 'react-bootstrap';

import { getShipmentsRequest } from 'actions/courseActions';

// import sub custom components
import ProfileLayout from "../../instructor/ProfileLayout";
import ShipmentsTable from './ShipmentsTable';

const AllShipments = () => {
	const dispatch = useDispatch();
	const shipments = useSelector(state => state.courses?.shipments);
	const [selectedShipmentStatus, setSelectedShipmentStatus] = useState('all');
	const shipmentStatus = [
		{ id: 'all', name: 'All' },
		{ id: 'started', name: 'Started' },
		{ id: 'inprogress', name: 'In-Progress' },
		{ id: 'success', name: 'Success' },
		{ id: 'failure', name: 'Failure' }
	];
	const [selectedStatusOption, setSelectedStatusOption] = useState(shipmentStatus[0].id);
	const [filteredShipments, setFilteredShipments] = useState(shipments);


	useEffect(() => {
		dispatch(getShipmentsRequest());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const filterStatus = (filter) => {
		setSelectedStatusOption(filter);

		(filter == 'all' ? setFilteredShipments(shipments) : setFilteredShipments(shipments.filter(x => x.status === filter)));
	}

	// const statusDropDown = (options, selectedOption, handleSelect) => {
    //     return <Form.Select value={selectedOption} style={{ width: '20vw', marginLeft: "20px" }} onChange={e => filterStatus(e.target.value)}>
    //         {options && options.map(({ id, name }, index) => <option key={index} value={id}>{name}</option>)}
    //     </Form.Select>
    // }

	return (
		<ProfileLayout>
			<Row>
				<Col lg={12} md={12} sm={12}>
					<Tab.Container defaultActiveKey="all">
						<Card>
							<Card.Header className="border-bottom-0 p-0 bg-white">
								<Nav className="nav-lb-tab">
									<Nav.Item>
										<Nav.Link eventKey="all" className="mb-sm-3 mb-md-0">
											All
										</Nav.Link>
									</Nav.Item>
								</Nav>
							</Card.Header>
							<Card.Body className="p-0">
								{/* <CardGroup style={{marginTop: '20px'}}>
									<Row>
										<Col sm={6}>
											<Card.Title style={{ textAlign: 'center' }}>Status</Card.Title>
											{statusDropDown(transactionStatus, selectedStatusOption, (value) => setSelectedTransactionStatus(value))}
										</Col >
									</Row>
								</CardGroup> */}
								<Tab.Content>
									<Tab.Pane eventKey="all" className="pb-4">
										<ShipmentsTable shipments_data={filteredShipments ?? shipments} />
									</Tab.Pane>
								</Tab.Content>
							</Card.Body>
						</Card>
					</Tab.Container>
				</Col>
			</Row>
		</ProfileLayout>
	);
};

export default AllShipments;
