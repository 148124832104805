import {URL} from '../shared/url';


export async function putQuestion(request, accessToken) {
    const payload = request.payload;
    const data = {
        "questions": payload.questions
    };
    const res = await fetch(`${URL.addQuestion(payload.chapterId, payload.experimentId)}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data)
    })
    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function updateQuestion(payload, accessToken) {
    const { question } = payload;
    const data = {
        text: question.text,
        score: question.score,
        level: question.level,
        type: question.type,
        status: question.status,
        choices: question.choices.map(c => ({
            id: c.id,
            text: c.text,
            isCorrect: c.isCorrect
        }))
    }
    const res = await fetch(URL.updateQuestion(question.id), {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data)
    })
    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function getQuiz(payload, accessToken) {
    const res = await fetch(URL.getQuiz(payload), {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
    })
    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}
