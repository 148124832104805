import { getCoursesRequest } from 'actions/courseActions';
import { useState } from 'react';
import { useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useDispatch, useSelector } from 'react-redux';
import { URL } from '../../../../shared/url'
import StatusReport from './StatusReport';
import { Button } from 'react-bootstrap';
import { utils, writeFile } from 'xlsx';
import ProfileLayout from "../../../instructor/ProfileLayout";

const StudentReport = () => {
    const dispatch = useDispatch();
    const courses = useSelector(state => state.courses.courses);
    const accessToken = useSelector(state => state.user.accessToken);

    useEffect(() => {
        dispatch(getCoursesRequest());
    }, []);



    const [courseSelected, setCourseSelected] = useState(null);
    const [enrolledStudents, setEnrolledStudents] = useState(null);
    const [selectedStudent, setSelectedStudent] = useState(null);
    const [statusInfo, setStatusInfo] = useState(null);
    const [courseStatus, setCourseStatus] = useState(null);
    const [totalQuizScore, setTotalQuizScore] = useState(null);
    const [totalWatchedTime, setTotalWatchedTime] = useState(null);
    const [totalAssignmentScore, setTotalAssignmentScore] = useState(null);
    const [deleted, setDeleted] = useState(null);

    useEffect(() => {
        if (courseSelected == null) return
        fetch(`${URL.getEnrolledStudents}/${courseSelected.id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        }).then((res) => {
            if (res.status == 200) {
                res.json().then((enrolled) => {
                    setDeleted(false);
                    setEnrolledStudents(enrolled)
                })
            }
        })
    }, [courseSelected])

    useEffect(() => {
        if (selectedStudent == null) return
        fetch(`${URL.getCourseStatus}/${courseSelected.id}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({
                id: selectedStudent.cognitoSub
            })
        }).then((res) => {
            if (res.status == 200) {
                res.json().then((statusInfo) => {
                    setStatusInfo(statusInfo.information.map((infos)=>({
                        id: infos.id,
                        name: infos.name,
                        score: infos.score,
                        type: infos.type,
                        status: infos.isCompleted === true? "COMPLETED"  : ((infos.isStarted === true) ? "STARTED" : "NOT STARTED"),
                    })))
                    setTotalAssignmentScore(statusInfo.totalAssignmentScore)
                    setTotalQuizScore(statusInfo.totalQuizScore)
                    setTotalWatchedTime(statusInfo.totalWatchedTime)
                    setCourseStatus(statusInfo.courseStatus)
                })
            }
        })
    }, [selectedStudent])

    const onDownload = () => {
        var workbook = utils.book_new();
        var worksheet = utils.json_to_sheet(statusInfo);
        utils.book_append_sheet(workbook, worksheet, "Results");
        writeFile(workbook, 'report.xlsx', { type: 'file' });
    }

    const onDelete = () =>{
        if (selectedStudent == null) return
        fetch(`${URL.deleteEnrollment}/${selectedStudent.id}`, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        }).then(() => {
            setDeleted(true);
            setCourseSelected(null);
            setStatusInfo(null);
            setEnrolledStudents(null);
            setSelectedStudent(null)
            setTotalAssignmentScore(null);
            setTotalWatchedTime(null)
            setTotalQuizScore(null);
            setCourseStatus(null);
        })
    }

    return (<>
        <ProfileLayout>
            <div> Choose course: </div>
            <Typeahead
                onChange={(selected) => {
                    setCourseSelected(selected[0])
                }}
                options={courses}
                labelKey={option => `${option.name} ${option.id}`}
            />
            {enrolledStudents &&
            <>
            <div> Choose Student: </div>
                <Typeahead
                    onChange={(selected) => {
                        setSelectedStudent(selected[0])
                    }}
                    options={enrolledStudents}
                    labelKey={option => `${option.userName} (${option.phoneNumber})`}
                />
                </>}
                <br/>
                <br/>
            {statusInfo && <Button onClick={onDownload} variant="outline-primary" className="ms-2" type="submit" style={{ align: 'center' }}>
                Download
            </Button>}
            {statusInfo && <Button onClick={onDelete} variant="outline-primary" className="ms-2" type="submit" style={{ align: 'center' }}>
                Delete
            </Button>}
            <br/>
            <br/>
            {courseStatus && <div><strong>Status:</strong> {courseStatus}</div>}
            {(totalAssignmentScore !=null) && <div> <strong>Total Assignment Score:</strong> {totalAssignmentScore}</div>}
            {(totalQuizScore != null) && <div><strong>Total Quiz Score:</strong> {totalQuizScore}</div>}
            {(totalWatchedTime != null) && <div><strong>Total Watch Time:</strong> {totalWatchedTime} seconds</div>}
            {statusInfo && <StatusReport statusInfo={statusInfo}></StatusReport>}
            {deleted && <div>Enrollment deleted successfully</div>}
        </ProfileLayout>
    </>)
}

export default StudentReport