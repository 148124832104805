// import node module libraries
import React, {Fragment, useEffect, useState} from 'react';
import {Col, Row, Card, Tab, Breadcrumb, Button, Offcanvas} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {clearErrorState, getUsersRequest} from "../../../actions/adminUserActions";
import InstructorsListItems from "./InstructorsListItems";
import OffcanvasCreateUserForm from "./OffcanvasCreateUserForm";
import ProfileLayout from "../../instructor/ProfileLayout";

const Instructor = () => {
	const dispatch = useDispatch();
	const instructor = useSelector(state => state.adminUser.users);

	const [show, setShow] = useState(false);
	const handleClose = () => {
		setShow(false);
		dispatch(clearErrorState());
		dispatch(getUsersRequest());
	}
	const handleShow = () => {
		dispatch(clearErrorState());
		setShow(true);
	}

	useEffect(() => {
		dispatch(getUsersRequest({ role: 'Instructor' }));
	}, []);

	return (
		<ProfileLayout>
			<Tab.Container defaultActiveKey="list">
				<Tab.Content>
					{/*<Tab.Pane eventKey="grid" className="pb-4">*/}
					{/*	/!* students in list view *!/*/}
					{/*	<StudentsGridCard studentsList={users}/>*/}
					{/*	/!* end of students in list view *!/*/}
					{/*</Tab.Pane>*/}
					<h4 className="mb-1 h4 fw-light">
						Instructor <span className="fs-5 text-muted">({instructor.length})</span>
					</h4>
					<Tab.Pane eventKey="list" className="pb-4">
						<Card className="mb-5 ">
							<Card.Body className="p-0">
								{/* students in list view */}
								{instructor.length > 0 ? (<InstructorsListItems instructorList={instructor}/>) : <></>}
								{/* end of students in list view */}
							</Card.Body>
						</Card>
					</Tab.Pane>
				</Tab.Content>
			</Tab.Container>
		</ProfileLayout>
	);
};
export default Instructor;
