import { call, put, takeEvery, select } from 'redux-saga/effects';
import { createCustomError } from '../errors';
import {createUserRequest, createUserFailure, createUserSuccess, getUsersFailure, 
    getUsersRequest, getUsersSuccess, getEventsRequest, getEventsRequestFailure, 
    getEventsRequestSuccess, getUserRequest, getUserSuccess, getUserFailure, 
    updateUserRequest, updateUserSuccess, updateUserFailure, deleteUserRequest, 
    deleteUserSuccess, deleteUserFailure} from "../actions/adminUserActions";
import {createUser, getEvents, getUsers, getUser, updateUser, deleteUser} from "../services/admin-user.service";
import { toast } from 'react-toastify';

function* getUsersHandler(params) {
    // const { phoneNumber } = params.payload;
    const { accessToken } = yield select((state) => state.user);

    try {
        const getUsersResponse = yield call(getUsers, params.payload, accessToken);

        if (getUsersResponse.errorCode) {
            yield put(getUsersFailure(createCustomError(getUsersResponse.message)));
        }
        else {
            yield put(getUsersSuccess(getUsersResponse));
        }
    } catch (error) {
        yield put(getUsersFailure(createCustomError(error)));
    }
}

function* createUserHandler(params) {
    const { accessToken } = yield select((state) => state.user);

    try {
        const createUserResponse = yield call(createUser, params.payload.user, accessToken);

        if (createUserResponse.errorCode) {
            // yield put(createUserFailure(createCustomError(createUserResponse.message)));
            toast.error(createUserResponse.message);
        }
        else {
            // yield put(createUserSuccess(createUserResponse));
            toast.success(createUserResponse.message);
            params.payload.history.push(`/user/${params.payload.user.role.toLowerCase()}`);
        }
    } catch (error) {
        yield put(createUserFailure(createCustomError(error)));
    }
}

function* getEventsHandler(params) {
    // const { phoneNumber } = params.payload;
   

    const { accessToken } = yield select((state) => state.user);
    const {phoneNumber} = yield select((state) => state.user);

    try {
        const getEventsResponse = yield call(getEvents, {phoneNumber}, accessToken);

            yield put(getEventsRequestSuccess(getEventsResponse));
    } catch (error) {
        yield put(getEventsRequestFailure(createCustomError(error)));
    }
}

function* getUserHandler(params) {
    const { accessToken } = yield select((state) => state.user);

    try {
        const getUserResponse = yield call(getUser, params.payload, accessToken);

        if (getUserResponse.errorCode) {
            toast.error('Error in fetching users');
            yield put(getUserFailure(createCustomError(getUserResponse.message)));
        }
        else {
            yield put(getUserSuccess(getUserResponse));
        }
    } catch (error) {
        toast.error('Error in fetching users');
        yield put(getUserFailure(createCustomError(error)));
    }
}

function* updateUserHandler(params) {
    const { accessToken } = yield select((state) => state.user);

    try {
        const updateUserResponse = yield call(updateUser, params.payload, accessToken);

        if (updateUserResponse.errorCode) {
            toast.error(updateUserResponse.message);
            // yield put(updateUserFailure(createCustomError(updateUserResponse.message)));
        }
        else {
            toast.success('User updated successfully');
            params.payload.history.push(`/user/${params.payload.role.toLowerCase()}`);
            // yield put(updateUserSuccess(updateUserResponse));
        }
    } catch (error) {
        yield put(updateUserFailure(createCustomError(error)));
    }
}

function* deleteUserHandler(params) {
    const { accessToken } = yield select((state) => state.user);

    try {
        const deleteUserResponse = yield call(deleteUser, params.payload, accessToken);

        if (deleteUserResponse.errorCode) {
            toast.error(deleteUserResponse.message);
        }
        else {
            yield put(deleteUserSuccess(params.payload));
        }
    } catch (error) {
        toast.error(createCustomError(error));
    }
}


function* adminUserSaga() {
    yield takeEvery(getUsersRequest.type, getUsersHandler);
    yield takeEvery(createUserRequest.type, createUserHandler);
    yield takeEvery(getEventsRequest.type, getEventsHandler);
    yield takeEvery(getUserRequest.type, getUserHandler);
    yield takeEvery(updateUserRequest.type, updateUserHandler);
    yield takeEvery(deleteUserRequest.type, deleteUserHandler);
}

export default [adminUserSaga];
