import { Fragment, useMemo } from 'react';
import {
 Table
} from 'react-bootstrap';
import {
	useFilters,
	useGlobalFilter,
	usePagination, useTable
} from 'react-table';
import Pagination from 'components/elements/advance-table/Pagination';


const StatusReport = ({ statusInfo }) => {


	const columns = useMemo(
		() => [
			{ accessor: 'id', Header: 'ID', show: false },
			{
				accessor: 'name',
				Header: 'NAME',
				Cell: ({ value }) => {
					return (
						<div className="d-flex align-items-center">
							<h5 className="mb-0">{value}</h5>
						</div>
					);
				}
			},
			{
				accessor: 'type',
				Header: 'TYPE',
				Cell: ({ value }) => {
					return (
						<div className="d-flex align-items-center">
							<h5 className="mb-0">{value.toUpperCase()}</h5>
						</div>
					);
				}
			},
			{
				accessor: 'status',
				Header: 'STATUS',
				Cell: ({ value }) => {
					return (
						<div className="d-flex align-items-center">
							<h5 className="mb-0">{value.toString()}</h5>
						</div>
					);
				}
			},
			{
				accessor: 'score',
				Header: 'SCORE',
				Cell: ({ value }) => {
					return (
						<div className="d-flex align-items-center">
							<h5 className="mb-0">{value}</h5>
						</div>
					);
				}
			}
		],
		[]
	);

	const data = useMemo(() => statusInfo, [statusInfo]);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		rows
	} = useTable(
		{
			columns,
			data,
			initialState: {
				hiddenColumns: columns.map((column) => {
					if (column.show === false) return column.accessor || column.id;
					else return false;
				})
			}
		},
		useFilters,
		useGlobalFilter
	);


	return (
		<Fragment>

			<div className="table-responsive ">
				<Table {...getTableProps()} className="text-nowrap">
					<thead className="table-light">
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps()}>
										{column.render('Header')}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map((row) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell) => {
										return (
											<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</Table>
			</div>

			{/* Pagination @ Footer */}
		</Fragment>
	);
};

export default StatusReport;
