
export const CoursesDashboardMenu = [
    {
        id: 1,
        title: 'All Courses',
        link: '/courses/all-courses',
        icon: 'book'
    },
    {
        id: 2,
        title: 'All Assignments',
        link: '/courses/assignments',
        icon: 'book-open'
    }
];

export const UserDashboardMenu = [
    {
        id: 1,
        title: 'Create User',
        link: '/user/create-user',
        icon: 'user'
    },
    {
        id: 2,
        title: 'Admin',
        link: '/user/admin',
        icon: 'power'
    },
    {
        id: 3,
        title: 'Instructor',
        link: '/user/instructor',
        icon: 'bell'
    },
    {
        id: 4,
        title: 'Author',
        link: '/user/author',
        icon: 'book-open'
    },
    {
        id: 5,
        title: 'Student',
        link: '/user/student',
        icon: 'book'
    }
];

export const PaymentManagementMenu = [
    {
        id: 1,
        title: 'Enroll Course',
        link: '/courses/enroll',
        icon: 'book'
    },
    {
        id: 2,
        title: 'All Transactions',
        link: '/courses/transactions',
        icon: 'dollar-sign'
    }
];

export const ShipmentManagementMenu = [
    {
        id: 1,
        title: 'All Shipments',
        link: '/courses/shipments',
        icon: 'dollar-sign'
    }];    

export const QuestionManagementMenu = [
    {
        id: 1,
        title: 'Add Questions',
        link: '/quiz/add-quiz',
        icon: 'edit'
    },
    {
        id: 2,
        title: 'List Questions',
        link: '/quiz/list-quiz',
        icon: 'list'
    }];

export const EventManagementsMenu = [
    {
        id: 1,
        title: 'Create Event',
        link: '/event/create-event',
        icon: 'calendar'
    },
    {
        id: 2,
        title: 'Event List',
        link: '/event/list',
        icon: 'list'
    }];

export const ReportMenu = [
    {
        id: 1,
        title: 'Student Report',
        link: '/report/student',
        icon: 'user'
    },
    {
        id: 2,
        title: 'Sales Report',
        link: '/report/sales',
        icon: 'dollar-sign'
    },
];


export const BannersMenu = [
    
    {
        id: 1,
        title: 'Upload Banner',
        link: '/courses/upload-banner',
        icon: 'shopping-bag'
    }];

export const AdminDashboardMenu = [
    {
        id: 1,
        title: 'Courses Dashboard',
        children: CoursesDashboardMenu
    },
    {
        id: 2,
        title: 'User Dashboard',
        children: UserDashboardMenu
    },
    {
        id: 3,
        title: 'Payment Management',
        children: PaymentManagementMenu
    },
    {
        id: 4,
        title: 'Shipment Management',
        children: ShipmentManagementMenu
    },
    {
        id: 5,
        title: 'Question Management',
        children: QuestionManagementMenu
    },
    {
        id: 6,
        title: 'Event Managements',
        children: EventManagementsMenu
    },
    {
        id: 7,
        title: 'Banners',
        children: BannersMenu
    },
    {
        id: 6,
        title: 'Reports',
        children: ReportMenu
    }
];

export default AdminDashboardMenu;
