const DEFAULT_ERROR_MESSAGE = 'Sorry, something went wrong. Please try again.';
const NO_NETWORK_ERROR = 'Please check your network connectivity';

const errorCodeMessageMap = {
    'user_not_found': 'Please verify your user details.',
    '403': 'Please verify your login details',
    '422': 'This is not a credential. Please check and try again.',
    '404': 'This is not a credential. Please check and try again.'
};

const errorMessage = statusCode => {
    if (!navigator.onLine) return NO_NETWORK_ERROR;

    return statusCode in errorCodeMessageMap
        ? errorCodeMessageMap[statusCode]
        : DEFAULT_ERROR_MESSAGE;
};

export const createCustomError = error => {
    return {
        message: error
    };
};

export const createError = statusCode => {
    return {
        code: statusCode,
        message: errorMessage(statusCode),
    };
};
