import { createAction } from '@reduxjs/toolkit';

export const getUsersRequest = createAction('GET_USERS_REQUEST');
export const getUsersSuccess = createAction('GET_USERS_SUCCESS');
export const getUsersFailure = createAction('GET_USERS_FAILURE');

export const createUserRequest = createAction('CREATE_USER_REQUEST');
export const createUserSuccess = createAction('CREATE_USER_SUCCESS');
export const createUserFailure = createAction('CREATE_USER_FAILURE');

export const updateUserRequest = createAction('UPDATE_USER_REQUEST');
export const updateUserSuccess = createAction('UPDATE_USER_SUCCESS');
export const updateUserFailure = createAction('UPDATE_USER_FAILURE');

export const deleteUserRequest = createAction('DELETE_USER_REQUEST');
export const deleteUserSuccess = createAction('DELETE_USER_SUCCESS');
export const deleteUserFailure = createAction('DELETE_USER_FAILURE');

export const getUserRequest = createAction('GET_USER_REQUEST');
export const getUserSuccess = createAction('GET_USER_SUCCESS');
export const getUserFailure = createAction('GET_USER_FAILURE');

export const resetUser = createAction('RESET_USER');

export const resetMessage = createAction('RESET_MESSAGE');

export const clearErrorState = createAction('CLEAR_ERROR_STATE');

export const getEventsRequest = createAction('GET_EVENTS_REQUEST');
export const getEventsRequestSuccess = createAction('GET_EVENTS_SUCCESS');
export const getEventsRequestFailure = createAction('GET_EVENTS_FAILURE');