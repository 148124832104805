import {useEffect, useRef} from "react";
import {useParams, useHistory} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {Col, Row, Form, Button, Alert} from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';

import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';

import ImageUploader from 'components/common/images/ImageUploader';
import {createUserRequest, getUserRequest, resetUser, updateUserRequest, resetMessage} from "../../../../actions/adminUserActions";
import RichTextEditor from 'components/elements/editor/RichTextEditor';
import FormPhone from "components/elements/bootstrap/forms/FormPhone";
import 'assets/scss/custom/student.scss';
import countryList from '../../../../data/countries.json';
import stateList from '../../../../data/states.json'
const CreateUserForm = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const editUser = useSelector(state => state.adminUser.editUser);
    const richTxtEditorRef = useRef(null);
    const history = useHistory();

    useEffect(() => {
        const userId = params.userId;
        if (userId) {
            dispatch(getUserRequest({ userId }));
        } else {
            dispatch(resetUser());
        }
    }, [params]);

    const roles = [
		{value: '', label:'Choose role', disabled: true},
        {value: 'Admin', label: 'Administrator', disabled: false},
        {value: 'Instructor', label: 'Instructor', disabled: false},
        {value: 'Student', label: 'Student', disabled: false},
        {value: 'Author', label: 'Author', disabled: false}
    ];

    const {
        isCreateUserError,
        errorMessage,
        isUserCreated,
		successMessage
    } = useSelector(state => state.adminUser)

    useEffect(() => {
        setTimeout(() => {
            dispatch(resetMessage());
        }, 5000);
    }, [successMessage, errorMessage])

    useEffect(() => {
        if (editUser?.description && richTxtEditorRef.current)
            richTxtEditorRef.current.setEditorState(EditorState.createWithContent(stateFromHTML(editUser.description)));
    }, [editUser?.id]);
    
    const onSubmit = user => {
        const address = JSON.stringify({
            "address1": user.address1,
            "address2": user.address2,
            "city": user.city,
            "state": user.state,
            "pincode": user.pincode,
            "country": user.country
        })
        const payload = {
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            description: user.description,
            instituteName: user.instituteName,
            qualification: user.qualification,
            badgeLogo: user.badgeLogo,
            profilePicture: user.profilePicture,
            address: user.role == "Student" ? address : null
        }
        if (editUser === null) {
            payload.phoneNumber = user.phoneNumber;
            payload.role = user.role;

            dispatch(createUserRequest({ user: payload, history }));
        } else {
            payload.status = user.status;
            dispatch(updateUserRequest({ user: payload, userId: editUser.id, role: editUser.role, history }));
        }
    }

    const roleItem = (role, index) => {
        return (
            <option key={index} value={role.value} disabled={role.disabled}>{role.label}</option>
        );
    }

    // useEffect(() => {
    // 	if(isUserCreated) {
    // 		onClick();
    // 	}
    // }, [isUserCreated]);

    const schema = yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        description: yup.string(),
        role: yup.string().required(),
        email: yup.string().email().required(),
        phoneNumber: yup.string().required(),
        status: editUser !== null ? yup.string().required() : yup.string(),
        instituteName: yup.string(),
        qualification: yup.string(),
        profilePicture: yup.string(),
        badgeLogo: yup.string(),
        address1: yup
            .string()
            .when("role",{
                is: "Student",
                then: yup.string().required()
            }),
        address2: yup
            .string()
            .when("role",{
                is: "Student",
                then: yup.string().required()
            }),
        city: yup
            .string()
            .when("role",{
                is: "Student",
                then: yup.string().required()
            }),
        state: yup
            .string()
            .when("role",{
                is: "Student",
                then: yup.string().required()
            }),
        pincode: yup.string()
            .matches(/^[0-9]+$/, 'Enter valid pincode')
            .when("role",{
                is: "Student",
                then: yup.string().required()
            }),
        country: yup
            .string()
            .when("role",{
                is: "Student",
                then: yup.string().required()
            }),
	});

    return (
        <Formik
			enableReinitialize 
			validationSchema={schema}
			onSubmit={onSubmit}
			initialValues={{
				firstName: editUser?.firstName || '',
                lastName: editUser?.lastName || '',
                description: editUser?.description || '',
                status: editUser?.status || '',
                role: editUser?.role || '',
                email: editUser?.email || '',
                phoneNumber: editUser?.phoneNumber || '',
                instituteName: editUser?.instituteName || '',
                qualification: editUser?.qualification || '',
                profilePicture: editUser?.profilePicture || '',
                badgeLogo: editUser?.badgeLogo ||  '',
                address1: editUser?.address ? JSON.parse(editUser.address).address1 : '',
                address2: editUser?.address ? JSON.parse(editUser.address).address2 : '',
                city: editUser?.address ? JSON.parse(editUser.address).city : '',
                state: editUser?.address ? JSON.parse(editUser.address).state : '',
                pincode: editUser?.address ? JSON.parse(editUser.address).pincode : '',
                country: editUser?.address ? JSON.parse(editUser.address).country : '',
			}}
		>
            {({
				handleSubmit,
				handleChange,
				handleBlur,
				values,
				touched,
				isValid,
				errors,
                resetForm
			}) => (
                <Form noValidate onSubmit={(e) => { e.preventDefault(); handleSubmit(e); }}>
                    <Row>
                        {/* User's Name */}
                        <Col xs={6} className="mb-3">
                            <Form.Group>
                                <Form.Label>
                                    First Name <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter first name"
                                    id="firstName"
									name="firstName"
									value={values.firstName}
									onChange={handleChange}
									onBlur={handleBlur}
									isInvalid={touched.firstName && !!errors.firstName}
                                />
                                <Form.Control.Feedback type="invalid">
									First name is required
								</Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col xs={6} className="mb-3">
                            <Form.Group>
                                <Form.Label>
                                    Last Name <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter last name"
                                    id="lastName"
									name="lastName"
									value={values.lastName}
									onChange={handleChange}
									onBlur={handleBlur}
									isInvalid={touched.lastName && !!errors.lastName}
                                />
                                <Form.Control.Feedback type="invalid">
									Last name is required
								</Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col xs={12} className="mb-3">
                            <Form.Group>
                                <Form.Label>
                                    Description
                                </Form.Label>
                                <RichTextEditor
									value={values.description}
									id="description"
									name="description"
									onChange={handleChange}
									onBlur={handleBlur}
                                    test={"test"}
									isInValid={touched.description && !!errors.description}
                                    editorRef={ref => {
                                        richTxtEditorRef.current = ref;
                                    }}
								/>
                            </Form.Group>
                        </Col>

                        <Col xs={6} className="mb-3">
                            <Form.Group>
                                <Form.Label>
                                    Institution Name
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter institution name"
                                    id="instituteName"
									name="instituteName"
									value={values.instituteName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={6} className="mb-3">
                            <Form.Group>
                                <Form.Label>
                                    Qualification
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter qualification"
                                    id="qualification"
									name="qualification"
									value={values.qualification}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Form.Group>
                        </Col>

                        <Col xs={12} className="mb-3">
                            <Form.Group>
                                <Form.Label>
                                    E-mail <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Enter e-mail"
                                    id="email"
									name="email"
									value={values.email}
									onChange={handleChange}
									onBlur={handleBlur}
									isInvalid={touched.email && !!errors.email}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
								</Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col xs={12} className="mb-3 create-user">
                            <Form.Group>
                                <Form.Label>
                                    Phone number <span className="text-danger">*</span>
                                </Form.Label>
                                {/*<Form.Control*/}
                                {/*	type="tel"*/}
                                {/*	placeholder="Enter phone number"*/}
                                {/*	required*/}
                                {/*/>*/}
                                <FormPhone
                                    name="phoneNumber"
                                    value={values.phoneNumber}
                                    defaultCountry="IN"
                                    id="phone-input"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    required
                                    readOnly={editUser !== null}
                                    isInValid={touched.phoneNumber && !!errors.phoneNumber}
                                />
                                <Form.Control.Feedback type="invalid">
                                    Phone number is required
								</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        {
                            editUser !== null && (
                                <Form.Group className="mb-3" controlId="formedituserstatus">
									<Form.Label>Status*</Form.Label><br />
									<Form.Check
										inline
										type="radio"
										label="Active" 
										name="status" 
										onChange={() => {
											handleChange({ target: { name: 'status', value: 'Active' } })
										}}
										onBlur={() => {
											handleBlur({ target: { name: 'status', value: 'Active' } })
										}}
										checked={values.status === 'Active'}
									/>
									<Form.Check
										inline
										type="radio"
										label="InActive" 
										name="status" 
										onChange={() => {
											handleChange({ target: { name: 'status', value: 'InActive' } })
										}}
										onBlur={() => {
											handleBlur({ target: { name: 'status', value: 'InActive' } })
										}}
										checked={values.status === 'InActive'}
									/>
                                    <Form.Control.Feedback type="invalid">
                                        Status is required
                                    </Form.Control.Feedback>
								</Form.Group>
                            )
                        }
                        {/* Set Priority */}
                        <Col md={6} xs={12} className="mb-3">
                            <Form.Group>
                                <Form.Label>Role <span className="text-danger">*</span></Form.Label>
                                <Form.Control
                                    className="form-control-role"
                                    as="select"
                                    placeholder="Role"
                                    id="role"
									name="role"
									value={values.role}
									onChange={handleChange}
									onBlur={handleBlur}
									isInvalid={touched.role && !!errors.role}
                                    disabled={editUser !== null}
                                >
                                    {roles.map((role, index) =>
                                        roleItem(role, index)
                                    )}
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    Role is required
								</Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        {
                            values.role == "Student" && 
                            <Col xs={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label>
                                        Address 1 <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter first address line"
                                        id="address1"
                                        name="address1"
                                        value={values.address1}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.address1 && !!errors.address1}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Address 1 is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        }
                        {
                            values.role == "Student" && 
                            <Col xs={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label>
                                        Address 2 <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter second address line"
                                        id="address2"
                                        name="address2"
                                        value={values.address2}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.address2 && !!errors.address2}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Address 2 is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        }
                        {
                            values.role == "Student" && 
                            <Col xs={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label>
                                        City <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="city"
                                        id="city"
                                        name="city"
                                        value={values.city}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.city && !!errors.city}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        City is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        }
                        {
                            values.role == "Student" && 
                            <Col xs={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label>
                                        State <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="state"
                                        id="state"
                                        name="state"
                                        value={values.state}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.state && !!errors.state}
                                        list="stateData"
                                    />
                                    <datalist id="stateData">
                                        {stateList.map((item, key) =>
                                            <option key={key} value={item.name} />
                                        )}
                                    </datalist>
                                    <Form.Control.Feedback type="invalid">
                                        State is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        }
                        {
                            values.role == "Student" &&
                            <Col xs={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label>
                                        Pincode <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="pincode"
                                        id="pincode"
                                        name="pincode"
                                        value={values.pincode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.pincode && !!errors.pincode}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Pincode is required
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        }
                        {
                            values.role == "Student" && 
                            <Col xs={6} className="mb-3">
                                <Form.Group>
                                    <Form.Label>
                                        Country <span className="text-danger">*</span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="country"
                                        id="country"
                                        name="country"
                                        value={values.country}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.country && !!errors.country}
                                        list="countryData"
                                    />
                                    <datalist id="countryData">
                                        {countryList.map((item, key) =>
                                            <option key={key} value={item.name} />
                                        )}
                                    </datalist>
                                    <Form.Control.Feedback type="invalid">
                                        Country required
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        }

                        <Col xs={12} className="mb-3">
                            <Form.Label>Profile Picture (150x150 pixels)</Form.Label>
                                <Form.Group controlId="formProfilePicture" className="mb-1 input-group">
                                    <ImageUploader
                                        id="profilePicture"
                                        type="file"
                                        className="form-control"
                                        name="profilePicture"
                                        accept="image/*"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        category="users"
                                    />
                            </Form.Group>
                        </Col>

                        <Col xs={12} className="mb-3">
                            <Form.Label>Badge Logo</Form.Label>
                                <Form.Group controlId="formBadgeLogo" className="mb-1 input-group">
                                    <ImageUploader
                                        id="badgeLogo"
                                        type="file"
                                        className="form-control"
                                        name="badgeLogo"
                                        accept="image/*"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        category="users"
                                    />
                            </Form.Group>
                        </Col>

                        {/* User logo */}
                        {/*<Col md={3} xs={12} className="mb-4">*/}
                        {/*	<div>*/}
                        {/*		<h5 className="mb-3">User Logo </h5>*/}
                        {/*		<div className="icon-shape icon-xxl border rounded position-relative">*/}
                        {/*			<span className="position-absolute">*/}
                        {/*				<Image size={25} className="text-muted" />*/}
                        {/*			</span>*/}
                        {/*			<Form.Control*/}
                        {/*				type="file"*/}
                        {/*				className="form-control border-0 opacity-0"*/}
                        {/*			/>*/}
                        {/*		</div>*/}
                        {/*	</div>*/}
                        {/*</Col>*/}

                        {/* Buttons */}
                        <Col xs={12} style={{textAlign: "right"}}>
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                            <Button onClick={resetForm} variant="outline-primary" className="ms-2">
                                Reset
                            </Button>
                        </Col>
                    </Row>
                    {errorMessage && (<Alert variant="danger" className="create-user-error">
                        <Alert.Heading>
                            {errorMessage ?? ''}
                        </Alert.Heading>
                    </Alert>)}

                    {successMessage && (<Alert variant="success" className="create-user-success">
                        <Alert.Heading>
                            {successMessage ?? ''}
                        </Alert.Heading>
                    </Alert>)}
                </Form>
            )}
        </Formik>
    );
};

export default CreateUserForm;
