import { useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { URL } from 'shared/url';

const ImageUploader = ({ name, onChange, onBlur, isInvalid, category }) => {
    const accessToken = useSelector(state => state.user.accessToken);

    const handleChange = async ({ target: { files } }) => {
        if (files.length > 0) {
            const file = files[0];
            const [fileName, ext] = file.name.split('.'); 
            const res = await fetch(URL.uploadCourseAsset, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    fileName: `${fileName}_${new Date().getTime()}.${ext}`,
                    fileType: `image/${ext}`,
                    path: category
                })
            })

            res.json().then(async ({ signedUrl, objectUrl }) => {
               try {
                await fetch(signedUrl, {
                    method: 'PUT',
                    body: file,
                    headers: {
                        "Content-Type": `${file.type}`
                    }
                });

                onChange({ target: { name, value: objectUrl } });
                onBlur({ target: { name } });
               } catch(e) {
                toast.error(e.message);
               }

            })
        }
    }

    return (
        <Fragment>
            <Form.Control
                type="file"
                className="form-control"
                name={name}
                accept="image/*"
                onChange={handleChange}
                isInvalid={isInvalid}
            />
            <Form.Label
                className="input-group-text mb-0"
            >
                Upload
            </Form.Label>
        </Fragment>
    );
};

export default ImageUploader;
