import { getCoursesRequest } from 'actions/courseActions';
import { useState } from 'react';
import { useEffect } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useDispatch, useSelector } from 'react-redux';
import { URL } from '../../../../shared/url'
import { Button } from 'react-bootstrap';
import {  Col, Form, Row } from 'react-bootstrap';
import { utils, writeFile } from 'xlsx';
import ProfileLayout from "../../../instructor/ProfileLayout";
import LineChart from './LineChart';
import * as moment from "moment"

const SalesReport = () => {
    const dispatch = useDispatch();
    const courses = useSelector(state => state.courses.courses);
    const accessToken = useSelector(state => state.user.accessToken);

    useEffect(() => {
        dispatch(getCoursesRequest());
    }, []);



    const [courseSelected, setCourseSelected] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [labels, setLabels] = useState(null);
    const [values, setValues] = useState(null);

    useEffect(() => {
       if (courseSelected == null || startDate == null) return
        fetch(`${URL.getPaymentAnalytics}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({
                courseId: courseSelected.id,
                start_date: moment(startDate,"YYYY-MM-DD").format("MM/DD/YYYY")
            })
        }).then((res) => {
            if (res.status == 200) {
                res.json().then((response) => {
                    setPaymentDetails(response.paymentDetails)
                   setLabels(Object.keys(response.courseEnrollment))
                   setValues(Object.values(response.courseEnrollment))
                })
            }
        })
    },[startDate,courseSelected])

    const onDownload = () => {
        var workbook = utils.book_new();
        const formattedPaymentDetails = paymentDetails.map((details)=>({
            userName: details.userName,
            amount: details.paymentOrder.amount,
            currency: details.paymentOrder.currency,
            purchaseDate : details.paymentOrder.createdAt,
            transactionDate: details.transactedAt,
        }))
        var worksheet = utils.json_to_sheet(formattedPaymentDetails);
        utils.book_append_sheet(workbook, worksheet, "Results");
        writeFile(workbook, 'report.xlsx', { type: 'file' });
    }

    return (<>
        <ProfileLayout>
            <div> Choose course: </div>
            <Typeahead
                onChange={(selected) => {
                    setCourseSelected(selected[0])
                }}
                options={courses}
                labelKey={option => `${option.name} ${option.id}`}

            />
            {courseSelected &&
                <Form>
                    <Row>
                        <Col xs={12} className="mb-3">
                            <Form.Group controlId="formEventTitle">
                                <Form.Label>
                                    Start Date <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    type="date"
                                    placeholder="Enter Start Time"
                                    required
                                    value={startDate}
                                    onChange={e => {setStartDate(e.target.value)}}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Form>
            }
            {paymentDetails && <Button onClick={onDownload} variant="outline-primary" className="ms-2" type="submit" style={{ align: 'center' }}>
                Download
            </Button>}
            {labels && values && <>
                 <h6 align="center"> Date vs Number of enrollments</h6>
                <LineChart labels={labels} value={values}/>
            </>}
        </ProfileLayout>
    </>)
}

export default SalesReport;