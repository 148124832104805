import { Line } from 'react-chartjs-2';
import { ChartData } from 'chart.js';
import { useEffect, useState } from 'react';

import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    LineElement,
    PointElement
} from 'chart.js';




const LineChart = (props) => {

    ChartJS.register(
        PointElement,
        LineElement,
        LinearScale,
        CategoryScale,
        Title,
        Tooltip
    );

    const dataset = {
        datasets: [{
            data: props.value,
            borderColor: ['rgb(54, 162, 235)', '#000000'],
            backgroundColor: "rgb(54, 162, 235)",
            hoverBackgroundColor: 'rgb(54, 162, 235)',
            pointBackgroundColor: "rgb(54, 162, 235)",
            pointBorderColor: "#000000",
            pointBorderWidth: 2,
            tension: 0.5
        }],
       labels: props.labels
    }
    
    return (
        <div className="Linechart">
            <Line
                data={dataset}
            />
        </div>
    );
}

export default LineChart;
