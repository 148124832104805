import {URL} from "../shared/url";
import {toast} from "react-toastify";

export async function uploadToS3(payload, accessToken) {
    const { bannerFile } = payload;
    const [fileName, ext] = bannerFile.name.split('.')
    let url = "";

    const res = await fetch(URL.uploadCourseAsset, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
            fileName: `${fileName}.${ext}`,
            fileType: `image/${ext}`,
            path: 'courses/banner'
        })
    })

    return res.json().then(async ({ signedUrl, objectUrl }) => {
        try {
            await fetch(signedUrl, {
                method: 'PUT',
                body: bannerFile,
                headers: {
                    "Content-Type": `${bannerFile.type}`
                }
            })

            return Promise.resolve(objectUrl);
        } catch(e) {
            toast.error(e.message);
            throw new Error(e.message);
        }
    }).catch(err => Promise.reject(err));
}
