// import node module libraries
import React, {useEffect, useMemo, useState} from 'react';
import {Card, Button, Modal, Form, Spinner, Row, Col, Alert, Image, Table, OverlayTrigger, Tooltip} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import 'assets/scss/custom/student.scss';
import {
    clearUploadBannerError,
    deleteCourseBannerRequest,
    getCourseBannerRequest, updateCourseBannerRequest,
    uploadCourseBannerRequest
} from "../../../actions/courseActions";
import ProfileLayout from "../../instructor/ProfileLayout";
import {Check, Image as ImageIcon, Trash, X} from "react-feather";
import Pagination from "../../elements/advance-table/Pagination";
import {useFilters, useGlobalFilter, usePagination, useTable} from "react-table";
import useConfirm from 'hooks/useConfirm';

const UploadBanner = () => {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState();
    const { isBannerCreated, isCreateBannerError, errorMessage, banners, getCourseBannerError, isBannerUpdated, isBannerDeleted } = useSelector(state => state.courses);
    const [ previewImage, setPreviewImage ] = useState(null);

    const {confirm} = useConfirm();
    const showConfirm = async (id) => {
        const isConfirmed = await confirm('Are you sure?');
        if (isConfirmed) {
            onDelete(id)
        }
    }

    useEffect(() => {
        dispatch(getCourseBannerRequest());

        if(isBannerCreated || isCreateBannerError || getCourseBannerError) {
            dispatch(clearUploadBannerError());
        }
    }, []);

    const handleChange = event => {
        setFormData(formData => ({
            ...formData,
            [event.target.name]: (event.target.files?.length > 0 ? event.target.files[0] : null)
            || event.target.value
        }));
    };

    const onSubmit = () => {
        // console.log("Called");
    }

    const onUpdate = (banner, status) => {
        let params = {
            title: banner.title,
            createdAt: banner.createdAt,
            updatedAt: new Date(),
            id: banner.id,
            status: status,
            description: banner.description,
            banner: banner.banner
        }

        dispatch(updateCourseBannerRequest(params));
    }

    const onDelete = (id) => {
        let params = {id}
        dispatch(deleteCourseBannerRequest(params));
    }

    const columns = useMemo(
        () => [
            // { accessor: 'id', Header: 'ID', show: false },
            {
                accessor: 'title',
                Header: 'TITLE',
                Cell: ({ value, row }) => {
                    return (
                        <div className="d-flex align-items-center">
                            <h5 className="mb-0">{value}</h5>
                        </div>
                    );
                }
            },
            {
                accessor: 'description',
                Header: 'DESCRIPTION',
                width: '10vw',
                Cell: ({ value, row }) => {
                    return (
                        <div className="d-flex align-items-center" style={{ width: '10vw'}}>
                            <h5 className="mb-1" >{value}</h5>
                        </div>
                    );
                }
            },
            {
                accessor: 'banner',
                Header: 'BANNER',
                Cell: ({ value, row }) => {
                    return (
                        <div className="d-flex align-items-center">
                            {/*<h5 className="mb-0">{value}</h5>*/}
                            <div className='mb-3'>
                                <Button variant="outline-white" className="me-1 mb-1" onClick={() => setPreviewImage(value)}>
                                    <ImageIcon size="15px" className="dropdown-item-icon me-1" />
                                    Preview
                                </Button>
                            </div>
                        </div>
                    );
                }
            },
            {
                accessor: 'status',
                Header: 'STATUS',
                Cell: ({ value, row }) => {
                    return (
                        <div className="d-flex align-items-center">
                            <h5 className="mb-0">{value.toUpperCase()}</h5>
                        </div>
                    );
                }
            },
            // {
            //     accessor: 'createdAt',
            //     Header: 'CREATED ON',
            //     Cell: ({ value, row }) => {
            //         return (
            //             <div className="d-flex align-items-center">
            //                 <h5 className="mb-0">{moment(value).format("DD/MM/YYYY")}</h5>
            //             </div>
            //         );
            //     }
            // },
            // {
            //     accessor: 'updatedAt',
            //     Header: 'UPDATED ON',
            //     Cell: ({ value, row }) => {
            //         return (
            //             <div className="d-flex align-items-center">
            //                 <h5 className="mb-0">{moment(value).format("DD/MM/YYYY")}</h5>
            //             </div>
            //         );
            //     }
            // },
            {
                accessor: 'action',
                Header: 'UPDATE',
                Cell: ({ value, row }) => {
                    if(row.original.status === 'active') {
                        return (
                            <OverlayTrigger
            					key="top"
            					placement="top"
            					overlay={<Tooltip id={`tooltip-top`}>Disable</Tooltip>}
            				>
                                <X color="#FF0000" style={{cursor: 'pointer'}} size="18px" className="dropdown-item-icon" onClick={() => onUpdate(row.original, 'disabled')}/>
            				</OverlayTrigger>
                        );
                    }
                    else {
                        return (
                            <OverlayTrigger
            					key="top"
            					placement="top"
            					overlay={<Tooltip id={`tooltip-top`}>Active</Tooltip>}
            				>
                                <Check color="#006400" style={{cursor: 'pointer'}} size="18px" className="dropdown-item-icon" onClick={() => onUpdate(row.original, 'active')}/>
            				</OverlayTrigger>
                        );
                    }
                }
            },
            {
            	accessor: 'id',
            	Header: 'Delete',
            	Cell: ({row}) => {
            		return (
            			<div className="align-middle border-top-0">
            				<OverlayTrigger
            					key="top"
            					placement="top"
            					overlay={<Tooltip id={`tooltip-top`}>Delete</Tooltip>}
            				>
                                <Trash color="#FF0000" style={{cursor: 'pointer'}} size="18px" className="dropdown-item-icon" onClick={() => showConfirm(row.original.id)}/>
            				</OverlayTrigger>
            			</div>
            		);
            	}
            }
        ],
        [banners]
    );

    const data = useMemo(() => banners, [banners]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        nextPage,
        previousPage,
        state,
        gotoPage,
        pageCount,
        prepareRow,
        setGlobalFilter
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageSize: 10,
                hiddenColumns: columns.map((column) => {
                    if (column.show === false) return column.accessor || column.id;
                    else return false;
                })
            }
        },
        useFilters,
        useGlobalFilter,
        usePagination
    );

    const { pageIndex, globalFilter } = state;

    const onClear = () => {
        setFormData(formData => ({
            ...formData,
            title: "", description: "", banner: ""
        }));

        dispatch(clearUploadBannerError());
    }

    const handleSubmit = (e) => {
        // console.log("Handling Submit");
        e.preventDefault();

        let courseBannerPayload = {
            title: formData.formTitle,
            description: formData.formDescription,
            bannerFile: formData.banner,
        }

        dispatch(uploadCourseBannerRequest(courseBannerPayload));
    }

    return (
        <ProfileLayout>
            <Form onSubmit={handleSubmit}>
                <Card className="mb-3  border-0">
                    <Card.Header className="border-bottom px-4 py-3">
                        <h4 className="mb-0">Upload Banner</h4>
                    </Card.Header>
                    <Card.Body>
                        <Col xs={12} className="mb-3">
                            <Form.Group controlId="formTitle">
                                <Form.Label>
                                    Title <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    name="formTitle"
                                    type="text"
                                    placeholder="Enter banner title"
                                    // required
                                    onChange={handleChange}
                                    // onChange={e => handleEmailChange(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} className="mb-3">
                            <Form.Group controlId="formDescription">
                                <Form.Label>
                                    Description <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    name="formDescription"
                                    type="text"
                                    placeholder="Enter banner description"
                                    // required
                                    onChange={handleChange}
                                    // onChange={e => handleEmailChange(e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} className="mb-3">
                            <Form.Group controlId="formFileUpload">
                                <Form.Label>
                                    Choose courses banner to upload (1650x250 pixels) <span className="text-danger">*</span>
                                </Form.Label>
                                <Form.Control
                                    name="banner"
                                    type="file"
                                    accept="image/*"
                                    onChange={handleChange}
                                    // required
                                />
                            </Form.Group>
                            {/*{*/}
                            {/*    formData?.banner && (*/}
                            {/*        <div className='mb-3'>*/}
                            {/*            <Button variant="outline-white" className="me-1 mb-1" style={{ marginTop: '10px', float: 'right'}} onClick={() => setPreviewImage(formData.banner)}>*/}
                            {/*                <ImageIcon size="15px" className="dropdown-item-icon me-1" />*/}
                            {/*                Preview*/}
                            {/*            </Button>*/}
                            {/*        </div>*/}
                            {/*    )*/}
                            {/*}*/}
                        </Col>
                        <Row>
                            <Form.Text className="text-muted">
                                Upload image must meet our banner image
                                quality standards. Important guidelines: 1650x250
                                pixels; .jpg, .jpeg,. gif, or .png. no text on the image.
                            </Form.Text>
                        </Row>
                        <Col xs={12} className="banner-button-group">
                            <Button variant="primary" type="submit" onClick={onSubmit}>
                                Upload
                            </Button>
                            <Button onClick={onClear} variant="outline-primary" className="ms-2">
                                Clear
                            </Button>
                        </Col>
                        <Col xs={12}>
                            {(isCreateBannerError || getCourseBannerError) && (<Alert variant="danger" className="create-user-error">
                                <Alert.Heading>
                                    {errorMessage ?? ''}
                                </Alert.Heading>
                            </Alert>)}

                            {isBannerCreated && (<Alert variant="success" className="create-user-success">
                                <Alert.Heading>
                                    {'Banner uploaded successfully'}
                                </Alert.Heading>
                            </Alert>)}

                            {isBannerUpdated && (<Alert variant="success" className="create-user-success">
                                <Alert.Heading>
                                    {'Banner updated successfully'}
                                </Alert.Heading>
                            </Alert>)}

                            {isBannerDeleted && (<Alert variant="success" className="create-user-success">
                                <Alert.Heading>
                                    {'Banner deleted successfully'}
                                </Alert.Heading>
                            </Alert>)}
                        </Col>
                        <Col xs={12}>
                            <Card className="mb-5 ">
                                <Card.Body className="p-0">
                                    <div className="table-responsive ">
                                        <Table {...getTableProps()} className="text-nowrap">
                                            <thead className="table-light">
                                            {headerGroups.map((headerGroup) => (
                                                <tr {...headerGroup.getHeaderGroupProps()}>
                                                    {headerGroup.headers.map((column) => (
                                                        <th {...column.getHeaderProps()}>
                                                            {column.render('Header')}
                                                        </th>
                                                    ))}
                                                </tr>
                                            ))}
                                            </thead>
                                            <tbody {...getTableBodyProps()}>
                                            {page.map((row) => {
                                                prepareRow(row);
                                                return (
                                                    <tr {...row.getRowProps()}>
                                                        {row.cells.map((cell) => {
                                                            return (
                                                                <td {...cell.getCellProps({
                                                                    style: {
                                                                        whiteSpace: 'pre-wrap'
                                                                    }
                                                                })}>{cell.render('Cell')}</td>
                                                            );
                                                        })}
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </Table>
                                    </div>

                                    {/* Pagination @ Footer */}
                                    <Pagination
                                        previousPage={previousPage}
                                        pageCount={pageCount}
                                        pageIndex={pageIndex}
                                        gotoPage={gotoPage}
                                        nextPage={nextPage}
                                    />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Card.Body>
                </Card>

                <Modal
                    show={!!previewImage}
                    onHide={() => { setPreviewImage(null) }}
                    size="lg"
                >
                    <Modal.Header closeButton/>
                    <Modal.Body className='pt-0'>
                        <Image src={previewImage} height={500} width="100%" />
                    </Modal.Body>
                </Modal>
            </Form>
        </ProfileLayout>
    );
};

export default UploadBanner;
