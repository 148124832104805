import { createAction } from '@reduxjs/toolkit';

export const loginRequest = createAction('LOGIN_REQUEST');
export const loginSuccess = createAction('LOGIN_SUCCESS');
export const loginFailure = createAction('LOGIN_FAILURE');

export const sendOTPRequest = createAction('SEND_OTP_REQUEST');
export const sendOTPSuccess = createAction('SEND_OTP_SUCCESS');
export const sendOTPFailure = createAction('SEND_OTP_FAILURE');

export const clearErrorMessages = createAction('CLEAR_ERROR_MESSAGES');
export const resetOTPSentFlag = createAction('RESET_OTP_SENT_FLAG');

export const signUpRequest = createAction('SIGN_UP_REQUEST');
export const signUpSuccess = createAction('SIGN_UP_SUCCESS');
export const signUpFailure = createAction('SIGN_UP_FAILURE');

export const logOut = createAction('LOG_OUT');

export const refreshTokenRequest = createAction('REFRESH_TOKEN_REQUEST');
export const refreshTokenSuccess = createAction('REFRESH_TOKEN_SUCCESS');
