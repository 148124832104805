import React from 'react';
import { Form, Button, Row, Col} from 'react-bootstrap';

import { FormSelect } from 'components/elements/form-select/FormSelect';

const AddQuestion = (props) => {
	const levels = [{value: "Easy", label: "Easy"}, {value: "Medium", label: "Medium"}, {value: "Hard", label: "Hard"}];
	const questionTypes = [{value: 'mcq', label: "mcq"}];
	const {
        questionKey,
        choices, 
        questionText,
        dropDown,
        questionData, 
        onAddChoice, 
        onChangeChoice,
        onRemoveChoice,
        setQuestionText,
        setData,
        onRemoveQuestion
    } = props;

	const dynamicForm = (props) => 
	{
		return (
			<Form.Group key={props.key} className="mb-3" controlId="formBasicEmail">	
                <Row>
                    <Col xs={4}>
                        <Form.Control 
                            type="text" 
                            placeholder="Enter next choice"
                            value= {props.formData.text} 
                            onChange={e => {
                                const inputData = {
                                    text: e.target.value,
                                    isCorrect: props.formData.isCorrect
                                };
                                props.onChangeChoice(inputData, props.questionKey, props.key);
                            }}
                        />
                    </Col>
                    <Col xs={2} className="d-grid">
                        <Button
                            variant="primary" 
                            onClick={() => props.onRemoveChoice(props.questionKey, props.key)}
                        >
                            Remove
                        </Button>
                    </Col>
                    <Col xs={2} className="mt-3">
                        <Form.Check 
                            type="checkbox" 
                            label="isCorrect"
                            checked={props.formData.isCorrect}
                            onChange={e => {
                                const inputData = {
                                    text: props.formData.text,
                                    isCorrect: !props.formData.isCorrect
                                };
                                props.onChangeChoice(inputData, props.questionKey, props.key);
                            }}
                        />
                    </Col>
                </Row>
			</Form.Group>
		);
	}

	return (
		<div key={questionKey}>
            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Question *</Form.Label>
                    <Form.Control 
                        type="text" 
                        placeholder="Enter Question Text" 
                        value={questionText}
                        onChange={e => setQuestionText(questionKey, e.target.value)}
                    />
                </Form.Group>
                <Row>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Question level *</Form.Label>
                            <FormSelect
                                options={levels}
                                id="level"
                                name="level"
                                placeholder="Select level"
                                onChange={e => setData(questionKey, { ...questionData, level: e.target.value })}
                                defaultselected={questionData.level}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Question type *</Form.Label>
                            <FormSelect
                                options={questionTypes}
                                id="type"
                                name="type"
                                placeholder="Select type"
                                onChange={e => setData(questionKey, { ...questionData, type: e.target.value })}
                                defaultselected={questionData.type}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group className="mb-3">
                            <Form.Label>Score *</Form.Label>
                            <Form.Control 
                                type="text" 
                                placeholder="score" 
                                value={questionData.score}
                                onChange={e => setData(questionKey, {...questionData, score: e.target.value})}
                             />
                        </Form.Group>
                    </Col>
                </Row>
            </Form>
            <ol className='mt-3'>
                {choices && choices.map((item, index) => (
                    <li key={`choice-${index}`}>
                        {dynamicForm({formData: item, key: index, questionKey,
                            onRemoveChoice:onRemoveChoice,
                            onChangeChoice:onChangeChoice})}
                    </li>
                ))}
            </ol>
            <div style={{display: "flex"}}>
                <Button 
                    style={{minWidth:"150px", height: "30px", marginTop: "10px", padding:"0px"}}
                    className="btn btn-primary mb-2"
                    type="button"
                    onClick={() => onAddChoice(questionKey)} 
                >
                    Add Choice
                </Button>
                <Button 
                    style={{minWidth:"150px", height: "30px", marginTop: "10px", marginLeft: "10px", padding:"0px"}}
                    className="btn btn-primary mb-2"
                    type="button"
                    hidden={!onRemoveQuestion}
                    onClick={() => onRemoveQuestion(questionKey)} 
                >
                    Remove Question
                </Button>
            </div>
		</div>
	);
};



export default AddQuestion;
