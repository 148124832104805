import slugify from 'slugify';

export const getMpdUrl = url => {
    const splitUrl = url.split('/');
    const fileName = splitUrl[splitUrl.length - 1];
    splitUrl[splitUrl.length - 1] = `${fileName.split('.')[0]}_720.mp4`
    splitUrl.splice(3, 0, 'processed-course');

    return decodeURIComponent(splitUrl.join('/'));
}

export const slugifyString = value => {
    return slugify(value, {
        remove: /[*+~.()'"!:@]/g
    });
}

export function getUrlWithParams(url, req) {
    const paramsObj = {};
    if (req?.role) paramsObj['role'] = req.role;
    if (req?.page) paramsObj['page'] = req.page; // default 1
    if (req?.limit) paramsObj['limit'] = req.limit; // default 10
    if (req?.search) paramsObj['search'] = req.search;
    const params = new URLSearchParams(paramsObj).toString();
    return url + (params ? `?${params}` : "");
}
