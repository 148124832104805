import React from 'react';
import { createPortal } from 'react-dom';
import useConfirm from 'hooks/useConfirm';
import { Modal, Button } from 'react-bootstrap';
import { Trash } from 'react-feather';

const ConfirmDialog = () => {
    const { onConfirm, onCancel, confirmState } = useConfirm();
    const portalElement = document.getElementsByTagName('body')[0];
    const component = confirmState.show ? (
        <div className="portal-overlay delete-confirm-model">
            <div className="confirm-dialog">
                <div className='delete-confirm-model-header'>
                    <div className='modal-title'>
                        <Trash size="36px" className="dropdown-item-icon" />
                        <span>Delete</span>
                    </div>
                </div>
                <div className='delete-confirm-model-body padding-tp'>{confirmState?.text && confirmState.text}</div>
                <div className="confirm-dialog__footer delete-confirm-model-footer">
                    <Button variant="secondary" size="sm" onClick={onCancel} className="margin-rgt">
                        Cancel
                    </Button>
                    <Button variant="primary" size="sm" onClick={onConfirm}>
                        Delete
                    </Button>
                </div>
            </div>
        </div>
    ) : <div></div>;

    return createPortal(component, portalElement);
};
export default ConfirmDialog;
