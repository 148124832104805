import { URL } from '../shared/url';
import { getUrlWithParams } from '../shared/utils';

export async function getUsers(req, accessToken) {
    const url = getUrlWithParams(URL.adminUsers, req);
    const res = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
    })

    if (res.status >= 200 && res.status < 300) {
        return res
            .json()
            .then(resBody => {
                return Promise.resolve(resBody);
            })
            .catch(err => Promise.reject(err));
    } else {
        throw new Error(res.status.toString())
    }
}

export async function createUser(payload, accessToken) {
    const res = await fetch(URL.adminUsers, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(payload)
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function getEvents(req, accessToken) {
    let url = URL.event;

    if (req.phoneNumber) {
        url = `${url}?id=${req.phoneNumber}`;
    }

    const res = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${accessToken}`
        },
    })

    if (res.status == 200) {
        return res.json().then((eventResponse) => {
            
            const response = eventResponse.map((value) => ({
                ...value,
                shortcutmenu: {
                    ...value
                }
            }))
            return Promise.resolve(response)
        }).catch(err => Promise.reject(err))
    }
    return Promise.reject("Request failed")
}

export async function getUser(req, accessToken) {
    const res = await fetch(`${URL.adminUsers}/${req.userId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
    })

    if (res.status >= 200 && res.status < 300) {
        return res
            .json()
            .then(resBody => {
                return Promise.resolve(resBody);
            })
            .catch(err => Promise.reject(err));
    } else {
        throw new Error(res.status.toString())
    }
}

async function updateAuthor({user, userId}, accessToken) {
    const author = {
        name: `${user.firstName} ${user.lastName}`,
        description: user.description,
        institution: user.instituteName,
        badgeLogo: user.badgeLogo,
        profilePicture: user.profilePicture,
    }
    
    await fetch(`${URL.updateAuthor}/${userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(author)
    })
}

export async function updateUser(payload, accessToken) {
    const res = await fetch(`${URL.adminUsers}/${payload.userId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
        body: JSON.stringify(payload.user)
    });

    if (payload.role === 'Author')
        await updateAuthor(payload, accessToken);

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function deleteUser(payload, accessToken) {
    const res = await fetch(`${URL.adminUsers}/${payload.userId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}
