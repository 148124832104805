// import node module libraries
import { useEffect, useState } from 'react';
import { Button, Card, Tab } from 'react-bootstrap';
import { useSelector } from "react-redux";
import ProfileLayout from "../../../instructor/ProfileLayout";
import EventList from './EventList';
import { URL } from '../../../../shared/url';
import { useDispatch } from 'react-redux';
import { getEventsRequest } from 'actions/adminUserActions';
import Modal from 'react-bootstrap/Modal';

const AllEvents = ({ history }) => {
    const dispatch = useDispatch()
    const [deleteConfirmation, setDeleteConfirmation] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const accessToken = useSelector(state => state.user.accessToken);
    const events = useSelector(state => state.adminUser.events);

    useEffect(() => {
        dispatch(getEventsRequest());
    }, []);

    const deleteEvent = (deleteId) => {
        if (deleteId == null) {
            return;
        }
        fetch(URL.event, {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify({
                "id": deleteId
            })
        }).then((res) => {
            if (res.status == 200) {
                dispatch(getEventsRequest());
            }
        })
    }

    const updateEvent = (id) => {
        history.push({
            pathname: '/event/update',
            state: {
                id
            }
        });
    }


    return (
        <ProfileLayout>

            <Tab.Container defaultActiveKey="list">
                <Tab.Content>
                    {/*<Tab.Pane eventKey="grid" className="pb-4">*/}
                    {/*	/!* students in list view *!/*/}
                    {/*	<StudentsGridCard studentsList={users}/>*/}
                    {/*	/!* end of students in list view *!/*/}
                    {/*</Tab.Pane>*/}
                    <h4 className="mb-1 h4 fw-light">
                        Events {events && <span className="fs-5 text-muted">({events.length})</span>}
                    </h4>
                    <Tab.Pane eventKey="list" className="pb-4">
                        <Card className="mb-5 ">
                            <Card.Body className="p-0">
                                {/* students in list view */}
                                {events && events.length > 0 ? (<EventList listItem={events} deleteEvent={deleteEvent} updateEvent={updateEvent} />) : <></>}
                                {/* end of students in list view */}
                            </Card.Body>
                        </Card>
                    </Tab.Pane>
                </Tab.Content>
            </Tab.Container>
        </ProfileLayout>
    );
};
export default AllEvents;
