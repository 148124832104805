// import node module libraries
import PhoneInput from "react-phone-number-input";
import './custom.scss';

const FormPhone = ({ name, value, onChange, onBlur, required, isInValid, readOnly = false }) => {
    function handleChange(value) {
		onChange({ target: { name: name, value } });
	}

	function handleBlur(value) {
		onBlur({ target: { name: name, value } });
	}

    let className = '';

    if (isInValid)
        className += 'form-control is-invalid'
    
    if (readOnly)
        className += ' phone-readonly'

    return (
        <PhoneInput
            readOnly={readOnly}
            className={className}
            value={value}
            defaultCountry="IN"
            id="phone-input"
            onChange={handleChange}
            onBlur={handleBlur}
            required={required}
        />
    );
};

export default FormPhone;
