// import node module libraries
import React, { useState, Fragment, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, Container } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';

// import custom components
import GKStepper from 'components/elements/stepper/GKStepper';

// import sub components ( Steps )
import BasicInformation from './steps/BasicInformation';
import CoursesMedia from './steps/CoursesMedia';
import Curriculum from './steps/Curriculum';
import FAQ from './steps/FAQs';
import Tasks from './steps/Tasks';
import Authors from './steps/Authors';

import { getCourseRequest, resetDraftCourse } from 'actions/courseActions';

const AddNewCourse = () => {
	const { courseId } = useParams();
	const [currentStep, setCurrentStep] = useState(1);
	const { draftCourse } = useSelector(state => state.courses);
	const { role } = useSelector(state => state.user);
	const dispatch = useDispatch();
	const [formData, setFormData] = useState(draftCourse);

	useEffect(() => {
		setFormData(draftCourse)
	}, [draftCourse]);

	useEffect(() => {
		if (courseId) {
			dispatch(getCourseRequest({ courseId }));
		} else {
			dispatch(resetDraftCourse());
		}
	}, []);

	const handleSubmitCourseInfo = data => {
		setFormData(formData => ({
			...formData,
			...data,
			shipmentData: JSON.stringify({length: data.length, breadth: data.breadth, height: data.height, weight: data.weight})
		}));
	};

	const handleChangeCurriculum = data => {
		formData.chapters = data;
		setFormData(formData);
	};

	const handleChangeFAQs = data => {
		formData.faqs = data;
		setFormData(formData);
	};

	const handleChangeTasks = data => {
		formData.tasks = data;
		setFormData(formData);
	};

	const handleChangeAuthors = data => {
		formData.authors = data;
		setFormData(formData);
	};

	const next = () => {
		setCurrentStep(currentStep === steps.length ? 1 : currentStep + 1);
	};
	const previous = () => {
		setCurrentStep(currentStep === 1 ? 1 : currentStep - 1);
	};

	const steps = [
		{
			id: 1,
			title: 'Basic Information',
			content: (
				<BasicInformation
					data={formData}
					handleSubmit={handleSubmitCourseInfo}
					next={next}
				/>
			)
		},
		{
			id: 2,
			title: 'Courses Media',
			content: (
				<CoursesMedia
					data={formData}
					handleSubmit={handleSubmitCourseInfo}
					next={next}
					previous={previous}
				/>
			)
		},
		{
			id: 3,
			title: 'Curriculum',
			content: (
				<Curriculum
					data={formData}
					handleChange={handleChangeCurriculum}
					next={next}
					previous={previous}
				/>
			)
		},
		{
			id: 4,
			title: 'FAQs',
			content: (
				<FAQ
					data={formData}
					handleChange={handleChangeFAQs}
					next={next}
					previous={previous}
				/>
			)
		},
		{
			id: 5,
			title: 'Tasks',
			content: (
				<Tasks
					data={formData}
					handleChange={handleChangeTasks}
					next={next}
					previous={previous}
				/>
			)
		},
		{
			id: 6,
			title: 'Co-Authors',
			content: (
				<Authors
					data={formData}
					handleChange={handleChangeAuthors}
					next={next}
					previous={previous}
				/>
			)
		}
	];

	return (
		<Fragment>
			<div className="py-4 py-lg-6 bg-primary">
				<Container>
					<Row>
						<Col lg={{ span: 10, offset: 1 }} md={12} sm={12}>
							<div className="d-lg-flex align-items-center justify-content-between">
								<div className="mb-4 mb-lg-0">
									<h1 className="text-white mb-1">Add New Course</h1>
									<p className="mb-0 text-white lead">
										Just fill the form and create your courses.
									</p>
								</div>
								<div>
									<Link
										to={role === 'Admin' ? '/courses/all-courses/' : '/instructor/instructor-my-courses/'}
										className="btn btn-white "
									>
										Back to Course
									</Link>{' '}
									{/* <Link
										to="/marketing/instructor/instructor-my-courses/"
										className="btn btn-success "
									>
										Save
									</Link> */}
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</div>
			<GKStepper currentStep={currentStep} steps={steps} />
		</Fragment>
	);
};

export default AddNewCourse;
