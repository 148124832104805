// import node module libraries
import React, { Fragment, useMemo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
	useTable,
	useFilters,
	useGlobalFilter,
	usePagination
} from 'react-table';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { Col, Row, Table, Button } from 'react-bootstrap';
import { Trash, ArrowRightCircle } from 'react-feather';
import '../assignments.scss';
import { saveAs } from 'file-saver';
import { deleteAssignmentsRequest, updateAssignmentsRequest } from 'actions/courseActions';

// import custom components
import GlobalFilter from 'components/elements/advance-table/GlobalFilter';
import Pagination from 'components/elements/advance-table/Pagination';

import moment from "moment/moment";
import useConfirm from 'hooks/useConfirm';

const AssignmentTable = ({ assignments_data }) => {
	// The forwardRef is important!!
	// Dropdown needs access to the DOM node in order to position the Menu

	const {confirm} = useConfirm();
    const showConfirm = async (id) => {
        const isConfirmed = await confirm('Are you sure?');
        if (isConfirmed) {
            onDelete(id)
        }
    }

	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<Link
			to="#"
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		>
			{children}
		</Link>
	));

	const dispatch = useDispatch();

	const downloadFile = async (url) => {
		var fileName = url.substring(url.lastIndexOf('/')+1, url.length);
		fetch(url).then((response) => {
		  response.blob().then((blob) => {
			saveAs(blob, fileName);
		  });
		});
	}

	const columns = useMemo(
		() => [
			{ accessor: 'id', Header: 'ID', show: false },
			{ accessor: 'courseEnrollment.userName', Header: 'USER',
			  Cell: ({ value, row }) => {
			 	return (
			 		<Fragment>
			 			{value}
			 		</Fragment>
			 	);}
			},
			{ accessor: 'courseEnrollment.phoneNumber', Header: 'MOBILE NO',
			  Cell: ({ value, row }) => {
			 	return (
			 		<Fragment>
			 			{value}
			 		</Fragment>
			 	);}
			},
			{ accessor: 'status', Header: 'STATUS',
			  Cell: ({ value, row }) => {
			 	return (
			 		<Fragment>
			 			{value.charAt(0).toUpperCase() + value.slice(1)}
			 		</Fragment>
			 	);}
			},
			{ accessor: 'courseEnrollment.course.name', Header: 'COURSE',
			  Cell: ({ value, row }) => {
			 	return (
			 		<Fragment>
			 			{value}
			 		</Fragment>
			 	);}
			},
            { accessor: 'chapter.name', Header: 'CHAPTER',
			  Cell: ({ value, row }) => {
			 	return (
			 		<Fragment>
			 			{value}
			 		</Fragment>
			 	);}
			},
            { accessor: 'lesson.name', Header: 'LESSON',
			  Cell: ({ value, row }) => {
			 	return (
			 		<Fragment>
			 			{value}
			 		</Fragment>
			 	);}
			},
			{ accessor: 'assignmentUrl', Header: 'FILE',
			  Cell: ({ value, row }) => {
			 	return (
			 		<Fragment>
                        <a onClick={() => downloadFile(value)} style={{textDecoration: 'underline', color: '#754ffe', cursor: 'pointer'}}>Download</a>
			 		</Fragment>
			 	);}
			},
			{ accessor: 'assignmentScore', Header: 'Total GRADE',
			  Cell: ({ value, row }) => {
				const [isEdit, setIsEdit] = useState(false);
				const [assignedScore, setAssignedScore] = useState(value);

				const updateData = (value) => {
					setAssignedScore(value);
				}

				if(row.original.status === 'active')
				{
					return (<Fragment>
						<div style={{display: 'flex', alignItems: 'center'}}>
						{
							!isEdit && <span style={{color: (value == null ? 'red' : '#5C5776'), cursor: 'pointer'}} onClick={() => setIsEdit(true)}>{value ?? '(NOT GRADED)'}</span>
						}
						{
							isEdit && <Form.Control 
											type="text" 
											placeholder="" 
											className='assignmentScoreForm'
											name="assignmentScore"
											onChange={(e) => updateData(e.target.value)} />
						}
						&nbsp;/&nbsp;{row.original.lesson.assignmentScore}
						{
							isEdit && <div className="d-flex align-items-center">
										&nbsp;&nbsp;&nbsp;<ArrowRightCircle color="green" style={{cursor: 'pointer'}} size="18px" className="dropdown-item-icon" onClick={() => {
											onSubmit(row.original.id, assignedScore);
											setIsEdit(false)
										}} />
						 			  </div>
						}
						</div>
					</Fragment>);
				} else {
					return <div></div>;
				}}
			},
			{ accessor: 'createdAt', Header: 'CREATED AT',
			  Cell: ({ value, row }) => {
			 	return (
			 		<Fragment>
			 			{moment(value).format("DD/MM/YYYY HH:mm")}
			 		</Fragment>
			 	);}
			},
			// {
            //     accessor: 'action',
            //     Header: '',
            //     Cell: ({ value, row }) => {
            //         if(row.original.status === 'active') {
            //             return (
            //             <div className="d-flex align-items-center" style={{paddingLeft: '0px'}}>
			// 				{' '}
            //                 <Trash color="#FF0000" style={{cursor: 'pointer'}} size="18px" className="dropdown-item-icon" onClick={() => showConfirm(row.original.id)}/>
            //             </div>
            //             );
            //         }
			// 		else {
			// 			return <div></div>;
			// 		}
					
            //     }
            // }
		],
		[]
	);

	const data = useMemo(() => assignments_data, [assignments_data]);

	const onDelete = (id) => {
        dispatch(deleteAssignmentsRequest(id));
    }

	const onSubmit = (rowId, assignedScore) => {
		dispatch(updateAssignmentsRequest({assignmentId: rowId, assignmentScore: assignedScore}));
	}

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		nextPage,
		previousPage,
		state,
		gotoPage,
		pageCount,
		prepareRow,
		setGlobalFilter
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageSize: 10,
				hiddenColumns: columns.map((column) => {
					if (column.show === false) return column.accessor || column.id;
					else return false;
				})
			}
		},
		useFilters,
		useGlobalFilter,
		usePagination
	);

	const { pageIndex, globalFilter } = state;

	return (
		<Fragment>
			<div className=" overflow-hidden">
				<Row>
					<Col lg={12} md={12} sm={12} className="mb-lg-0 mb-2 py-4 px-5 ">
						<GlobalFilter
							filter={globalFilter}
							setFilter={setGlobalFilter}
							placeholder="Search Assignments"
						/>
					</Col>
				</Row>
			</div>

			<div className="table-responsive border-0">
				<Table {...getTableProps()} className="text-nowrap">
					<thead className="table-light">
						{headerGroups.map((headerGroup) => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column) => (
									<th {...column.getHeaderProps()}>
										{column.render('Header')}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{page.map((row) => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell) => {
										return (
											<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</Table>
			</div>

			{/* Pagination @ Footer */}
			<Pagination
				previousPage={previousPage}
				pageCount={pageCount}
				pageIndex={pageIndex}
				gotoPage={gotoPage}
				nextPage={nextPage}
			/>

		</Fragment>
	);
};

export default AssignmentTable;
