import {URL} from '../shared/url';

export async function getCourses(accessToken) {
    const res = await fetch(URL.getCourses, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function getCourse(req, accessToken) {
    const res = await fetch(`${URL.getCourses}/${req.courseId}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`
        },
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function createCourse(payload, accessToken) {
    const data = {
        name: payload.name,
        description: payload.description,
        status: 'draft',
        level: payload.level || null,
        price: payload.price,
        trailer: payload.trailer,
        glimpse: payload.glimpse,
        thumbnail: payload.thumbnail,
        coverPhoto: payload.coverPhoto,
        currency: 'INR',
        isShipping: payload.isShipping,
        shipmentData: JSON.stringify({length: payload.length, breadth: payload.breadth, height: payload.height, weight: payload.weight}),

    };


    const res = await fetch(`${URL.createCourses}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data)
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function updateCourse(payload, accessToken) {
    const data = {};
    Object.keys(payload).map(field => {
        if (field !== 'courseId' && field !== 'history') {
            data[field] = payload[field];
        }
    })

    const res = await fetch(`${URL.createCourses}/${payload.courseId}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data)
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function deleteCourse(payload, accessToken) {
    const res = await fetch(`${URL.deleteCourses}/${payload.id}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function createChapter(payload, accessToken) {
    const res = await fetch(`${URL.createChapters}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
            'name': payload.name,
            'description': payload.description,
            'order': payload.order,
            'courseId': payload.courseId,
            'isQuizEnabled': payload.isQuizEnabled,
            'noOfQuestions': payload.noOfQuestions,
            'quizMaxRetry': payload.quizMaxRetry
        })
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function updateChapter(payload, accessToken) {
    const res = await fetch(`${URL.updateChapters}/${payload.id}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
            'name': payload.name,
            'description': payload.description,
            'order': payload.order,
            'isQuizEnabled': payload.isQuizEnabled,
            'noOfQuestions': payload.noOfQuestions,
            'quizMaxRetry': payload.quizMaxRetry
        })
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function deleteChapter(payload, accessToken) {
    const res = await fetch(`${URL.deleteChapters}/${payload.id}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        }
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function createLesson(payload, accessToken) {
    const data = {
        name: payload.name,
        description: payload.description,
        thumbnail: payload.thumbnail,
        video: payload.video,
        videoDuration: payload.videoDuration,
        order: payload.order,
        chapterId: payload.chapterId,
        isPreview: payload.isPreview,
        lessonType: payload.lessonType,
        isQuizEnabled: payload.isQuizEnabled,
        quizMaxRetry: payload.quizMaxRetry,
        noOfQuestions: payload.noOfQuestions,
        assignmentScore: payload.assignmentScore
    };

    const res = await fetch(`${URL.createLessons}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data)
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function updateLesson(payload, accessToken) {
    const data = {};
    Object.keys(payload).map(field => {
        if (field !== 'id' && field !== 'chapterId' && field !== 'chapterIndex') {
            data[field] = payload[field];
        }
    })

    const res = await fetch(`${URL.updateLessons}/${payload.id}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data)
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function deleteLesson(payload, accessToken) {
    const res = await fetch(`${URL.deleteLessons}/${payload.lessonId}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
        }
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function createFAQ(payload, accessToken) {
    const res = await fetch(`${URL.createFAQs}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
            'question': payload.question,
            'answer': payload.answer,
            'order': payload.order,
            'courseId': payload.courseId
        })
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function updateFAQ(payload, accessToken) {
    const res = await fetch(`${URL.updateFAQs}/${payload.id}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
            'question': payload.question,
            'answer': payload.answer,
            'order': payload.order
        })
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function deleteFAQ(payload, accessToken) {
    const res = await fetch(`${URL.deleteFAQs}/${payload.id}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        }
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function createTask(payload, accessToken) {
    const res = await fetch(`${URL.createTask}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
            'task': payload.task,
            'courseId': payload.courseId
        })
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function updateTask(payload, accessToken) {
    const res = await fetch(`${URL.updateTask}/${payload.id}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
            'task': payload.task
        })
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function deleteTask(payload, accessToken) {
    const res = await fetch(`${URL.deleteTask}/${payload.id}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        }
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function createCourseBanner(payload, bannerUrl, accessToken) {
    const data = {
        title: payload.title,
        description: payload.description,
        banner: bannerUrl,
        status: 'active'
    };

    const res = await fetch(`${URL.createCourseBanner}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data)
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function updateCourseBanner(payload, accessToken) {
    const data = {
        title: payload.title,
        description: payload.description,
        banner: payload.banner,
        status: payload.status
    };

    const res = await fetch(`${URL.updateCourseBanner}/${payload.id}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(data)
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function getCourseBanner(accessToken) {
    const res = await fetch(`${URL.updateCourseBanner}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        }
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function deleteCourseBanner(payload, accessToken) {
    const res = await fetch(`${URL.deleteCourseBanners}/${payload.id}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        }
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function getTransactions(req, accessToken) {
    const url = getTransactionsUrlWithParams(URL.getTransactions, req);
    const res = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        }
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function getTransactionsStats(accessToken) {
    const res = await fetch(URL.getTransactionsStats, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        }
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function getShipments(accessToken) {
    const res = await fetch(`${URL.getShipmentOrders}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        }
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function getAssignments(accessToken) {
    const res = await fetch(`${URL.getAssignments}`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        }
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function deleteAssignment(id, accessToken) {
    const res = await fetch(`${URL.getAssignments}/${id}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        }
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function updateAssignment(id, assignedScore, accessToken) {
    const res = await fetch(`${URL.getAssignments}/${id}`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({assignmentScore: assignedScore})
    });

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function offlineCourseEnroll(payload, accessToken) {
    const res = await fetch(`${URL.offlineCourseEnroll}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(payload)
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

export async function deleteTransaction(payload, accessToken) {
    const res = await fetch(`${URL.deleteTransaction}/${payload.id}`, {
        method: 'DELETE',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
        },
    })

    return res
        .json()
        .then(resBody => {
            return Promise.resolve(resBody);
        })
        .catch(err => Promise.reject(err));
}

function getTransactionsUrlWithParams(url, req) {
    const paramsObj = {};
    if (req?.status) paramsObj['status'] = req.status;
    if (req?.page) paramsObj['page'] = req.page; // default 1
    if (req?.limit) paramsObj['limit'] = req.limit; // default 10
    if (req?.search) paramsObj['search'] = req.search;
    if (req?.startDate) paramsObj['startDate'] = req.startDate;
    if (req?.endDate) paramsObj['endDate'] = req.endDate;
    const params = new URLSearchParams(paramsObj).toString();
    return url + (params ? `?${params}` : "");
}